import React from "react";
import ReactToPrint from "react-to-print";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { BsPersonCheckFill, BsPrinter } from "react-icons/bs";
import { AiOutlineDownload } from "react-icons/ai";
import styles from "./Security.module.scss";
class DownloadAndPrint extends React.Component {
  handlePrint = () => {
   /*  var opt = {
        margin:       1,
        filename:     'btrlQrcode.pdf',
        image:        {  },
        html2canvas:  {  },
        jsPDF:        { orientation: 'landscape' }
      };
      
    html2pdf().set(opt)
      .from(this.printComponent)
      .save(); */
     //document.getElementById('qrcodeUrl').src=this.props.qrCodeUrl; 
     document.getElementById('qrcodeUrl').html=this.props.qrCodeUrl; 
      //,{ allowTaint: true }
      html2canvas(this.printComponent).then((canvas) => {
        console.log(canvas);
       // const imgData = canvas.toDataURL("image/png");
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF('landscape','px','a4','false');
        const imgProps = pdf.getImageProperties(imgData);
        const width = pdf.internal.pageSize.getWidth();
        const height = (imgProps.height * width) / imgProps.width;
        /* const imgData = canvas.toDataURL("image/png");
        const imgProps = pdf.getImageProperties(imgData);
        
      // console.log("imgProps",this.props.qrCodeUrl);
        const width = pdf.internal.pageSize.getWidth();
        const height = (imgProps.height * width) / imgProps.width;
  
        // pdf.addImage(imgData, "PNG", 10, 10, width, height);
        
        // pdf.addImage(imgData, "JPEG", 10, 10, width, height);
        */
        //pdf.addImage(imgData, "", 10, 10, width, height);
        pdf.addImage(imgData, "PNG", 10, 10, width, height);
        pdf.save("BTRLQrcode.pdf");
      });
      
  };
  render() {
    return (
      <div>
        
         <div ref={(ref) => (this.printComponent = ref)}>
          {this.props.children}
         
        </div>
        <div className={`${styles._container} flex-between`} style={{borderTop:"none",paddingTop:"0px",marginTop:"-50px"}}>
        <div className={styles.box}>
        <div className={`${styles.twobtns} flex-between`}>
        <ReactToPrint
          trigger={() => <button className="flex-center" style={{borderRadius: "3rem 3rem 3rem 3rem",margin:"auto"}}> <BsPrinter /><span>Print QR Code</span></button>}
          content={() => this.printComponent}
        />
       {/*  <button onClick={this.handlePrint}>Download as PDF</button> */}
     {/*   <button className="flex-center" onClick={this.handlePrint}>
              <AiOutlineDownload />
              <span>Download QR Code Pdf</span>
            </button> */}
        </div>
      </div>
      </div>
      </div>
    );
  }
}

export default DownloadAndPrint;