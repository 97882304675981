import React, { useContext, useState, useEffect, useMemo } from "react";
import Deposit from "../../../Components/BalanceComponent/Deposit";
import Footer from "../../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../../Components/HomeComponents/Navbar/Navbar";
import { NavLink as Link, useNavigate } from "react-router-dom";
import Context from "../../../Context/Context";
import { AiOutlineClose } from "react-icons/ai";
import { MdKeyboardDoubleArrowDown, MdKeyboardDoubleArrowUp } from "react-icons/md";
import Table from 'react-bootstrap/Table';
import {
  authRequest,
  authRequestNb,
  fixedNumber,
  refreshToken,
  decodeToken,
} from "../../../Helper/helper";
//import styles from "./Balance.module.scss";
import styles from "./Balance.module.scss";
import Withdraw from "../../../Components/BalanceComponent/Withdraw/Withdraw";

const Balance = ({ update, globalRes }) => {
  const { states } = useContext(Context);
  const [dpstatus, setDpstatus] = useState(false);
  const [coinBalance, setCoinBalance] = useState();
  const [wtstatus, setWtstatus] = useState(false);
  const [slectedCoin, setSlectedCoin] = useState();
  const [Filters, setFilters] = useState();
  const [filter, setFilter] = useState("");
  const [kycStatus, setKycStatus] = useState(0);
  const [portfolio, setPortfolio] = useState({
    /*  "inr":{"available":0,"open":0,"total":0},
     "usd":{"available":0,"open":0,"total":0} */
   });
   const navigate = useNavigate();
   const kycPage = () => navigate("/bank-details");
  const [toggle, setToggle] = useState(
    JSON.parse(localStorage.getItem("hideZero"))
  );
  const [data, setData] = useState();

  const togglef = async () => {
    toggle ? setToggle(false) : setToggle(true);
    const toggleRes = await authRequest(
      { status: toggle ? 0 : 1 },
      "hide-zero"
    );
    refreshToken(toggleRes.refresh_token);
    setToggle(toggleRes.data);
    localStorage.setItem("hideZero", !toggle);
  };

  const dmode = states.dmode;

  const getAutoData = async () => {
    const res = await authRequestNb("all-balance");
    //console.log("res=>",res.data.gtoken);//
    refreshToken(res.refresh_token);
    const kdata=await decodeToken(res.refresh_token);
    //console.log("kdata=>",kdata);
    setKycStatus(kdata);
    setFilters(Object.keys(res.data));
    const _data = [];
    for (const coin of Object.keys(res.data)) {
      _data.push(res.data[coin]);
    }
    return _data;
  };

  useEffect(() => {
    (async () => {
      const _data = await getAutoData();
      if (toggle) {
        let hideZeroList = [];
        let filterList = [];
        for (const coin of _data) {
          if(!coin?.portfolio){

            if (parseFloat(coin.total_balance) !== 0) {
              filterList.push(coin.currency_symbol);
              if (filter === "") {
                hideZeroList.push(coin);
              } else if (coin.currency_symbol === filter) {
                hideZeroList = coin;
              }
            }
          }else{
           setPortfolio(coin?.portfolio)
         }
          /* if (parseFloat(coin.total_balance) !== 0) {
            filterList.push(coin.currency_symbol);
            if (filter === "") {
              hideZeroList.push(coin);
            } else if (coin.currency_symbol === filter) {
              hideZeroList = coin;
            }
          } */
        }
        setFilters(filterList);
        setData(hideZeroList);
      } else {
        let hideZeroList = [];
        let filterList = [];
        for (const coin of _data) {
          if(!coin?.portfolio){
            filterList.push(coin.currency_symbol);
            if (filter === "") {
              hideZeroList.push(coin);
            } else if (coin.currency_symbol === filter) {
              hideZeroList = coin;
            }
          }else{
            setPortfolio(coin?.portfolio)
          }
         /*  filterList.push(coin.currency_symbol);
          if (filter === "") {
            hideZeroList.push(coin);
          } else if (coin.currency_symbol === filter) {
            hideZeroList = coin;
          } */
        }
        setFilters(filterList);
        setData(hideZeroList);
      }
    })();
  }, [filter, toggle]);

  useMemo(() => {
    if (update[1] && data) {
      const _obj = update[1];
      const _data = data;

      if (_data.length) {
        for (const _key of Object.keys(_obj)) {
          const index = _data.findIndex((obj) => obj.currency_symbol === _key);
          _data[index] = { ..._data[index], ..._obj[_key] };
          setData(_data);
        }
      } else {
        for (const coin of Object.keys(update[1])) {
          if (data.currency_symbol === coin) {
            setData({ ..._data, ...update[1][coin] });
          }
        }
      }
    }
  }, [update[0]]);

  const dandwHandler = async (_selectedcoin, loc) => {
    if(kycStatus==1){

        if (loc === "dep") {
          setDpstatus(true);
          setWtstatus(false);
        } else {
          const resBal = await authRequest({ currency: _selectedcoin }, "balance");
          setCoinBalance(_selectedcoin === "INR" ? resBal.data[0].available_balance - globalRes?.data?.lock_inr  : resBal.data[0].available_balance);
          refreshToken(resBal.refresh_token);
          setWtstatus(true);
          setDpstatus(false);
        }
        setSlectedCoin(_selectedcoin);
    }
    else{
      kycPage()
    }
  };

  useEffect(() => {
    dpstatus
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style.overflowY = "auto");
  }, [dpstatus]);

  useEffect(() => {
    // window.scrollTo(0, 0)
  }, []);

  return (
    <div className={`${styles.bal_l}  ligtMode`}>
      <Navbar />
      <div className="bannerMy  text-center ">
        <div className="container">
          <div className="bannerIn">
            <h4>Buy & Sell Digital Assets on BTRL</h4>
            <p>BTRL is the easiest, safest, and fastest way to buy & sell crypto asset exchange</p>
            <a href="/exchange" className="custombtn">Trade Now</a> &nbsp;
            <a href="#" className="custombtn1 " style={{backgroundColor:"#4a67f0 !important"}} onClick={() =>dandwHandler("INR", "dep")}> Deposit INR</a>
          </div>
        </div>
      </div>
      <div className={`${styles.container} container tableDesign`}>
      {<div className={`${styles.bal} tbalehead`}>
          <div className="porth">
              <h2>Total Portfolio</h2>
            </div>
            <div className="row">{/* ,paddingBottom:"10px" */}
              <div className="col-sm-4">
                <div className="portf">
                  <label>INR</label> 
                  <p className="">{portfolio?.INR?.total.toFixed(2)}</p>
                  {/* <div style={{marginBottom:"25px"} }><p className="pull-left">{portfolio?.INR?.total.toFixed(2)}</p> <a href="#" className="custombtn1 pull-right" onClick={() =>dandwHandler("INR", "dep")}> Deposit INR</a></div> */}
                </div>
                
              </div>
              <div className="col-sm-4">
                <div className="portf"><label>USDT</label> <p>{portfolio?.USD?.total.toFixed(2)}</p></div>
              </div>
              <div className="col-sm-4">
                <div className="portf"><label>BTC</label> <p>{fixedNumber(portfolio?.BTC?.total,12)}</p></div>
              </div>
            </div>
         
          </div>}
        <div className={` tbalehead`}>
       {/*  <div className={`${styles.bal} tbalehead`}> */}
          
          <div className="flex-between">
            <div>
              <h2>Balance details</h2>
            </div>
            <div className={`${styles.filters} flex `}>
              <div className="flex">
                <h3>Hide Zero Balance:</h3>
                <div 
                  onClick={togglef}
                  className={`${toggle ? styles.tru_tg_c : styles.tg_c} flex 1`}
                >
                  <div className={toggle ? styles.tru : styles.tg_sc} d="1"></div>
                </div>
              </div>

              {dpstatus && (
              <div className="popHandle">
                <div className={`${styles.depositBox} popHandleIn`}>
                  <div className={` popnav `}>
                    <h1>
                      Deposit {slectedCoin} {slectedCoin.toUpperCase()==="INR"?" (Deposit From Same Verified Bank Account.)":""}
                      {/*  */}
                    </h1>
                    <button
                      className={`${styles.close} popHandleClose`}
                      onClick={() => setDpstatus(false)}
                    >
                      <AiOutlineClose />
                    </button>
                  </div>
                  {slectedCoin && (
                    <Deposit
                      globalRes={globalRes}
                      setDpstatus={setDpstatus}
                      coin={slectedCoin}
                      dmode={dmode}
                    />
                  )}
                </div>

                <div className="shadowmodal"></div>
              </div>
              )}

              {wtstatus && (
              <div className="popHandle">
                <div className={`${styles.widthdrawBox} popHandleIn`}>
                  <div className={`popnav`}>
                    <h1>
                      Withdraw {fixedNumber(coinBalance, 12)} {slectedCoin}
                    </h1>
                    <button
                      className={`${styles.close} popHandleClose`}
                      onClick={() => setWtstatus(false)}
                    >
                      <AiOutlineClose />
                    </button>
                  </div>
                  {slectedCoin && (
                    <Withdraw
                      setWtstatus={setWtstatus}
                      globalRes={globalRes}
                      coin={slectedCoin}
                      availableBalance={coinBalance}
                      dmode={dmode}
                    />
                  )}
                </div>
                <div className="shadowmodal"></div>
              </div>
              )}

              <div className="flex">
                <h3>Filter By:</h3>
                <div className={`styles.sbox cusSelect`}>
                  {/* console.log(Filters) */}
                  <select 
                    onChange={(e) => setFilter(e.target.value)}
                    value={filter}
                  >
                    <option value={""}>All Coins</option>
                    {Filters?.map((option, index) => {
                      return (
                        <option value={option?.toUpperCase()} key={index}>
                          {option?.toUpperCase()}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>

        <h1 className={`${styles.note}`}>
          {"Note : - Drag right for all options >>>"}
        </h1>
        <div className="heightAutoTable historyTable mb-3">
        <Table responsive>
          <thead>
              <tr>
                <th className=""  >Currency Symbol</th>
                <th >Currency Name</th>
                <th >Total Balance</th>
                <th >Available Balance</th>
                <th >Open Order Balance</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            
              {filter === "" ? (
                data &&
                data.length > 0 &&
                data?.map((coin, i) => (
                  <tr key={i}>
                    <td className=""  >
                      <div className="flex">
                      <img src={coin.image} alt={coin.image} />
                      {coin.currency_symbol}
                    </div>
                    </td>
                    <td >{coin.name}</td>
                    <td >{fixedNumber(coin.total_balance?.toString(), 12)}</td>
                    <td >
                      {fixedNumber(coin.available_balance?.toString(), 12)}
                    </td>
                    <td >{fixedNumber(coin.open_orders?.toString(), 12)}</td>
                    {coin.total_balance && (
                      <td>
                        <button
                          onClick={() =>
                            dandwHandler(coin.currency_symbol, "dep")
                          }
                        >
                          <MdKeyboardDoubleArrowDown /> Deposit
                        </button>{" "}
                        <button
                          onClick={() => dandwHandler(coin.currency_symbol)}
                        >
                        <MdKeyboardDoubleArrowUp /> Withdraw
                        </button>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="" >
                    <div className="flex">
                    <img src={data.image} alt={data.image} />
                    {data.currency_symbol}
                  </div>
                  </td>
                  <td >{data.name}</td>
                  <td >{data.total_balance}</td>
                  <td >{data.available_balance}</td>
                  <td >{data.open_orders}</td>
                  {data.total_balance && (
                    <td>
                      <button
                        onClick={() =>
                          dandwHandler(data.currency_symbol, "dep")
                        }
                      >
                        <MdKeyboardDoubleArrowDown /> Deposit
                      </button>{" "}
                      <button
                        onClick={() => dandwHandler(data.currency_symbol)}
                      >
                      <MdKeyboardDoubleArrowUp /> Withdraw
                      </button>
                    </td>
                  )}
                </tr>
              )}
            </tbody>
        </Table>
        </div>
        {/*   <div className='custableresponsive heightAutoTable table-responsive  mb-3'>
            <table>
              <thead>
                <tr>
                  <th className=""  style={{width: "150px"}}>Currency Symbol</th>
                  <th style={{width: "200px"}}>Currency Name</th>
                  <th style={{width: "200px"}}>Total Balance</th>
                  <th style={{width: "200px"}}>Available Balance</th>
                  <th style={{width: "200px"}}>Open Order Balance</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
               
                {filter === "" ? (
                  data &&
                  data.length > 0 &&
                  data?.map((coin, i) => (
                    <tr key={i}>
                      <td className=""  style={{width: "150px"}}>
                        <div className="flex">
                        <img src={coin.image} alt={coin.image} />
                        {coin.currency_symbol}
                      </div>
                      </td>
                      <td style={{width: "200px"}}>{coin.name}</td>
                      <td style={{width: "200px"}}>{fixedNumber(coin.total_balance?.toString(), 12)}</td>
                      <td style={{width: "200px"}}>
                        {fixedNumber(coin.available_balance?.toString(), 12)}
                      </td>
                      <td style={{width: "200px"}}>{fixedNumber(coin.open_orders?.toString(), 12)}</td>
                      {coin.total_balance && (
                        <td>
                          <button
                            onClick={() =>
                              dandwHandler(coin.currency_symbol, "dep")
                            }
                          >
                            <MdKeyboardDoubleArrowDown /> Deposit
                          </button>{" "}
                          <button
                            onClick={() => dandwHandler(coin.currency_symbol)}
                          >
                           <MdKeyboardDoubleArrowUp /> Withdraw
                          </button>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="" style={{width: "150px"}}>
                      <div className="flex">
                      <img src={data.image} alt={data.image} />
                      {data.currency_symbol}
                    </div>
                    </td>
                    <td style={{width: "200px"}}>{data.name}</td>
                    <td style={{width: "200px"}}>{data.total_balance}</td>
                    <td style={{width: "200px"}}>{data.available_balance}</td>
                    <td style={{width: "200px"}}>{data.open_orders}</td>
                    {data.total_balance && (
                      <td>
                        <button
                          onClick={() =>
                            dandwHandler(data.currency_symbol, "dep")
                          }
                        >
                          <MdKeyboardDoubleArrowDown /> Deposit
                        </button>{" "}
                        <button
                          onClick={() => dandwHandler(data.currency_symbol)}
                        >
                         <MdKeyboardDoubleArrowUp /> Withdraw
                        </button>
                      </td>
                    )}
                  </tr>
                )}
              </tbody>
            </table>
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Balance;
