import React, { useEffect, useRef,useState,useContext } from 'react';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import Context from "../../Context/Context";
import { refreshToken, sendGetRequest } from "../../Helper/helper";

import tradingview from "../../Assets/tradingview.png";
import btcInr from "../../Assets/btc-inr-trading.png";
import coinmarketcap from "../../Assets/coinmarketcap.svg";
import coinmarketcap1 from "../../Assets/coinmarketcap.png";
import athcoinindex from "../../Assets/athcoinindex.png";
import coingecko from "../../Assets/coingecko.png";
import cryptocoinworld from "../../Assets/cryptocoinworld.png";


SwiperCore.use([Autoplay, Pagination, Navigation]);

const Adds = () => {

  const [data, setData] = useState();
  const { states } = useContext(Context);

  const dmode = states.dmode;

  useEffect(() => {
    (async () => {
      const res = await sendGetRequest("global");
      res.refresh_token && refreshToken(res.refresh_token);
      setData(res.data.listingAnnoucement);
    })();
  }, []);
    return (
    <>

       
        <section className="Partners1 padSec scrollFade ">
            <div className="container">

            <h2 className="text-center fade1 titleh2">Our Partners</h2>
            <div className=" boxanimation2 ">
                 <Swiper
                  loop={true}
                  speed={5000}
                  slidesPerView={1}
                  modules={[Autoplay]}
                  className='' >
                    <SwiperSlide className="flex-right">
                        <div className='row align-items-center justify-content-center mt-5'>
                            <div className='col-sm-4'>
                                <img src={btcInr} className="img-fluid" />
                                {/* <img src={tradingview} className="img-fluid" /> */}
                            </div>
                            <div className='col-sm-5'>
                                <h3 className='fontBold'>BTRL Exchange Powered by TradingView's Advanced Charting Tools</h3>

                                <p className='mt-3'>   Our platform integrates TradingView's advanced charting technology, 
                                    providing users with a comprehensive suite of tools for detailed market analysis. 
                                    BTRL Exchange users benefit from real-time <a href="https://www.tradingview.com/markets/cryptocurrencies/global-charts/" target="_blank"> crypto market cap</a> data, live price tracking, 
                                    and insightful analytics to monitor <a href="https://www.tradingview.com/symbols/BTCUSD/" target='_blank' >
                                    BTC prices</a> and significant market trends with ease.
                                </p>  
                            </div>

                        </div>
                           
                       
                    </SwiperSlide>
                    

                </Swiper>
            </div>
            </div>
        </section>
        <section className="appbanner Adds padSec scrollFade ">
            <div className="container">
            <div className="appbannerIn banners  text-center">
                {/* <h2 className="text-center fade1 titleh2">BTRL Exchange Coming Soon on</h2> */}
                <div className='playImages d-flex justify-content-center gap-2 mt-2'>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.btrl">
                        <img src='/latestimages/home/2.png' className="img-fluid" />
                    </a>
                    {/* 
                    <a href="#">
                        <img src='/latestimages/home/1.png' className="img-fluid" />
                    </a> 
                    */}
                </div>
            </div>
            </div>
        </section>
        {/* text-center */}
        {/* slidesPerView={states.swidth < 800 && states.swidth > 550 ? 3 : states.swidth < 550 ? 2 : 5} */}
        {/* <section className="Partners padSec scrollFade ">
            <div className="container-fluid">

            <h2 className="text-center fade1 titleh2">Find Us On</h2>
            <div className=" boxanimation2 text-center">
                 <Swiper
                  loop={true}
                  speed={5000}
                  slidesPerView={states.swidth < 800 && states.swidth > 550 ? 3 : states.swidth < 550 ? 2 : 5}
                  modules={[Autoplay]}
                  className='' >
                    <SwiperSlide className="flex-center">
                        <img src={coinmarketcap} className="img-fluid" />
                    </SwiperSlide>
                    <SwiperSlide className="flex-center">
                        <img src={coinmarketcap1} className="img-fluid" />
                    </SwiperSlide>
                    <SwiperSlide className="flex-center">
                        <img src={athcoinindex} className="img-fluid" />
                    </SwiperSlide>
                    <SwiperSlide className="flex-center">
                        <img src={coingecko} className="img-fluid" />
                    </SwiperSlide>
                    <SwiperSlide className="flex-center">
                        <img src={cryptocoinworld} className="img-fluid" />
                    </SwiperSlide>

                </Swiper>
            </div>
            </div>
        </section> */}
    </>
   );
};

export default Adds;