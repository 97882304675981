import React, { useContext, useState, useEffect } from "react";
import { NavLink as Link, useNavigate } from "react-router-dom";
import insi from '../../Assets/Wallet.png';
import cloud from '../../Assets/Cloud.png';
import supp from '../../Assets/about-icon-2.png';

import Topgainers from './tabs/topgainers';
import Toploosers from './tabs/toploosers';
import { sendGetRequest } from "../../Helper/helper";

const Cryptotabs = () => {
  
    const [activeTab, setActiveTab] = useState('Topgainers');
    const [globalRes, setGlobalRes] = useState();
    useEffect(()=>{

      (async()=>{
        const res = await sendGetRequest("global");
        setGlobalRes(res)

      })()

    },[])
    //  const exchange = () => navigate("/exchange");
    useEffect(()=>{
    //  console.log("globalRes=>",globalRes);
    },[globalRes])
  return (
    <section  className='cryptoSec padSec   scrollFade'>
      <div className="container">
        <h2 className='fade1 titleh2 text-center'>Crypto Market Activities</h2>
        <div className="cryptoIn mt-5">
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <ul className="row nav nav-tabs" id="myTab" role="tablist">
            <li className=" nav-item" role="presentation">
              <button className={activeTab==="Topgainers" ? "tab tabbtn customInput active" : 'tab tabbtn customInput'} onClick={() => setActiveTab('Topgainers')} >
                Top Gainers
              </button>
              <button className={activeTab==="Toploosers" ? "tab tabbtn customInput active" : 'tab tabbtn customInput'} onClick={() => setActiveTab('Toploosers')} >
                Top Loosers
              </button>

            </li>
          </ul>
          {/*<div className='marketlink'>
          <a href='/exchange'>View Market</a>
          </div>*/}
          </div>

          <div className="tab-content" id="myTabContent1">
            { activeTab==="Topgainers" ? <><Topgainers /> </> : <> </>}
            
            { activeTab==="Toploosers" ? <><Toploosers /> </> : <> </>}
            


          </div>
          <div className="row btnslabel">
            <div className="col-sm-6 col-md-6 col-xl-3 mt-2">
              <div className="snbtnlabel">
                <div href="#" className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center gap-2">
                    <i className="fa-regular fa-user"></i> <span>Register / Login</span>
                  </div>
                  <div className="btnhere">
                    {/* <a href="/signup">Visit Now</a> */}
                    <Link to={`/signup`}>Visit Now</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-xl-3 mt-2">
              <div className="snbtnlabel">
                <div href="#" className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center gap-2">
                    <i className="fa-solid fa-money-check-dollar"></i> <span>withdraw funds</span>
                  </div>
                  <div className="btnhere">
                    {/* <a href="/balance">Visit Now</a> */}
                    <Link to={`/balance`}>Visit Now</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-xl-3 mt-2">
              <div className="snbtnlabel">
                <div href="#" className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center gap-2">
                    <i className="fa-solid fa-right-left"></i> <span>exchange</span>
                  </div>
                  <div className="btnhere">
                    {/* <a href={`/exchange/${globalRes?.data?.landing_page.toUpperCase()}`}>Visit Now</a> */}
                    <Link to={`/exchange/${globalRes?.data?.landing_page.toUpperCase()}`}>Visit Now</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-xl-3 mt-2">
              <div className="snbtnlabel">
                <div href="/balance" className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center gap-2">
                    <i className="fa-solid fa-coins"></i> <span>deposit coins</span>
                  </div>
                  <div className="btnhere">
                   {/*  <a href="#">Visit Now</a> */}
                   <Link to={`/balance`}>Visit Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cryptotabs;
