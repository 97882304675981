import React, { useState, useEffect, useMemo,useRef } from "react";

import styles from "./SetOrder.module.scss";
import {
  authRequest,
  fixedNumber,
  refreshToken,
  toFixed,
} from "../../../Helper/helper";
import { useNavigate } from "react-router-dom";
// import swal from "sweetalert";
import Swal from "sweetalert2";
/* ,
  ooData,
  setOoData */
let rerender = 0;
const _path = window.location.pathname;
const SetOrder = ({
  userBalance,
  setOrder,
  dmode,
  buyTrade,
  setBuyTrade,
  sellTrade,
  setSellTrade,
  tradeData,
  _isLogin,
  bid,
  setBid,
  globalRes
}) => {
  const [percent, setPercent] = useState();
  const [buypercent, setBuyPercent] = useState(0);
  const [sellpercent, setSellPercent] = useState(0);
  const [tdsPercent, setTdsPercent] = useState(1);
  const [isMatch, setIsMatch] = useState(true);
  const [isBuyMatch, setIsBuyMatch] = useState(true);
  const [isSellMatch, setIsSellMatch] = useState(true);
  const [isTradable, setIsTradable] = useState(false);
  const [isBuyTradable, setIsBuyTradable] = useState(false);
  const [isSellTradable, setIsSellTradable] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [errorBuyMessage, setErrorBuyMessage] = useState({});
  const [errorSellMessage, setErrorSellMessage] = useState({});
  const [firstCheck, setFirstCheck] = useState(null);
  const [showProcess, setShowProcess] = useState(0);
  const [showBuyProcess, setShowBuyProcess] = useState(0);
  const [showSellProcess, setShowSellProcess] = useState(0);
  // const [message, setMessage] = useState();
  const windowWidth = useRef(window.innerWidth);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [formBuyData, setFormBuyData] = useState({
    price: "",
    amount: "",
    total: "",
  });
  const [formSellData, setFormSellData] = useState({
    price: "",
    amount: "",
    total: "",
  });
  const [formData, setFormData] = useState({
    price: "",
    amount: "",
    total: "",
  });
  const [currentTrade, setCurrentTrade] = useState({});
  const [currentBuyTrade, setCurrentBuyTrade] = useState({});
  const [currentSellTrade, setCurrentSellTrade] = useState({});
  // console.log("userBalance=>",userBalance);
  // console.log("setOrder=>",setOrder);
  // console.log("dmode=>",dmode);
  // console.log("buyTrade=>",buyTrade);
  // console.log("setBuyTrade=>",setBuyTrade);
  //
  // console.log("_isLogin=>",_isLogin);
  // console.log("bid=>",bid);
  // console.log("setBid=>",setBid);

  useEffect(() => {
    if(globalRes && globalRes.status===200 && globalRes.data.tds_percent){
      setTdsPercent(parseFloat(globalRes.data.tds_percent))
    }
  },[globalRes]);

    useEffect(() => {
    //if(currentTrade.market.toLowerCase()==)
    const currency = _path.split("/")[2]?.toUpperCase();
    const market = _path.split("/")[3]?.toUpperCase();
    // if(market!==tradeData.market_symbol && currency!==tradeData.currency_symbol){
    //   navigate(`/exchange/${tradeData.currency_symbol}/${tradeData.market_symbol}`, { replace: true })
    // }

    //console.log("currentTrade=>",currentTrade);
    //console.log("tradeData=>",tradeData);
    //console.log("_path=>",_path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrade, tradeData, _path]);
  const navigate = useNavigate();

  const trade = (_trade) => {
    if (_trade === "sell") {
      setBuyTrade(false);
    } else {
      setBuyTrade(true);
    }
  };

  const errorHandler = (key, val) => {
    let change = errorMessage;
    change[key] = val;
    setErrorMessage({ ...change });
  };
  const errorBuyHandler = (key, val) => {
    let change = errorBuyMessage;
    change[key] = val;
    setErrorBuyMessage({ ...change });
  };
  const errorSellHandler = (key, val) => {
    let change = errorSellMessage;
    change[key] = val;
    setErrorSellMessage({ ...change });
  };

  
  const submitBuyHandler = async (e) => {
    setShowBuyProcess(1);
    
    if (_isLogin) {
      if (formBuyData.price && formBuyData.amount && formBuyData.total) {
        /* console.log("formData.price=>",formData.price);
        console.log("formData.amount=>",formData.amount);
        console.log("formData.total=>",formData.total); */
        if (currentBuyTrade.buy_balance >= formData.total) {
          const _formData = {
            market: currentBuyTrade.market.toLowerCase(),
            currency: currentBuyTrade.currency.toLowerCase(),
            price: formBuyData.price,
            amount: formBuyData.amount,
          };
          // console.log("formData=>",_formData);
          setOrder({ ..._formData, buyTrade: true });

          //const res = await authRequest(_formData, buyTrade ? "buy" : "sell");
          const res = await authRequest(_formData, "buy");

          res.refresh_token && refreshToken(res.refresh_token);
          let timerInterval;
          res &&
            Swal.fire({
              html: res.message,
              timer: 2000,
              timerProgressBar: true,
              position: "top-right",
              padding: "1rem",
              showConfirmButton: false,
              scrollbarPadding: false,
              allowOutsideClick: false,
              backdrop: false,
              customClass: {
                timerProgressBar: "tgb",
                popup: "popup",
                htmlContainer: "htmlContainer",
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            });
          res &&
            setFormBuyData({
              price: "",
              amount: "",
              total: "",
            });
          res && setShowBuyProcess(0);
          // setMessage(res.message)
        }
      } else {
        console.log(formBuyData, errorBuyMessage);
      }
    } else {
      navigate("/signin");
    }
  };

  const formBuyHandler = (key, value, stcheck=null) => {
    const change = formBuyData;
    setBuyTrade(true);
    //setBuyPercent(0);
    //console.log("buy stcheck",stcheck);
    if(stcheck===null){

      setBuyPercent(0);
    }
    change[key] = value && parseFloat(value);
    if (
      (formBuyData.price && formBuyData.amount) ||
      (formBuyData.price && formBuyData.total)
    ) {
     // console.log("formBuyData=>",formBuyData) 
     // console.log("formSellData.amount=>",formSellData.amount) 
      //console.log("currentSellTrade.sell_balance=>",currentSellTrade.sell_balance);
      if (key === "total") {
        if (formBuyData.total) {
          change["amount"] =
            value &&
            formBuyData.price &&
            parseFloat(toFixed(value / formBuyData.price, 8));
        } else {
          change["amount"] = "";
          change["total"] = "";
        }
      }
      if (key === "amount") {
        if (formBuyData.amount) {
          change["total"] =
            value &&
            formBuyData.price &&
            parseFloat(toFixed(value * formBuyData.price, 8));
        } else {
          change["total"] = "";
          change["amount"] = "";
        }
      }
      if (key === "price") {
        if (formBuyData.price) {
          change["total"] =
            value &&
            formBuyData.price &&
            parseFloat(toFixed(value * formBuyData.amount, 8));
        } else {
          change["total"] = "";
          change["price"] = "";
        }
      }
    } else {
      change["total"] = "";
    }
    setFormBuyData({ ...change });
  };
  const submitSellHandler = async (e) => {
    setShowSellProcess(1);
    
    if (_isLogin) {
      
      if (formSellData.price && formSellData.amount && formSellData.total) {
        /* console.log("formData.price=>",formData.price);
        console.log("formData.amount=>",formData.amount);
        console.log("formData.total=>",formData.total); */
      
        if (currentSellTrade.sell_balance >= formSellData.amount) {
         // console.log("formSellData=>",formSellData);
          const _formData = {
            market: currentSellTrade.market.toLowerCase(),
            currency: currentSellTrade.currency.toLowerCase(),
            price: formSellData.price,
            amount: formSellData.amount,
          };
          //console.log("formData=>",_formData);
          setOrder({ ..._formData, buyTrade: false });

          const res = await authRequest(_formData, "sell");

          res.refresh_token && refreshToken(res.refresh_token);
          let timerInterval;
          res &&
            Swal.fire({
              html: res.message,
              timer: 2000,
              timerProgressBar: true,
              position: "top-right",
              padding: "1rem",
              showConfirmButton: false,
              scrollbarPadding: false,
              allowOutsideClick: false,
              backdrop: false,
              customClass: {
                timerProgressBar: "tgb",
                popup: "popup",
                htmlContainer: "htmlContainer",
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            });
          res &&
            setFormSellData({
              price: "",
              amount: "",
              total: "",
            });
          res && setShowSellProcess(0);
          // setMessage(res.message)
        } 
        else{
        //  console.log("currentSellTrade=>",currentSellTrade);
        //  console.log("formSellData=>",formSellData);

        }
      } else {
        console.log(formSellData, errorSellMessage);
      }
    } else {
      navigate("/signin");
    }
  };
  const formSellHandler = (key, value, stcheck=null) => {
    const change = formSellData;
  //  setBuyTrade(false);
  //console.log("sell stcheck",stcheck);
    setSellTrade(true);
    if(stcheck===null){

      setSellPercent(0);
    }
    change[key] = value && parseFloat(value);
    if (
      (formSellData.price && formSellData.amount) ||
      (formSellData.price && formSellData.total)
    ) {
      if (key === "total") {
        if (formSellData.total) {
          change["amount"] =
            value &&
            formSellData.price &&
            parseFloat(toFixed(value / formSellData.price, 8));
        } else {
          change["amount"] = "";
          change["total"] = "";
        }
      }
      if (key === "amount") {
        if (formSellData.amount) {
          change["total"] =
            value &&
            formSellData.price &&
            parseFloat(toFixed(value * formSellData.price, 8));
        } else {
          change["total"] = "";
          change["amount"] = "";
        }
      }
      if (key === "price") {
        if (formSellData.price) {
          change["total"] =
            value &&
            formSellData.price &&
            parseFloat(toFixed(value * formSellData.amount, 8));
        } else {
          change["total"] = "";
          change["price"] = "";
        }
      }
    } else {
      change["total"] = "";
    }
    setFormSellData({ ...change });
  };
  

  useMemo(() => {
    setErrorMessage({});
    setErrorBuyMessage({});
    //console.log("currentBuyTrade",currentBuyTrade);
    if (formBuyData && currentBuyTrade && _isLogin) {
      if (
        (parseFloat(currentBuyTrade.buy_balance) * parseFloat(percent)) / 100 ===
        formBuyData.total
      ) {
        setIsMatch(true);
      } else {
        setIsMatch(false);
        setPercent();
      }
      if (currentBuyTrade?.buy_balance > 0) {
       // setIsSellTradable(false);
        if (formBuyData.price && formBuyData.amount && formBuyData.total) {
          if (currentBuyTrade.min_trade > formBuyData.total) {
            errorBuyHandler(
              "min_trade",
              `Minimum trade amount : ${currentBuyTrade.min_trade}`
            );
          }
          if (formBuyData.total > currentBuyTrade.buy_balance) {
            setIsBuyTradable(false);
            errorBuyHandler("buy_balance", `Insufficient balance :`);
          }
          formBuyData.total <= currentBuyTrade.buy_balance && setIsBuyTradable(true);
         

          if (Object.values(errorBuyMessage)[0]) {
            setIsBuyTradable(false);
          }
        } else {
          setIsBuyTradable(false);
        }
      }

      if (currentBuyTrade?.buy_balance <= 0) {
        errorBuyHandler("buy_balance", `Insufficient balance :`);
      }
    }
    
  }, [formBuyData, currentBuyTrade]);
  useMemo(() => {
    setErrorMessage({});
    setErrorSellMessage({});
    
    if (formSellData && currentSellTrade && _isLogin) {
     // setIsBuyTradable(false);
      if (
        (parseFloat(currentSellTrade.sell_balance) * parseFloat(percent)) / 100 ===
        formSellData.total
      ) {
        setIsMatch(true);
      } else {
        setIsMatch(false);
        setPercent();
      }
      if (currentSellTrade?.sell_balance > 0) {
        if (formSellData.price && formSellData.amount && formSellData.total) {
          if (currentSellTrade.min_trade > formSellData.total) {
            errorSellHandler(
              "min_trade",
              `Minimum trade amount : ${currentSellTrade.min_trade}`
            );
          }
          if (formSellData.amount > currentSellTrade.sell_balance) {
            setIsSellTradable(false);
            errorSellHandler("sell_balance", `Insufficient balance :`);
          }
         
          formSellData.amount <= currentSellTrade.sell_balance && setIsSellTradable(true);
          if (Object.values(errorSellMessage)[0]) {
            setIsSellTradable(false);
          }
        } else {
          setIsSellTradable(false);
        }
      }

      if (currentSellTrade?.sell_balance <= 0) {
        errorSellHandler("sell_balance", `Insufficient balance :`);
      }
    }
  }, [formSellData, currentSellTrade]);

  useMemo(() => {
    Object.values(errorBuyMessage)[0] ? setIsBuyTradable(false) : setIsBuyTradable(true);
  }, [Object.values(errorBuyMessage)[0]]);
  useMemo(() => {
    Object.values(errorSellMessage)[0] ? setIsSellTradable(false) : setIsSellTradable(true);
  }, [Object.values(errorSellMessage)[0]]);

  // *****auto set Bid

  // useMemo(() => {
  //   if (currentTrade.balance) {
  //     currentTrade.price &&
  //       formHandler("price", currentTrade?.price ? currentTrade?.price : 0);
  //     currentTrade.min_trade &&
  //       formHandler(
  //         "amount",
  //         currentTrade?.min_trade ? currentTrade?.min_trade : 0
  //       );
  //     currentTrade.min_trade &&
  //       currentTrade.price &&
  //       formHandler("total", currentTrade?.min_trade * currentTrade.price);
  //   }
  // }, [currentTrade ]);

  // *****auto set Bid

  const percentHandler = (per,type,stcheck=null) => {
    //console.log("type=>",type);
   // console.log("per=>",per);
    if(type) {
      if (_isLogin) {
      setBuyPercent(parseFloat(per));
      formBuyData.price &&
        formBuyHandler(
          "total",
          (parseFloat(currentBuyTrade.buy_balance) * parseFloat(per)) / 100, stcheck
        );
      }else{
        setBuyPercent(parseFloat(0));
      }
    } else {
      if (_isLogin) {
      setSellPercent(parseFloat(per));
      formSellData.price &&
        formSellHandler(
          "amount",
          (parseFloat(currentSellTrade.sell_balance) * parseFloat(per)) / 100, stcheck
        );
      }else{
        setSellPercent(parseFloat(0));
      }
    }
  };

  useEffect(() => {
   // !formSellData.price && setSellPercent("");
    if (
      (parseFloat(currentSellTrade.sell_balance) * parseFloat(sellpercent)) / 100 ===
      formSellData.total
    ) {
      setIsMatch(true);
    } else {
      setIsMatch(false);
      //setSellPercent();
    }
    setBid();
  }, [sellpercent]);
  useEffect(() => {
   // console.log("buypercent12",buypercent);
   // !formBuyData.price && setBuyPercent("");
    if (
      (parseFloat(currentBuyTrade.buy_balance) * parseFloat(buypercent)) / 100 ===
      formBuyData.total
    ) {
      setIsMatch(true);
    } else {
      setIsMatch(false);
     // setBuyPercent();
    }
    setBid();
  }, [buypercent]);
  // for click on orders and fill in form
  useEffect(() => {
   // console.log("bid=>",bid);
   /*  bid &&
      setFormData({
        price: parseFloat(bid.bid_price),
        amount: parseFloat(bid.qty),
        total: parseFloat(bid.total),
      }); */
      if(bid){
        if(bid.type==="Buy"){
          setBuyPercent(0);
          setFormBuyData({
            price: parseFloat(bid.bid_price),
            amount: parseFloat(bid.qty),
            total: parseFloat(bid.total),
          });
        }
        else{
          setSellPercent(0);
          setFormSellData({
            price: parseFloat(bid.bid_price),
            amount: parseFloat(bid.qty),
            total: parseFloat(bid.total),
          });
        }
      }
    setBid();
  }, [bid]);

  /* 
    useEffect(() => {
      if (_isLogin && tradeData && userBalance) {
        //console.log("userBalance",userBalance);
        setCurrentBuyTrade({
          buy_balance: userBalance.buy_balance && userBalance.buy_balance,
          sell_balance: userBalance.sell_balance && userBalance.sell_balance,
          fee: tradeData.sell_fee,
          currency: tradeData.currency_symbol,
          market: tradeData.market_symbol,
          price: parseFloat(tradeData.price),
          min_trade: parseFloat(tradeData.minimum_trade),
        });
        setCurrentSellTrade({
            buy_balance: userBalance.buy_balance && userBalance.buy_balance,
            sell_balance: userBalance.sell_balance && userBalance.sell_balance,
            fee: tradeData.sell_fee,
            currency: tradeData.currency_symbol,
            market: tradeData.market_symbol,
            price: parseFloat(tradeData.price),
            min_trade: parseFloat(tradeData.minimum_trade),
        });
        
      }
      
      setErrorBuyMessage({});
      setErrorSellMessage({});
      setPercent();
    }, [firstCheck, buyTrade, tradeData]); 
  */
  useEffect(() => {
    if (_isLogin && tradeData && userBalance) {
      setCurrentBuyTrade({
        buy_balance: userBalance.buy_balance && userBalance.buy_balance,
        sell_balance: userBalance.sell_balance && userBalance.sell_balance,
        fee: tradeData.buy_fee,
        currency: tradeData.currency_symbol,
        market: tradeData.market_symbol,
        price: parseFloat(tradeData.price),
        min_trade: parseFloat(tradeData.minimum_trade),
      });
      setCurrentSellTrade({
          buy_balance: userBalance.buy_balance && userBalance.buy_balance,
          sell_balance: userBalance.sell_balance && userBalance.sell_balance,
          fee: tradeData.sell_fee,
          currency: tradeData.currency_symbol,
          market: tradeData.market_symbol,
          price: parseFloat(tradeData.price),
          min_trade: parseFloat(tradeData.minimum_trade),
      });
      /* if (buyTrade) {
        setCurrentTrade({
          buy_balance: userBalance.buy_balance && userBalance.buy_balance,
          sell_balance: userBalance.sell_balance && userBalance.sell_balance,
          fee: tradeData.buy_fee,
          currency: tradeData.currency_symbol,
          market: tradeData.market_symbol,
          price: parseFloat(tradeData.price),
          min_trade: parseFloat(tradeData.minimum_trade),
        });
      } else {
        setCurrentTrade({
          buy_balance: userBalance.buy_balance && userBalance.buy_balance,
          sell_balance: userBalance.sell_balance && userBalance.sell_balance,
          fee: tradeData.sell_fee,
          currency: tradeData.currency_symbol,
          market: tradeData.market_symbol,
          price: parseFloat(tradeData.price),
          min_trade: parseFloat(tradeData.minimum_trade),
        });
      } */
    }
    /* setFormData({
      price: "",
      amount: "",
      total: "",
    }); */
    setErrorBuyMessage({});
    setErrorSellMessage({});
    setPercent();
  }, [firstCheck, buyTrade, tradeData, _isLogin]);
  if (userBalance) {
      /*  
        if (buyTrade) {
          if (currentTrade.buy_balance !== userBalance.buy_balance) {
            !isNaN(userBalance.buy_balance) &&
              setCurrentTrade({
                ...currentTrade,
                buy_balance: userBalance.buy_balance,
              });
          }
        } else {
          if (currentTrade.sell_balance !== userBalance.sell_balance) {
            !isNaN(userBalance.sell_balance) &&
              setCurrentTrade({
                ...currentTrade,
                sell_balance: userBalance.sell_balance,
              });
          }
        } 
      */
    if (currentBuyTrade.buy_balance !== userBalance.buy_balance) {
      !isNaN(userBalance.buy_balance) &&
        setCurrentBuyTrade({
          ...currentBuyTrade,
          buy_balance: userBalance.buy_balance,
        });
    }
    if (currentSellTrade.sell_balance !== userBalance.sell_balance) {
      !isNaN(userBalance.sell_balance) &&
        setCurrentSellTrade({
          ...currentSellTrade,
          sell_balance: userBalance.sell_balance,
        });
    }
  }

  useEffect(() => {
    if (firstCheck === null) {
      if (_isLogin && tradeData && userBalance) {
        setFirstCheck(true);
      }
    }
  }, [buyTrade, _isLogin, tradeData, userBalance]);

  useEffect(() => {
    setErrorMessage({});
    setErrorBuyMessage({});
    setErrorSellMessage({});
    setFormBuyData({
      price: "",
      amount: "",
      total: "",
    });
    setFormSellData({
      price: "",
      amount: "",
      total: "",
    });
  }, [_isLogin]);

  const marksBuy = [
    {
      value: 1,
      label: '1%',
    },
    {
      value: 5,
      label: '5%',
    },
    {
      value: 25,
      label: '25%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 75,
      label: '75%',
    },
    {
      value: 100,
      label: '100%',
    },
  ];
  const marksSell = [
    {
      value: 1,
      label: '1%',
    },
    {
      value: 5,
      label: '5%',
    },
    {
      value: 25,
      label: '25%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 75,
      label: '75%',
    },
    {
      value: 100,
      label: '100%',
    },
  ];
  const handleResize = () => {
    setIsMobile(window.innerWidth < 913);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  //console.log("tradeData=>",tradeData);
  return (
    <section
      className={`${styles.so_l}  buysellForm Form ligtMode latesthandle`}
    >
      <div className={`${styles.trademode} flex-between sellbuytab`}>
       
      {isMobile ? (
         <>
          <button
          onClick={() => trade("buy")}
          /* className={buyTrade ? "active btnb" : "btnb"} */
          className={buyTrade ? styles.buybtn : ""}
          >
          Buy {tradeData?.currency_symbol}
        </button>
       
        <button
          onClick={() => trade("sell")}
          /* className={buyTrade ? "active btns" : " btns"} */
          className={buyTrade ? "" : styles.sellbtn}
          >
          Sell {tradeData?.currency_symbol}
        </button>
          {/* {buyTrade?
          (<>
           <button
          onClick={() => trade("buy")}
          className={buyTrade ? "active btnb" : "btnb"}
          >
          Buy {tradeData?.currency_symbol}
        </button>
          </>)
          :
          (<>
          <button
          onClick={() => trade("sell")}
          className={buyTrade ? "active btns" : " btns"}
          >
          Sell {tradeData?.currency_symbol}
        </button>
          </>)
          } */}
         
        
        </>

      ):
      (
        <>
          <button
            onClick={() => trade("buy")}
           /*  className={buyTrade ? " btnb" : "btnb"} */
           className={buyTrade ? styles.buybtn : ""}
          >
            Buy {tradeData?.currency_symbol}
          </button>
          <button
            onClick={() => trade("sell")}
            /* className={buyTrade ? "btns" : " btns"} */
            className={buyTrade ? "" : styles.sellbtn}
          >
            Sell {tradeData?.currency_symbol}
          </button>
        </>
      )
      }
      </div>
     
      <div className="overhide">
        <div className="row ">
        {isMobile ? (<>
        {buyTrade?
          (<>
           <div className="col-sm-12 ">
            <div className={`${styles.buy}  inPut`}>
              {/*   value={formData.price} */}
              <h4>
                Price <span>{tradeData?.market_symbol}</span>
              </h4>
              <input
                min={0}
                onChange={(e) => formBuyHandler("price", e.target.value)}
                value={formBuyData.price}
                type="number"
                placeholder="0.000000"
                name="_price"
              />
              <div className="btinput"></div>{" "}
              {errorBuyMessage.price && (
                <p className={styles.warning_note}>{errorBuyMessage.price}</p>
              )}
            </div>

            <div className={`${styles.amount}  inPut`}>
              {/* value={formData.amount} */}
              <h4>
                Amount <span>{tradeData?.currency_symbol}</span>
              </h4>
              <input
                min={0}
                onChange={(e) => formBuyHandler("amount", e.target.value)}
                value={formBuyData.amount}
                type="number"
                placeholder="0.000000"
                name="_amount"
              />
              <div className="btinput"></div>{" "}
            </div>

            <div className="d-flex orderhandle BuyHandle">
              <div className={`${styles.percents} flex-around perBox`}>
                <button
                  disabled={
                    currentBuyTrade.buy_balance && currentBuyTrade.buy_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(1,true,true)}
                  className={buypercent === 1 && isBuyMatch ? `${styles.active} active` : buypercent === 1 ?"active":""}
                >
                  1%
                </button>
                <button
                  disabled={
                    currentBuyTrade.buy_balance && currentBuyTrade.buy_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(5,true,true)}
                  className={buypercent === 5 && isBuyMatch ? `${styles.active} active` : buypercent === 5 ?"active":""}
                >
                  5%
                </button>
                <button
                  disabled={
                    currentBuyTrade.buy_balance && currentBuyTrade.buy_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(25,true,true)}
                  className={buypercent === 25 && isBuyMatch ? `${styles.active} active` : buypercent === 25 ?"active":""}
                >
                  25%
                </button>
                <button
                  disabled={
                    currentBuyTrade.buy_balance && currentBuyTrade.buy_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(50,true,true)}
                  className={buypercent === 50 && isBuyMatch ? `${styles.active} active` : buypercent === 50 ?"active":""}
                >
                  50%
                </button>
                <button
                  disabled={
                    currentBuyTrade.buy_balance && currentBuyTrade.buy_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(75,true,true)}
                  className={buypercent === 75 && isBuyMatch ? `${styles.active} active` : buypercent === 75 ?"active":""}
                >
                  75%
                </button>
                <button
                  disabled={
                    currentBuyTrade.buy_balance && currentBuyTrade.buy_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(100,true,true)}
                  className={buypercent === 100 && isBuyMatch ? `${styles.active} active` : buypercent === 100 ?"active":""}
                >
                  100%
                </button>
              </div>

              {/*per0 per25 per50 per75 per100 */}
             {/*  <div className="percentagerange per0">
                <div className="rdot"></div>
              </div> */}
              {/* <div className="percentagerangeBuy per0"> */}
                {/* <div className="rdotBuy"></div> */}
                <input
                  type="range"
                  min={0}
                  max={100}
                  step={5}
                  value={buypercent}
                  onChange={(event) => {
                  //  console.log("buy event.target.value=>",event.target.value);
                    percentHandler(event.target.value,true);
                  }}
                  style={{ width: '100%' }}
                  list="tickmarkbuy"
                />
                <datalist id="tickmarksbuy" className="rdotBuy">
                {marksBuy.map((mark) => (
                  <option key={mark.value} value={mark.value} label={mark.label} />
                ))}
              </datalist>
            {/*   </div> */}
            </div>
            <div className={`${styles.total}  inPut`}>
              <h4>Total</h4>
              <input
                onChange={(e) => formBuyHandler("total", e.target.value)}
                value={formBuyData.total}
                min={0}
                type="number"
                placeholder="0.00000"
              />
              <div className="btinput"></div>
              {errorBuyMessage.min_trade && (
                <p className={styles.warning_note}>{errorBuyMessage.min_trade}</p>
              )}
            </div>

            <div className=" justify-content-between h3buysell">
              <h3>
                <span className="labelspan">
                  {" "}
                  { tradeData?.market_symbol }
                 
                    
                    {" "}
                  Balance:{" "}
                </span>
                {currentBuyTrade?.buy_balance ? (
                  errorBuyMessage.buy_balance ? (
                    <span style={{ color: "crimson" }}>
                      {errorBuyMessage.buy_balance} {currentBuyTrade?.buy_balance}
                    </span>
                  ) : (
                    currentBuyTrade?.buy_balance
                  )
                ) : errorBuyMessage.buy_balance ? (
                  <span style={{ color: "crimson" }}>
                    {errorBuyMessage.buy_balance} {currentBuyTrade?.buy_balance}
                  </span>
                ) : (
                  <span>0</span>
                )}
              </h3>

              <h3>
                <span className="labelspan">
                  Fees ( {currentBuyTrade?.fee} %):{" "}
                </span>
                {fixedNumber(
                      (formBuyData?.amount * currentBuyTrade?.fee) / 100
                        ? (formBuyData?.amount * currentBuyTrade?.fee) / 100
                        : "0",
                      12
                    )}
               
                    
                    {" "}
               
                {tradeData?.currency_symbol}
                <br />{" "}
              
              </h3>
            </div>
            <div
              style={{ position: "relative" }}
              className="flex-between"
            ></div>
            {tradeData?.trade_status === "1" ? (
              showBuyProcess === 0 ? (
                <button
                  disabled={_isLogin && !isBuyTradable ? true : false}
                  style={{
                    opacity: _isLogin && !isBuyTradable ? "0.5" : "1",
                    background:  "#76d776",
                  }}
                  onClick={submitBuyHandler}
                  className={styles.si}
                >
                  {_isLogin ? "Buy Now": "Sign In"}
                </button>
              ) : (
                <button
                  disabled={true}
                  style={{
                    opacity: _isLogin && !isBuyTradable ? "0.5" : "1",
                    background:  "#76d776" ,
                  }}
                  className={styles.si}
                >
                  Please Wait...
                </button>
              )
            ) : (
              <button
                disabled={_isLogin && !isBuyTradable ? true : false}
                style={{
                  opacity: _isLogin && !isBuyTradable ? "0.5" : "1",
                  background: "#549D54" ,
                }}
                className={styles.si}
              >
                {_isLogin && !isBuyTradable
                  ? "Temporarily out of service"
                  : "Sign In"}
              </button> 
            )}
          </div>
          </>)
          :
          (<>
          
          <div className="col-sm-12 ">
            <div className={`${styles.buy}  inPut`}>
              {/*   value={formData.price} */}
              <h4>
                Price <span>{tradeData?.market_symbol}</span>
              </h4>
              <input
                min={0}
                onChange={(e) => formSellHandler("price", e.target.value)}
                value={formSellData.price}
                type="number"
                placeholder="0.000000"
                name="_price"
              />
              <div className="btinput"></div>{" "}
              {errorSellMessage.price && (
                <p className={styles.warning_note}>{errorSellMessage.price}</p>
              )}
            </div>

            <div className={`${styles.amount}  inPut`}>
              {/* value={formData.amount} */}
              <h4>
                Amount <span>{tradeData?.currency_symbol}</span>
              </h4>
              <input
                min={0}
                onChange={(e) => formSellHandler("amount", e.target.value)}
                value={formSellData.amount}
                type="number"
                placeholder="0.000000"
                name="_amount"
              />
              <div className="btinput"></div>{" "}
            </div>

            <div className="d-flex orderhandle SellHandle">
              <div className={`${styles.percents} flex-around perBox`}>
                <button
                  disabled={
                    currentSellTrade.sell_balance && currentSellTrade.sell_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(1,false,true)}
                  className={sellpercent === 1 && isSellMatch ? `${styles.active} active` : sellpercent === 1 ?"active":""}
                  data={sellpercent}
                >
                  1%
                </button>
                <button
                  disabled={
                    currentSellTrade.sell_balance && currentSellTrade.sell_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(5,false,true)}
                  className={sellpercent === 5 && isSellMatch ? `${styles.active} active` : sellpercent === 5 ?"active":""}
                  data={sellpercent}
                >
                  5%
                </button>
                <button
                  disabled={
                    currentSellTrade.sell_balance && currentSellTrade.sell_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(25,false,true)}
                  className={sellpercent === 25 && isSellMatch ? `${styles.active} active` : sellpercent === 25 ?"active":""}
                >
                  25%
                </button>
                <button
                  disabled={
                    currentSellTrade.sell_balance && currentSellTrade.sell_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(50,false,true)}
                  className={sellpercent === 50 && isSellMatch ? `${styles.active} active` : sellpercent === 50 ?"active":""}
                >
                  50%
                </button>
                <button
                  disabled={
                    currentSellTrade.sell_balance && currentSellTrade.sell_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(75,false,true)}
                  className={sellpercent === 75 && isSellMatch ? `${styles.active} active` : sellpercent === 75 ?"active":""}
                >
                  75%
                </button>
                <button
                  disabled={
                    currentSellTrade.sell_balance && currentSellTrade.sell_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(100,false,true)}
                  className={sellpercent === 100 && isSellMatch ? `${styles.active} active` : sellpercent === 100 ?"active":""}
                >
                  100%
                </button>
              </div>

              {/*per0 per25 per50 per75 per100 */}
              {/* <div className="percentagerangeSell per0">
                <div className="rdotSell"></div>
              </div> */}
              <input
                  type="range"
                  min={0}
                  max={100}
                  step={5}
                  value={sellpercent}
                  onChange={(event) => {
                    console.log("sell event.target.value=>",event.target.value);
                    percentHandler(event.target.value,false);
                  }}
                  style={{ width: '100%' }}
                  list="tickmarksell"
                />
                <datalist id="tickmarksell" className="rdotSell">
                {marksSell.map((mark) => (
                  <option key={mark.value} value={mark.value} label={mark.label} />
                ))}
              </datalist>
            </div>
            <div className={`${styles.total}  inPut`}>
              <h4>Total</h4>
              <input
                onChange={(e) => formSellHandler("total", e.target.value)}
                value={formSellData.total}
                min={0}
                type="number"
                placeholder="0.00000"
              />
              <div className="btinput"></div>
              {errorSellMessage.min_trade && (
                <p className={styles.warning_note}>{errorSellMessage.min_trade}</p>
              )}
            </div>

            <div className=" justify-content-between h3buysell">
              <h3>
                <span className="labelspan">
                  {" "}
                  {tradeData?.currency_symbol}
                    {" "}
                  Balance:{" "}
                </span>
                {currentSellTrade?.sell_balance ? (
                  errorSellMessage.sell_balance ? (
                    <span style={{ color: "crimson" }}>
                      {errorSellMessage.sell_balance} {currentSellTrade?.sell_balance}
                    </span>
                  ) : (
                    currentSellTrade?.sell_balance
                  )
                ) : errorSellMessage.sell_balance ? (
                  <span style={{ color: "crimson" }}>
                    {errorSellMessage.sell_balance} {currentSellTrade?.sell_balance}
                  </span>
                ) : (
                  <span>0</span>
                )}
              </h3>

              <h3>
                <span className="labelspan">
                  {/* Fees ({parseFloat(currentSellTrade?.fee) - tdsPercent} %)  + TDS ( {tdsPercent} %):{" "} */}
                  {currentSellTrade.fee? (
                    <>
                      Fees ({parseFloat(currentSellTrade?.fee) - tdsPercent} %) + TDS ( {tdsPercent} %):{" "}
                    </>
                    ) :  
                    (  
                      <>
                       Fees (%) + TDS (%) :{" "}
                      </>
                      )
                  }
                </span>
               
                    {fixedNumber(
                      (formSellData?.total * currentSellTrade?.fee) / 100
                        ? (formSellData?.total * currentSellTrade?.fee) / 100
                        : "0",
                      12
                    )}
                    
                    {" "}
              
                  {tradeData?.market_symbol}
                <br />
                {" "}
               
              </h3>
            </div>
            <div
              style={{ position: "relative" }}
              className="flex-between"
            ></div>
            {/*  disabled={_isLogin && !isBuyTradable ? true : false} */}
            {tradeData?.trade_status === "1" ? (
              showSellProcess === 0 ? (
                <button
                  disabled={_isLogin && !isSellTradable ? true : false}
                  style={{
                    opacity: _isLogin && !isSellTradable ? "0.5" : "1",
                   background:  "#ef5350",
                  }}
                  onClick={submitSellHandler}
                  className={styles.si}
                >                  
                 { _isLogin ?  "Sell Now" : "Sign In"}
                </button>
              ) : (
                <button
                  disabled={true}
                  style={{
                    opacity: _isLogin && !isSellTradable ? "0.5" : "1",
                   background:  "#ef5350",
                  }}
                  className={styles.si}
                >
                  Please Wait...
                </button>
              )
            ) : (
              <button
                disabled={_isLogin && !isSellTradable ? true : false}
                style={{
                  opacity: _isLogin && !isSellTradable ? "0.5" : "1",
                 background:  "#ef5350",
                }}
                className={styles.si}
              >
                {_isLogin && !isSellTradable
                  ? "Temporarily out of service"
                  : "Sign In"}
              </button>
            )}
          </div>
          </>)}
         
          
        
        </>)
        :
        (<>
        
          <div className="col-sm-6 ">
            <div className={`${styles.buy}  inPut`}>
              {/*   value={formData.price} */}
              <h4>
                Price <span>{tradeData?.market_symbol}</span>
              </h4>
              <input
                min={0}
                onChange={(e) => formBuyHandler("price", e.target.value)}
                value={formBuyData.price}
                type="number"
                placeholder="0.000000"
                name="_price"
              />
              <div className="btinput"></div>{" "}
              {errorBuyMessage.price && (
                <p className={styles.warning_note}>{errorBuyMessage.price}</p>
              )}
            </div>

            <div className={`${styles.amount}  inPut`}>
              {/* value={formData.amount} */}
              <h4>
                Amount <span>{tradeData?.currency_symbol}</span>
              </h4>
              <input
                min={0}
                onChange={(e) => formBuyHandler("amount", e.target.value)}
                value={formBuyData.amount}
                type="number"
                placeholder="0.000000"
                name="_amount"
              />
              <div className="btinput"></div>{" "}
            </div>

            <div className="d-flex orderhandle BuyHandle">
              <div className={`${styles.percents} flex-around perBox`}>
                {/* {console.log("buypercent",buypercent)} */}
                <button
                    id="{buypercent}"
                    key="f-buy-0"
                  disabled={
                    currentBuyTrade.buy_balance && currentBuyTrade.buy_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(1,true,true,true)}
                  className={((buypercent === 1 && isBuyMatch) ? `${styles.active} active` : ((buypercent === 1) ?"active":""))}
                >
                  1%
                </button>
                <button
                   
                  disabled={
                    currentBuyTrade.buy_balance && currentBuyTrade.buy_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(5,true,true,true)}
                  className={((buypercent === 5 && isBuyMatch) ? `${styles.active} active` : ((buypercent === 5) ?"active":""))}
                >
                  5%
                </button>
                <button
                  disabled={
                    currentBuyTrade.buy_balance && currentBuyTrade.buy_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(25,true,true,true)}
                  className={buypercent === 25 && isBuyMatch ? `${styles.active} active` :  buypercent === 25 ?"active":""}
                   data={buypercent}
                >
                  25%
                </button>
                <button
                  disabled={
                    currentBuyTrade.buy_balance && currentBuyTrade.buy_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(50,true,true,true)}
                  className={buypercent === 50 && isBuyMatch ? `${styles.active} active` :  buypercent === 50 ?"active":""}
                   data={buypercent}
                >
                  50%
                </button>
                <button
                  disabled={
                    currentBuyTrade.buy_balance && currentBuyTrade.buy_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(75,true,true,true)}
                  className={buypercent === 75 && isBuyMatch ? `${styles.active} active` :  buypercent === 75 ?"active":""}
                   data={buypercent}
                >
                  75%
                </button>
                <button
                  disabled={
                    currentBuyTrade.buy_balance && currentBuyTrade.buy_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(100,true,true,true)}
                  className={buypercent === 100 && isBuyMatch ? `${styles.active} active` : buypercent === 100 ?"active":""}
                   data={buypercent}
                >
                  100%
                </button>
              </div>

              {/*per0 per25 per50 per75 per100 */}
             {/*  <div className="percentagerange per0">
                <div className="rdot"></div>
              </div> */}
              {/* <div className="percentagerangeBuy per0"> */}
                {/* <div className="rdotBuy"></div> */}
                <input
                  type="range"
                  min={0}
                  max={100}
                  step={5}
                  value={buypercent}
                  onChange={(event) => {
                   // console.log("buy event.target.value=>",event.target.value);
                    percentHandler(event.target.value,true);
                  }}
                  style={{ width: '100%' }}
                  list="tickmarkbuy"
                />
                <datalist id="tickmarksbuy" className="rdotBuy">
                {marksBuy.map((mark) => (
                  <option key={mark.value} value={mark.value} label={mark.label} />
                ))}
              </datalist>
            {/*   </div> */}
            </div>
            <div className={`${styles.total}  inPut`}>
              <h4>Total</h4>
              <input
                onChange={(e) => formBuyHandler("total", e.target.value)}
                value={formBuyData.total}
                min={0}
                type="number"
                placeholder="0.00000"
              />
              <div className="btinput"></div>
              {errorBuyMessage.min_trade && (
                <p className={styles.warning_note}>{errorBuyMessage.min_trade}</p>
              )}
            </div>

            <div className=" justify-content-between h3buysell">
              <h3>
                <span className="labelspan">
                  {" "}
                  { tradeData?.market_symbol }
                 
                    
                    {" "}
                  Balance:{" "}
                </span>
                {currentBuyTrade?.buy_balance ? (
                  errorBuyMessage.buy_balance ? (
                    <span style={{ color: "crimson" }}>
                      {errorBuyMessage.buy_balance} {currentBuyTrade?.buy_balance}
                    </span>
                  ) : (
                    currentBuyTrade?.buy_balance
                  )
                ) : errorBuyMessage.buy_balance ? (
                  <span style={{ color: "crimson" }}>
                    {errorBuyMessage.buy_balance} {currentBuyTrade?.buy_balance}
                  </span>
                ) : (
                  <span>0</span>
                )}
              </h3>

              <h3>
                <span className="labelspan">
                  Fees ( {currentBuyTrade?.fee} %):{" "}
                </span>
                {fixedNumber(
                      (formBuyData?.amount * currentBuyTrade?.fee) / 100
                        ? (formBuyData?.amount * currentBuyTrade?.fee) / 100
                        : "0",
                      12
                    )}
               
                    
                    {" "}
               
                {tradeData?.currency_symbol}
                <br />{" "}
              
              </h3>
            </div>
            <div
              style={{ position: "relative" }}
              className="flex-between"
            ></div>
            {tradeData?.trade_status === "1" ? (
              showBuyProcess === 0 ? (
                <button
                  disabled={_isLogin && !isBuyTradable ? true : false}
                  style={{
                    opacity: _isLogin && !isBuyTradable ? "0.5" : "1",
                    background:  "#76d776",
                  }}
                  onClick={submitBuyHandler}
                  className={styles.si}
                >
                  {_isLogin ? "Buy Now": "Sign In"}
                </button>
              ) : (
                <button
                  disabled={true}
                  style={{
                    opacity: _isLogin && !isBuyTradable ? "0.5" : "1",
                    background:  "#76d776" ,
                  }}
                  className={styles.si}
                >
                  Please Wait...
                </button>
              )
            ) : (
              <button
                disabled={_isLogin && !isBuyTradable ? true : false}
                style={{
                  opacity: _isLogin && !isBuyTradable ? "0.5" : "1",
                  background: "#549D54" ,
                }}
                className={styles.si}
              >
                {_isLogin && !isBuyTradable
                  ? "Temporarily out of service"
                  : "Sign In"}
              </button> 
            )}
          </div>
          <div className="col-sm-6 ">
            <div className={`${styles.buy}  inPut`}>
              {/*   value={formData.price} */}
              <h4>
                Price <span>{tradeData?.market_symbol}</span>
              </h4>
              <input
                min={0}
                onChange={(e) => formSellHandler("price", e.target.value)}
                value={formSellData.price}
                type="number"
                placeholder="0.000000"
                name="_price"
              />
              <div className="btinput"></div>{" "}
              {errorSellMessage.price && (
                <p className={styles.warning_note}>{errorSellMessage.price}</p>
              )}
            </div>

            <div className={`${styles.amount}  inPut`}>
              {/* value={formData.amount} */}
              <h4>
                Amount <span>{tradeData?.currency_symbol}</span>
              </h4>
              <input
                min={0}
                onChange={(e) => formSellHandler("amount", e.target.value)}
                value={formSellData.amount}
                type="number"
                placeholder="0.000000"
                name="_amount"
              />
              <div className="btinput"></div>{" "}
            </div>

            <div className="d-flex orderhandle SellHandle">
              <div className={`${styles.percents} flex-around perBox`}>
                <button
                  disabled={
                    currentSellTrade.sell_balance && currentSellTrade.sell_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(1,false,true)}
                  className={sellpercent === 1 && isSellMatch ? `${styles.active} active` : sellpercent === 1 ?"active":""}
                >
                  1%
                </button>
                <button
                  disabled={
                    currentSellTrade.sell_balance && currentSellTrade.sell_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(5,false,true)}
                  className={sellpercent === 5 && isSellMatch ? `${styles.active} active` : sellpercent === 5 ?"active":""}
                >
                  5%
                </button>
                <button
                  disabled={
                    currentSellTrade.sell_balance && currentSellTrade.sell_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(25,false,true)}
                  className={sellpercent === 25 && isSellMatch ? `${styles.active} active` : sellpercent === 25 ?"active":""}
                >
                  25%
                </button>
                <button
                  disabled={
                    currentSellTrade.sell_balance && currentSellTrade.sell_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(50,false,true)}
                  className={sellpercent === 50 && isSellMatch ? `${styles.active} active` : sellpercent === 50 ?"active":""}
                >
                  50%
                </button>
                <button
                  disabled={
                    currentSellTrade.sell_balance && currentSellTrade.sell_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(75,false,true)}
                  className={sellpercent === 75 && isSellMatch ? `${styles.active} active` : sellpercent === 75 ?"active":""}
                >
                  75%
                </button>
                <button
                  disabled={
                    currentSellTrade.sell_balance && currentSellTrade.sell_balance > 0
                      ? false
                      : true
                  }
                  onClick={() => percentHandler(100,false,true)}
                  className={sellpercent === 100 && isSellMatch ? `${styles.active} active` : sellpercent === 100 ?"active":""}
                >
                  100%
                </button>
              </div>

              {/*per0 per25 per50 per75 per100 */}
              {/* <div className="percentagerangeSell per0">
                <div className="rdotSell"></div>
              </div> */}
              <input
                  type="range"
                  min={0}
                  max={100}
                  step={5}
                  value={sellpercent}
                  onChange={(event) => {
                    //console.log("sell event.target.value=>",event.target.value);
                    percentHandler(event.target.value,false);
                  }}
                  style={{ width: '100%' }}
                  list="tickmarksell"
                />
                <datalist id="tickmarksell" className="rdotSell">
                {marksSell.map((mark) => (
                  <option key={mark.value} value={mark.value} label={mark.label} />
                ))}
              </datalist>
            </div>
            <div className={`${styles.total}  inPut`}>
              <h4>Total</h4>
              <input
                onChange={(e) => formSellHandler("total", e.target.value)}
                value={formSellData.total}
                min={0}
                type="number"
                placeholder="0.00000"
              />
              <div className="btinput"></div>
              {errorSellMessage.min_trade && (
                <p className={styles.warning_note}>{errorSellMessage.min_trade}</p>
              )}
            </div>

            <div className=" justify-content-between h3buysell">
              <h3>
                <span className="labelspan">
                  {" "}
                  {tradeData?.currency_symbol}
                    {" "}
                  Balance:{" "}
                </span>
                {currentSellTrade?.sell_balance ? (
                  errorSellMessage.sell_balance ? (
                    <span style={{ color: "crimson" }}>
                      {errorSellMessage.sell_balance} {currentSellTrade?.sell_balance}
                    </span>
                  ) : (
                    currentSellTrade?.sell_balance
                  )
                ) : errorSellMessage.sell_balance ? (
                  <span style={{ color: "crimson" }}>
                    {errorSellMessage.sell_balance} {currentSellTrade?.sell_balance}
                  </span>
                ) : (
                  <span>0</span>
                )}
              </h3>

              <h3>
                <span className="labelspan">
                {/* Fees ( {currentBuyTrade?.fee} %):{" "} */}
                  {/* Fees ({parseFloat(currentSellTrade?.fee) - tdsPercent} %) + TDS ( {tdsPercent} %):{" "} */}
                  {currentSellTrade.fee? (
                    <>
                      Fees ({parseFloat(currentSellTrade?.fee) - tdsPercent} %) + TDS ( {tdsPercent} %) :{" "}
                    </>
                    ) :  
                    (  
                      <>
                      Fees (%) + TDS (%) :{" "}
                      </>
                      )
                  }
                </span>
               
                    {fixedNumber(
                      (formSellData?.total * currentSellTrade?.fee) / 100
                        ? (formSellData?.total * currentSellTrade?.fee) / 100
                        : "0",
                      12
                    )}
                    
                    {" "}
              
                  {tradeData?.market_symbol}
                <br />
                {" "}
               
              </h3>
            </div>
            <div
              style={{ position: "relative" }}
              className="flex-between"
            ></div>
            {/*  disabled={_isLogin && !isBuyTradable ? true : false} */}
            {tradeData?.trade_status === "1" ? (
              showSellProcess === 0 ? (
                <button
                  disabled={_isLogin && !isSellTradable ? true : false}
                  style={{
                    opacity: _isLogin && !isSellTradable ? "0.5" : "1",
                   background:  "#ef5350",
                  }}
                  onClick={submitSellHandler}
                  className={styles.si}
                >                  
                 { _isLogin ?  "Sell Now" : "Sign In"}
                </button>
              ) : (
                <button
                  disabled={true}
                  style={{
                    opacity: _isLogin && !isSellTradable ? "0.5" : "1",
                   background:  "#ef5350",
                  }}
                  className={styles.si}
                >
                  Please Wait...
                </button>
              )
            ) : (
              <button
                disabled={_isLogin && !isSellTradable ? true : false}
                style={{
                  opacity: _isLogin && !isSellTradable ? "0.5" : "1",
                 background:  "#ef5350",
                }}
                className={styles.si}
              >
                {_isLogin && !isSellTradable
                  ? "Temporarily out of service"
                  : "Sign In"}
              </button>
            )}
          </div>
        </>)}
        </div>
      </div>
    </section>
  );
};

export default SetOrder;
