/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState, useEffect, useMemo } from "react";
import { NavLink as Link, useNavigate } from "react-router-dom";
import Footer from "../../Components/HomeComponents/Footer/Footer.js";
import Navbar from "../../Components/HomeComponents/Navbar/Navbar.js";
import Context from "../../Context/Context.js";
//import { useNavigate } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import styles from "./module.css";
import Modal from 'react-modal';
import 'react-tabs/style/react-tabs.css';
import lottery1 from "../../Assets/lottery/lottery1.png";
import lottery2 from "../../Assets/lottery/lottery2.png";
import { BiCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Table from 'react-bootstrap/Table';
import swal from "sweetalert";
import DataTable, { createTheme } from 'react-data-table-component';
import FilterComponent from "./FilterComponent.js";
import { FaRegStar } from "react-icons/fa";
import {
  authRequest,
  refreshToken,
  shortener
} from "../../Helper/helper.js";
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const _path = window.location.pathname;
const Game = ({gameStatus, setGameStatus}) => {
 
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  
  function closeModal() {
    setIsOpen(false);
  }
  const { states } = useContext(Context);
  const [displayclass, setDisplayClass] = useState('show');
  const navigate = useNavigate();
  const signin = () => navigate("/signin");
  //const isLogin = states.isLogin;
  const isLogin = sessionStorage.getItem("isLogin") === "true" ? true : false;
 
  const dmode = states.dmode;
  //const ticketPrice = 1;
  //const currency_symbol = "BTRL";
  //const totalTicket = 270000;
  //BTRL
  const [currency_symbol, setCurrency_symbol] = useState("");
  const [token, setToken] = useState(1);
  const [infos, setInfos] = useState([]);
  const [soldTicket, setSoldTicket] = useState(false);
  const [previusGame, setPreviusGame] = useState(false);
  const [ticketPrice, setTicketPrice] = useState(1);
  const [totalBtrl, setTotalBtrl] = useState(token*ticketPrice);
  const [btrlBalance, setBtrlBalance] = useState(0);
  const [errormessage, setErrormessage] = useState({});
  const [userTickets, setUserTickets] = useState([]);
  const [winners, setWinners] = useState([]);
  const [contractAddress, setContractAddress] = useState("");
  const [contractLink, setContractLink] = useState("");
  const [winnerStatus, setWinnerStatus] = useState(0);
  const [pending, setPending] = React.useState(true);
  const handleChange = (event) => {
      const result=event.target.value.replace(/\D/g,'');
      if(result!==""){
        
          setToken(result);
          setTotalBtrl(result*ticketPrice);
        

      }
      else{
        setToken(result);
      }
  };
  const setInfosHandler = (key) => {
    let change = infos;
    change[key] = true;
    setInfos({ ...change });
    setTimeout(() => {
      setInfos({});
    }, 2000);
  };
  const errorHandler = (key, value) => {
    let change = errormessage;
    change[key] = value;
    setErrormessage({ ...change });
  };
 /*  useEffect(()=>{
    (async()=>{
      
      if(!soldTicket){

        const resBal1 = await authRequest({ currency: currency_symbol }, "total-game-ticket-sold");
        //console.log(resBal1?.data);
        refreshToken(resBal1?.refresh_token);
        if(resBal1?.status===200){
          setGameStatus(1);
          setSoldTicket(resBal1?.data)
          setPreviusGame(resBal1?.previousgame)
          setContractAddress(resBal1.contract_address);
          setContractLink(resBal1.link);
        }
      }
    })()
  }) */

  useEffect(()=>{
    (async()=>{
      
      if(!soldTicket){

        const resBal1 = await authRequest({ currency: currency_symbol }, "total-game-ticket-sold");
        //console.log(resBal1?.data);
        refreshToken(resBal1?.refresh_token);
        if(resBal1?.status===200){
          setGameStatus(1);
          setSoldTicket(resBal1?.data)
          setPreviusGame(resBal1?.previousgame)
          setContractAddress(resBal1.contract_address);
          setContractLink(resBal1.link);
          setCurrency_symbol(resBal1?.data?.currency_symbol)
          setTicketPrice(resBal1?.data?.price)
        }
      }
    })()
  })
  const getuserticket=async ()=>{
    const resBal1 = await authRequest({ currency: currency_symbol }, "user-game-tickets");
        if(resBal1)
        {
          if(resBal1.status===200){
            setUserTickets(resBal1.data)
          }
        }
  }
  useEffect(()=>{
    (async()=>{
     // console.log("isLogin",isLogin);
      if(isLogin){

        getuserticket();
        const resBal = await authRequest({ currency: currency_symbol }, "balance");
        refreshToken(resBal.refresh_token);
        if(resBal.status===200){
          setBtrlBalance(resBal.data[0]?.available_balance)
        }
      }
      else{
        setBtrlBalance(0)
      }
    })()
  },[isLogin, currency_symbol])

  useEffect(()=>{
    (async()=>{
    
        if(winners.length===0 && contractAddress==="" && contractLink==="" && winnerStatus===0){

          //getwinnersticket();
        }
    })()
  })
  const formDataHandler = async () => {
  
   if(token>0){
    if(btrlBalance >= totalBtrl){
 
        const res = await authRequest({
          ticket : token,
          
        } , "buy-game-tickets");
        refreshToken(res.refresh_token)
    
        if(res.status === 200){
           swal(res.message)
           await getuserticket();
           setBtrlBalance(btrlBalance-totalBtrl)
         
          setSoldTicket(prevState => ({
            ...prevState,
            game_court:(parseFloat(soldTicket.game_court)+ ((token*soldTicket.price)-(token*soldTicket.price)*soldTicket.admin_percent/100)).toFixed(2),
            sold_tickets:parseFloat(soldTicket.sold_tickets)+parseFloat(token)
          }))
           closeModal()
        }else if(res.status === 401){
          for (const keys of Object.keys(res.data)) {
            errorHandler(keys,res.data[keys])
          }
        }else{
          swal(res.message)
        }

      
    }
    else{
      swal("Balance must be greater than "+totalBtrl+".")
     }
   }
   else{
    errorHandler("ticket","Ticket is required.")
   }
  };
  const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  useEffect(() => {
		const timeout = setTimeout(() => {
			setRows(winners);
			setPending(false);
		}, 2000);
		return () => clearTimeout(timeout);
	}, [winners]);
  const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);
  const columns =  [
    {
      name: 'Ticket Number',
      selector: 'ticket',
      sortable: true,
    },
   
    {
      name: 'Winning Prize',
      selector: 'winningAmount',
      sortable: true,
    },
  ];
  const clickHandler = (state) => {
    // console.log("ID ", state);
    // window.open("https://wa.me/"+state.mobile, '_blank', 'noopener,noreferrer');
  };
  createTheme('dark', {
    background: {
      default: 'transparent',
    },
    
  });
  const customStyles = {
    title: {
      style: {
        fontColor: 'red',
        fontWeight: '900',
      }
    },
    
  };
/* (e) =>
          setToken((v) => (e.target.validity.valid ? e.target.value : v)) */
        //  console.log("isLogin=>",isLogin);
  return (
    <div className={dmode ? `  ligtModeInfoPage ligtModeLottery  ` : `  ligtModeInfoPage ligtModeLottery `}>
      <Navbar />
      
      	<div className="sn_page_pad looterpage ticketSection  p-relative">


        
          <img src={lottery1} className="lottery lottery1 img-fluid"/>
          <img src={lottery2} className="lottery lottery2 img-fluid"/>
          <div className="container relative">
          <div className="text-center p-relative">
        		<h1>
         
          BTRL Game
       </h1>

  
                <div className="row">
                    <div className="col-sm-6">
                        <div className='popupcenter buyBoxam_it' >
                        <div className="modalHeader d-flex justify-content-between">
                          <h2 className='mt-0'>Buy Ticket</h2>
                        </div>
                        <div className="modalBody">
                            <h4 className="bold">{ticketPrice} {currency_symbol} - 1  Ticket.</h4>
                          <label>{token>1?"Tickets":"Ticket"}:</label>
                          <div className="inPut">
                            <input type="text" name="ticket" className="form-control" placeholder="1" value={token} onChange={handleChange.bind(this)}/>
                            {errormessage.ticket && (
                                <p className={styles.warning_note}>{errormessage.ticket}</p>
                              )}
                          </div>
                          <div className="valuemodal d-flex justify-content-between align-items-center">
                              <p>Ticket Price</p>
                              <p>{ticketPrice} {currency_symbol}</p>
                          </div>
                          <div className="valuemodal d-flex justify-content-between align-items-center">
                              <p>Balance</p>
                              <p>{btrlBalance} {currency_symbol}</p>
                          </div>
                          <hr />
                          <div className="valuemodal d-flex justify-content-between align-items-center">
                              <p>Total</p>
                              <p><b>{totalBtrl} {currency_symbol}</b></p>
                          </div>
                          {isLogin ?
                          <>
                            {(btrlBalance>0 && btrlBalance >= totalBtrl)?
                            <>
                              <button className="btn" onClick={formDataHandler}>Buy Ticket</button>
                            </>
                            :
                            <>
                            <button className="btn" disabled={true}>Buy Ticket</button>
                            </>
                            }
                          </>
                          :<>
                          <button className="btn" onClick={signin}>Buy Ticket</button>
                                      </>
                        }
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="row">
                    <div className="col-sm-12">
                      <div className="snvalue text-center">
                        <h4>Total {currency_symbol} In Current Game Pot</h4>
                      {/*   <h6>Winner Will Get Pot</h6> */}
                        <h2>{parseFloat(soldTicket?.game_court).toFixed(2)}</h2>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="snvalue text-center">
                        <h4>Total Tickets In Current Game</h4>
                        <h2>{(soldTicket?.sold_tickets)}</h2>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="snvalue text-center g-details">
                        <h4>Game Details According To IST</h4>
                          <p style={{margin:"0px"}}>Start Timing Friday 12:00 P.M.</p>
                          <p style={{margin:"0px"}}>End Timing Friday 09:00 A.M.</p>
                          <p style={{margin:"0px"}}>Result Timing Friday 09:00 A.M. to 11:50 A.M.</p>                  
                          <p style={{margin:"0px"}}><strong>Note:- </strong>Winner Will Get Full Amount Of Game Pot In His/Her Wallet On Result's Day.</p>                  
                      </div>
                    </div>
                   
                  </div>
                    </div>
                  </div>
                  <div className="row">
          
                    <div className="col-sm-12">
                      <br></br>
                    <h1>
         
                    Previous Game Winner
                  </h1>

                      <div className="row">
                    <div className="col-sm-6">
                      <div className="snvalue text-center">
                        <h4>Total {currency_symbol} In  Game Pot</h4>
                      {/*   <h6>Winner Will Get Pot</h6> */}
                        <h2>{parseFloat(previusGame?.game_court).toFixed(2)}</h2>
                      </div>
                    </div>
                    {/* <div className="col-sm-4">
                      <div className="snvalue text-center">
                        <h4>Winner </h4>
                        <h2>BT{(previusGame?.winner_userid)}</h2>
                      </div>
                    </div> */}
                    <div className="col-sm-6">
                      <div className="snvalue text-center g-details">
                        <h4>Winner Ticket Number</h4>
                        <h2>{(previusGame?.winner_ticket)}</h2>
                      </div>
                    </div>
                    <div className="col-sm-12">
                    { contractLink!==""?<><h4 style={{marginTop:"10px"}}>BTRL Game Winner Contract: <a href={contractLink} target="_blank">{contractAddress}</a></h4></>:<></>}
                     
                    </div>
                  </div>
                    </div>
                  </div>
            </div>
           
          </div>
        </div>



              
        {(isLogin && userTickets.length>0) ?
            <>
              <div className="sn_page_pad ticketSection roundsSection " key={"aa"}>
                <div className="container relative " key={"aaa"}>
                  <h2 className="text-center" key={"aaaa"}>Congratulations 🎉 Your Tickets Total Are  As Follows.</h2>
                  <h2 className="text-center" key={"aaaat"}>You have total {userTickets.length} {userTickets.length>1?"tickets":"ticket"}.</h2>
                  <div className="row justify-content-center" key={"aaaaa"}>
                  { (userTickets.length>0) ?  (userTickets?.map((dbticket, i) => (
                      <>
                          <div className="col-sm-3" key={dbticket.ticket}>


                            <div className=" small_boxes_slider ticketsHere text-start d-flex gap-3 align-items-center"  key={"a"+dbticket.ticket}>
                                <div className='linHere'  key={"b"+dbticket.ticket}></div>
                                <div className='stars'>
                                  <FaRegStar className='star1' />
                                  <FaRegStar className='star2' />
                                  <FaRegStar className='star3' />
                                  </div>
                                  <h4 className="alignHere "> <p className="mb-0 mt-0"  key={"c"+dbticket.ticket}>Ticket Number</p> 
                                    <span>{infos[dbticket.ticket]? <><span  key={"e"+dbticket.ticket} className="coptext">Ticket No. Copied!</span></>:dbticket.ticket} 
                                    <CopyToClipboard  key={"f"+dbticket.ticket}
                                    text={dbticket && dbticket.ticket}
                                    onCopy={() => setInfosHandler(dbticket.ticket)}
                                  >
                                    <BiCopy  key={"g"+dbticket.ticket} />
                                  </CopyToClipboard>

                                  </span></h4>
                             </div>
                          </div>
                          
                      </>
                  ))):
                      <>
                        
                          <div className="col-sm-3" key="0-0">
                            <div className=" small_boxes_slider ticketsHere text-start d-flex gap-3 align-items-center">
                                <div className='linHere'></div>
                                <div className='stars'>
                                  <FaRegStar className='star1' />
                                  <FaRegStar className='star2' />
                                  <FaRegStar className='star3' />
                                  </div>
                                  <h4 className="alignHere "> <p className="mb-0 mt-0">Ticket Number</p> <span>Not Buy Yet</span></h4>
                             </div>
                          </div>
                          
                      
                      </>
                    }
                    </div>
                </div>
              </div>
      </>
      :<></>
            }       
      <Footer />
    </div>

  );
};

export default Game;
