import React, { useState, useContext, useEffect } from "react";
import styles from "./Navbar.module.scss";
import logo from "../../../Assets/logo.png";
import { NavLink as Link, useNavigate } from "react-router-dom";
import Context from "../../../Context/Context";
import { FaRegLightbulb, FaWhatsapp } from "react-icons/fa";
import Menubar from "../Menubar/Menubar";
import DropDownMenu from "./DropDownMenu";
import jwt_decode from "jwt-decode";
import { sendGetRequest, userFuncion, userLogin } from "../../../Helper/helper";

const Navbar = () => {

  const { states, changeState } = useContext(Context);
  const [dmode, setDmode] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [exchangeUrl, setExchangeUrl] = useState("/exchange");
  const modeChange = () =>
    dmode
      ? changeState(
          false,
          states.swidth,
          states.stokenbtn,
          states.buyTrade,
          states.isLogin
        )
      : changeState(
          true,
          states.swidth,
          states.stokenbtn,
          states.buyTrade,
          states.isLogin
        );

  localStorage.setItem("dmode", dmode);
  const navigate = useNavigate();

  const signin = () => navigate("/signin");
  const signup = () => navigate("/signup");

  useEffect(() => {
    toggle
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
    window.addEventListener("resize", () => {
      setToggle(false);
      if (states.stokenbtn) {
        changeState(states.dmode, window.innerWidth, false);
      }
    });
  }, [toggle]);

  const getUser = sessionStorage.getItem("user");
 // const isLogin = states.isLogin;
  const isLogin = sessionStorage.getItem("isLogin") === "true" ? true : false;
  const isSt = localStorage.getItem("st") == "1" ? "1" : "0";
  useEffect(() => {
    if (getUser !== "" && getUser !== "false") {
      try {
        const DecodedUser = jwt_decode(getUser);
        if (DecodedUser) {
          userLogin(true);
          changeState(
            states.dmode,
            window.innerWidth,
            states.stokenbtn,
            states.buyTrade,
            true,
            states.theme
          );
        } else {
          changeState(
            states.dmode,
            window.innerWidth,
            states.stokenbtn,
            states.buyTrade,
            false,
            states.theme
          );
          userLogin(false);
        }
      } catch (e) {
        if (e) {
          userFuncion(false);
          changeState(
            states.dmode,
            window.innerWidth,
            states.stokenbtn,
            states.buyTrade,
            false,
            states.theme
          );
        }
      }
    } else {
      changeState(
        states.dmode,
        window.innerWidth,
        states.stokenbtn,
        states.buyTrade,
        false,
        states.theme
      );
      userFuncion(false);
    }
  }, [getUser]);

  useEffect(() => {
    setDmode(states.dmode);
  }, [states.dmode]);


  //Latest
  const [theme, setTheme] = useState(
    //localStorage.getItem('theme') || 'light'
    localStorage.getItem('theme') || 'bodylightMode'
  );
   const lightTheme = () => {
    setTheme('bodylightMode');
    // if (theme === 'light') {
    //   setTheme('bodylightMode');
    // } else {
    //   setTheme('bodylightMode');
    // }
  };
  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.body.className = theme;
  }, [theme]);

   const darkTheme = () => {
    setTheme('bodydarkMode');
    // if (theme === 'dark') {
    //   setTheme('bodydarkMode');
    // } else {
    //   setTheme('bodydarkMode');
    // }
  };
  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.body.className = theme;
    changeState(
      states.dmode,
      states.swidth,
      states.stokenbtn,
      states.buyTrade,
      states.isLogin,
      theme
    );
  }, [theme]);
  useEffect(() => {
   //console.log("localStorage.getItem('theme')",localStorage.getItem('theme'));
    changeState(
      states.dmode,
      states.swidth,
      states.stokenbtn,
      states.buyTrade,
      states.isLogin,
      localStorage.getItem('theme')
    );
  }, []);


  //Latest End
  return (
    // <nav className={`${styles.nav  } flex`} >
    <nav className={`${styles.nav} flex  navbarMain lightMode`} >
      {isLogin ? (
        <div className={`${styles.loginNav} container flex-between`}>
          <div className="flex">
            <a href="/">
              <img className={styles.logo} src={logo} alt="logo" />
            </a>
            <div className={`${styles.navlinks} navlinks flex`}>
              <Link
                className={({ isActive }) =>
                  isActive
                    ? [styles.navlink, styles.active_nav_link].join(" ")
                    : styles.navlink
                }
                to="/"
              >
                Home
              </Link>
              <Link
                className={({ isActive }) =>
                  isActive
                    ? [styles.navlink, styles.active_nav_link].join(" ")
                    : styles.navlink
                }
                to={exchangeUrl}
              >
                Exchange
              </Link>
              {/* <Link
                className={({ isActive }) =>
                  isActive
                    ? [styles.navlink, styles.active_nav_link].join(" ")
                    : styles.navlink
                }
                to="/crorepati-lucky-draw"
              >
                 Crorepati Lucky Draw 2.0
              </Link> */}
              <Link
                className={({ isActive }) =>
                  isActive
                    ? [styles.navlink, styles.active_nav_link].join(" ")
                    : styles.navlink
                }
                to="/pension-rank"
              >
                 Pension Rank
              </Link>
              {/* <Link
                className={({ isActive }) =>
                  isActive
                    ? [styles.navlink, styles.active_nav_link].join(" ")
                    : styles.navlink
                }
                to="/btrl-game"
              >
                 BTRL Game
              </Link> */}
              
              {/*<Link
                className={({ isActive }) =>
                  isActive
                    ? [styles.navlink, styles.active_nav_link].join(" ")
                    : styles.navlink
                }
                to="/news"
              >
                News
              </Link>*/}
              <Link
                className={({ isActive }) =>
                  isActive
                    ? [styles.navlink, styles.active_nav_link].join(" ")
                    : styles.navlink
                }
                to="/contact-us"
              >
                Contact Us
              </Link>
            </div>
          </div>

          <div className={`${styles.rightnav} navlinks flex`}>
            <Link
              className={({ isActive }) =>
                isActive
                  ? [styles.navlink, styles.active_nav_link].join(" ")
                  : styles.navlink
              }
              to="/balance"
            /*  to={isSt=="1"?"/balance":"/bank-details"} */
            >
              Balance
            </Link>
            {/* <Link
              className={({ isActive }) =>
                isActive
                  ? [styles.navlink, styles.active_nav_link].join(" ")
                  : styles.navlink
              }
              to="/history"
            >
              History
            </Link> */}
            <Link
              className={({ isActive }) =>
                isActive
                  ? [styles.navlink, styles.active_nav_link].join(" ")
                  : styles.navlink
              }
              to="/stack"
            >
              Staking
            </Link>
            <Link
              className={({ isActive }) =>
                isActive
                  ? [styles.navlink, styles.active_nav_link].join(" ")
                  : styles.navlink
              }
              to="/referral"
            >
              Refer & Earn
            </Link>

            <DropDownMenu />

            
            <div className="darkLightBtn">
            <button
              onClick={lightTheme}
              type="button"
              className={`${styles.mode} flex-center darkLight `}
            >
              <i className="fa-regular fa-lightbulb"></i>
            </button>

            <button
              onClick={darkTheme}
              type="button"
              className={`${styles.mode} flex-center darkLight `}
            >
              <i className="fa-regular fa-moon"></i>
            </button>
          </div>
            

            <a
              href="https://wa.me/+919714826176"
              target="_blank"
              rel="noreferrer"
              className={`${styles.wts} flex-between wIcon`}
            >
              <FaWhatsapp />
            </a>

            <div
              className={
                toggle
                  ? [styles.menubar, styles.open].join(" ")
                  : styles.menubar
              }
              onClick={() => (toggle ? setToggle(false) : setToggle(true))}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>

            <Menubar toggle={toggle} changeToggle={setToggle} />
          </div>

          <div className={`bg`}></div>
        </div>
      ) : (
        <div className="container flex-between">

          <div className="flex">
            <a href="/">
              <img className={styles.logo} src={logo} alt="logo" />
            </a>
            <div className={`${styles.navlinks} navlinks flex`}>
              <Link
                className={({ isActive }) =>
                  isActive
                    ? [styles.navlink, styles.active_nav_link].join(" ")
                    : styles.navlink
                }
                to="/"
              >
                Home
              </Link>
              <Link
                className={({ isActive }) =>
                  isActive
                    ? [styles.navlink, styles.active_nav_link].join(" ")
                    : styles.navlink
                }
                to="/exchange"
              >
                Exchange
              </Link>
              {/* <Link
                className={({ isActive }) =>
                  isActive
                    ? [styles.navlink, styles.active_nav_link].join(" ")
                    : styles.navlink
                }
                to="/crorepati-lucky-draw"
              >
                 Crorepati Lucky Draw 2.0
              </Link> */}
              <Link
                className={({ isActive }) =>
                  isActive
                    ? [styles.navlink, styles.active_nav_link].join(" ")
                    : styles.navlink
                }
                to="/pension-rank"
              >
                 Pension Rank
              </Link>
              {/*  <Link
                className={({ isActive }) =>
                  isActive
                    ? [styles.navlink, styles.active_nav_link].join(" ")
                    : styles.navlink
                }
                to="/btrl-game"
              >
                 BTRL Game
              </Link> */}
              {/*<Link
                className={({ isActive }) =>
                  isActive
                    ? [styles.navlink, styles.active_nav_link].join(" ")
                    : styles.navlink
                }
                to="/news"
              >
                News
              </Link>*/}
              <Link
                className={({ isActive }) =>
                  isActive
                    ? [styles.navlink, styles.active_nav_link].join(" ")
                    : styles.navlink
                }
                to="/contact-us"
              >
                Contact Us
              </Link>
            </div>
          </div>


          <div className={`${styles.rightnav} flex navlinks navRight`}>
            <button onClick={signin} className="btn">
              Sign In
            </button>
            <button onClick={signup} className="btn btns">
              Sign Up
            </button>
            <div className="darkLightBtn">
            <button
              onClick={lightTheme}
              type="button"
              className={`${styles.mode} flex-center darkLight `}
            >
              <i className="fa-regular fa-lightbulb"></i>
            </button>

            <button
              onClick={darkTheme}
              type="button"
              className={`${styles.mode} flex-center darkLight `}
            >
              <i className="fa-regular fa-moon"></i>
            </button>
          </div>
            <a
              href="https://wa.me/+919714826176"
              target="_blank"
              rel="noreferrer"
              className={`${styles.wts} flex-between wIcon`}
            >
              <FaWhatsapp />
            </a>

            <div
              className={
                toggle
                  ? [styles.menubar, styles.open].join(" ")
                  : styles.menubar
              }
              onClick={() => (toggle ? setToggle(false) : setToggle(true))}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>

            

            <Menubar toggle={toggle} />


          </div>
          <div className={`bg`}></div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
