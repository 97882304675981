import React, { useContext, useState, useEffect, useRef } from "react";
import Footer from "../../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../../Components/HomeComponents/Navbar/Navbar";
import Context from "../../../Context/Context";
import styles from "./History.module.scss";
import { BiTransfer, BiSortUp } from "react-icons/bi";
import { ImUpload3, ImDownload3 } from "react-icons/im";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { DateRangePicker,DateRange } from 'react-date-range';
import "react-datepicker/dist/react-datepicker.css";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import MyDateRangePicker from "../../../Components/DateRange/MyDateRangePicker"
import {
  authRequest,
  authRequestNb,
  deposit_history_headers,
  openOrderDataHandler,
  open_order_history_headers,
  refreshToken,
  trade_history_headers,
  withdraw_history_headers,
} from "../../../Helper/helper";
import Table1 from "./Table1";
import Swal from "sweetalert2";
typeof localStorage.getItem("history") !== "string" &&
  localStorage.setItem("history", "deposit_history");

const History = ({ openOrder, tradeHistory }) => {
  const [svalue, setSvalue] = useState(4);
  const [activeValue, setActiveValue] = useState(
    localStorage.getItem("history")
  );
  
  const [currencyFilters, setCurrencyFilters] = useState();
  const [pairsFilters, setPairsFilters] = useState();
  const [data, setData] = useState({});
  const [cancelNo, setCancelNo] = useState();
  const [statusFilterValue, setStatusFilterValue] = useState("");
  
  const [allFiters, setAllFiters] = useState({
    deposit_history: "",
    trade_history: "",
    withdraw_history: "",
    open_order_history: "",
  });
  const [allPagesData, setAllPagesData] = useState(
    JSON.parse(localStorage.getItem("Pages"))
      ? JSON.parse(localStorage.getItem("Pages"))
      : {
        deposit_history: 1,
        trade_history: 1,
        withdraw_history: 1,
        open_order_history: 1,
      }
  );
  // const [allPagesData, setAllPagesData] = useState({deposit_history: 1, trade_history: 1, withdraw_history: 1, open_order_history: 1})
  const [nextEl, setNextEl] = useState(null);
  const [ooData, setOoData] = useState();
  const [prevEl, setPrevEl] = useState(null);
  const [ttype, setTtype] = useState("");
  const [ttypeval, setTtypeval] = useState("");
  const [tfilter, setTfilter] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [newActivePage, setNewActivePage] = useState(false);

  const [myfilter, setMyfilter] = useState(
    {
      deposit_history:{currency:"",filter:"",startdate:"",enddate:""},
      trade_history:{currency:"",market:"",filter:""},
      withdraw_history:{currency:"",filter:"",startdate:"",enddate:""},
      open_order_history:{currency:"",market:""}
  });
  const [tfilterval, setTfilterval] = useState("");
  const { states } = useContext(Context);
  const switchTab=(activeTav)=>{
    //setActiveValue("withdraw_history")
  }
  const setFilters = (key, val) => {
    let change = allFiters;
    change[key] = val;
    setAllFiters({ ...change });
  };
  const cancelOrder = async (_id) => {
    setCancelNo(_id);
    const res = await authRequest({ orderid: _id }, "cancel-order");
    res.refresh_token && refreshToken(res.refresh_token);
    let timerInterval;
    res &&
      Swal.fire({
        html: res.message,
        timer: 2000,
        timerProgressBar: true,
        position: "top-right",
        padding: "1rem",
        showConfirmButton: false,
        scrollbarPadding: false,
        allowOutsideClick: false,
        backdrop: false,
        customClass: {
          timerProgressBar: "tgb",
          popup: "popup",
          htmlContainer: "htmlContainer",
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      });
  };
  const onstType=(event)=>{
   
      setTtype("type")
      setTtypeval(event.target.value)
      onOptionChangeHandler(event.target.value,event.target.value,tfilterval);

    
  }
  const currenyOnChange=(event)=>{
   
    setTtype("type")
    setTfilter("filter")
    setTtypeval(event.target.value)
   
   if(activeValue==="deposit_history"){
    setMyfilter(prevState => ({
      ...prevState, // Spread operator to copy previous state
      deposit_history: {
        ...prevState.deposit_history, // Spread operator to copy previous open_order_history object
        currency: event.target.value, // Updating the currency value
      },
    }));
    setAllPagesData((prevState) => ({
      ...prevState, // Keep all existing properties
      deposit_history: 1, // Reset deposit_history to its default value or any new value
    }));
   }
   else{
    setMyfilter(prevState => ({
      ...prevState, // Spread operator to copy previous state
      withdraw_history: {
        ...prevState.withdraw_history, // Spread operator to copy previous open_order_history object
        currency: event.target.value, // Updating the currency value
      },
    }));
    setAllPagesData((prevState) => ({
      ...prevState, // Keep all existing properties
      withdraw_history: 1, // Reset withdraw_history to its default value or any new value
    }));
   }
 
  
}
const changeOnPairs=(event)=>{
 
    setTtype("type")
    setTfilter("filter")
    setTtypeval(event.target.value)
    var pair=event.target.value;
    const etv = pair.split(",");
    console.log("activeValue",activeValue);
    if(activeValue==="trade_history"){
      setMyfilter(prevState => ({
        ...prevState, // Spread operator to copy previous state
        trade_history: {
          ...prevState.trade_history, // Spread operator to copy previous open_order_history object
          currency: etv[0], // Updating the currency value
          market: etv[1], // Updating the currency value
        },
      }));
      setAllPagesData((prevState) => ({
        ...prevState, // Keep all existing properties
        trade_history: 1, // Reset trade_history to its default value or any new value
      }));
     }
     else{
      setMyfilter(prevState => ({
        ...prevState, // Spread operator to copy previous state
        open_order_history: {
          ...prevState.open_order_history, // Spread operator to copy previous open_order_history object
          currency: etv[0], // Updating the currency value
          market: etv[1], // Updating the market value
        },
      }));
      setAllPagesData((prevState) => ({
        ...prevState, // Keep all existing properties
        open_order_history: 1, // Reset open_order_history to its default value or any new value
      }));
     }
    
    
}
  const onstFilter=(event)=>{
   
    setTfilter("filter")
    setTfilterval(event.target.value)
    //onOptionChangeHandler(event.target.value,ttypeval,event.target.value);
    setMyfilter(prevState => ({
      ...prevState, // Spread operator to copy previous state
      trade_history: {
        ...prevState.trade_history, // Spread operator to copy previous open_order_history object
        filter: event.target.value, // Updating the fiter value
      },
    }));
    setAllPagesData((prevState) => ({
      ...prevState, // Keep all existing properties
      trade_history: 1, // Reset trade_history to its default value or any new value
    }));
    
  }
  
  const onOptionMyChangeHandler = async (myfilter) => {
    
    if (activeValue === "deposit_history") {
      const dhres = await authRequest(
        myfilter.deposit_history,
        "deposit-history"
      );
      refreshToken(dhres.refresh_token);
      setDataFunc("deposit_history", dhres);
    } else if (activeValue === "trade_history") {
        const thres = await authRequest(
          myfilter.trade_history,
          "order-history"
        );
        refreshToken(thres.refresh_token);
        setDataFunc("trade_history", thres);
       // console.log("trade history filtered ", thres)
    } else if (activeValue === "withdraw_history") {
      const thres = await authRequest(
        myfilter.withdraw_history,
        "withdraw-history"
      );
      refreshToken(thres.refresh_token);
      setDataFunc("withdraw_history", thres);
    } else if (activeValue === "open_order_history") {
      const mynewFilter=myfilter.open_order_history;
      /* 
       start: activePage > 1 ? activePage * 10 - 10 : "",
            limit: "10",
      */
      mynewFilter.start= allPagesData[activeValue] > 1? allPagesData[activeValue] * 10 - 10:"";
      mynewFilter.limit="10";
      //allPagesData[activeValue]
      const thres = await authRequest(
        mynewFilter,
        "inorder"
      );
      refreshToken(thres.refresh_token);
      setDataFunc("open_order_history", thres);
    }
  };
  const onOptionChangeHandler = async (event, type = "",filter="") => {
    if(activeValue !== "trade_history"){

      setFilters(activeValue, event.target.value);
    }
    if (activeValue === "deposit_history") {
      const dhres = await authRequest(
        {
          currency: event.target.value,
        },
        "deposit-history"
      );
      refreshToken(dhres.refresh_token);
      setDataFunc("deposit_history", dhres);
    } else if (activeValue === "trade_history") {
        /* 
          if (type == "pairs") {
            setStatusFilterValue("")
            const etv = event.target.value.split(",");
            const thres = await authRequest(
              {
                currency: etv[0],
                market: etv[1] ? etv[1] : "",
              },
              "order-history"
            );
            refreshToken(thres.refresh_token);
            setDataFunc("trade_history", thres);
          } else if (type == "status") {
            setFilters(activeValue, "");
            setStatusFilterValue(event.target.value)

            const thres = await authRequest(
              {
                filter: event.target.value
              },
              "order-history"
            );
            refreshToken(thres.refresh_token);
            setDataFunc("trade_history", thres);
            console.log("trade history filtered ", thres)
          } 
        */
      
       // console.log("type=>",type);
        var stType={}
      if(type!==""){
        setFilters("trade_history",type);
        //const etv = event.target.value.split(",");
        const etv = type.split(",");
            stType.currency= etv[0]
            stType.market= etv[1] 
          
      }
      else{
        setFilters("trade_history","");
        stType.currency= ""
        stType.market= "" 
      }
      if(filter!==""){
        setStatusFilterValue(filter)
        stType.filter=filter
      }
      else{
        setStatusFilterValue("")
        stType.filter=""
      }
        const thres = await authRequest(
          stType,
          "order-history"
        );
        refreshToken(thres.refresh_token);
        setDataFunc("trade_history", thres);
       // console.log("trade history filtered ", thres)
    } else if (activeValue === "withdraw_history") {
      const thres = await authRequest(
        {
          currency: event.target.value,
        },
        "withdraw-history"
      );
      refreshToken(thres.refresh_token);
      setDataFunc("withdraw_history", thres);
    } else if (activeValue === "open_order_history") {
      const etv = event.target.value.split(",");
     
      const thres = await authRequest(
        {
          // currency: etv[0],
          // market: etv[1],
          currency: etv[0],
          market: etv[1] ? (etv[1]!==undefined?etv[1]:"") : "",
        },
        "inorder"
      );
      refreshToken(thres.refresh_token);
      setDataFunc("open_order_history", thres);
    }
  };
  useEffect(() => {
    if (openOrder && data.open_order_history) {
      openOrderDataHandler(data.open_order_history.data, openOrder, setOoData);
      setDataFunc("open_order_history", ooData);
    }
  }, [openOrder]);

  const dmode = states.dmode;

  const setFunc = () => {
    const ssize = window.innerWidth;
    if (ssize >= 1192) {
      setSvalue(4);
    }
    if (ssize <= 1176) {
      setSvalue(3);
    }
    if (ssize <= 999) {
      setSvalue(2);
    }
    if (ssize <= 800) {
      setSvalue(1);
    }
    if (ssize <= 590) {
      setSvalue(2);
    }
    if (ssize <= 500) {
      setSvalue(2);
    }
  };

  const setDataFunc = (key, val) => {
    let change = data;
    change[key] = val;
    setData({ ...change });
  };

  useEffect(() => {
    if(tfilter!==""){
      (async () => {
       //console.log("myfilter",myfilter);
       await onOptionMyChangeHandler(myfilter)
     })();

    }
    
  },[myfilter,tfilter])
  useEffect(() => {
    setFunc();
    window.addEventListener("resize", setFunc);
    // window.scrollTo(0, 0)
    (async () => {
      try {
        const thres = await authRequestNb("order-history");
        const oohres = await authRequestNb("inorder");
        const dhres = await authRequestNb("deposit-history");
        const whres = await authRequestNb("withdraw-history");

        refreshToken(thres.refresh_token);
        refreshToken(oohres.refresh_token);
        refreshToken(dhres.refresh_token);
        refreshToken(whres.refresh_token);

        setDataFunc("deposit_history", dhres);
        setDataFunc("trade_history", thres);
        setDataFunc("withdraw_history", whres);
        setDataFunc("open_order_history", oohres);
      } catch (e) {
        e && console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const currencyFilterRes = await authRequest(
          { type: "filter" },
          "get-currency"
        );
        const pairsFilterRes = await authRequest(
          { type: "filter" },
          "get-pairs"
        );
        setCurrencyFilters(currencyFilterRes.data);
        setPairsFilters(pairsFilterRes.data);
        refreshToken(currencyFilterRes.refresh_token);
        refreshToken(pairsFilterRes.refresh_token);
      } catch (error) {
        error && console.log(error);
      }
    })();
  }, []);
//console.log("activeValue",allPagesData[activeValue])
  useEffect(() => {
    
    localStorage.setItem("history", activeValue);
    setMyfilter(
      {
        deposit_history:{currency:"",filter:"",startdate:"",enddate:""},
        trade_history:{currency:"",market:"",filter:""},
        withdraw_history:{currency:"",filter:"",startdate:"",enddate:""},
        open_order_history:{currency:"",market:""}
    })
  }, [activeValue]);



  const date = new Date();
  const date2=new Date();
  const [state, setState] = useState([
    {
      /* startDate: new Date(date.setDate(date.getDate()-365)),
      endDate: date2, */
      //startDate: new Date(),
      startDate: null,
      endDate: new Date(),
      maxDate:new Date(),
      key: 'selection'
    }
  ]);
  const onToggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(()=>{
    //console.log("state",state);
    if(state[0].startDate!=null && state[0].endDate!=null){
      setTfilter("filter")
      var startDate=state[0].startDate.toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3/$1/$2")
      var endDate=state[0].endDate.toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3/$1/$2")
       //console.log("newDate",newDate);
       if(activeValue==="deposit_history"){
        setMyfilter(prevState => ({
          ...prevState, // Spread operator to copy previous state
          deposit_history: {
            ...prevState.deposit_history, // Spread operator to copy previous open_order_history object
            startdate: startDate, // Updating the fiter value
            enddate: endDate,
          },
        }));
        setAllPagesData((prevState) => ({
          ...prevState, // Keep all existing properties
          deposit_history: 1, // Reset deposit_history to its default value or any new value
        }));
       }
       else{
        setMyfilter(prevState => ({
          ...prevState, // Spread operator to copy previous state
          withdraw_history: {
            ...prevState.withdraw_history, // Spread operator to copy previous open_order_history object
            startdate: startDate, // Updating the fiter value
            enddate: endDate,
          },
        }));
        setAllPagesData((prevState) => ({
          ...prevState, // Keep all existing properties
          withdraw_history: 1, // Reset withdraw_history to its default value or any new value
        }));
       }
    }else{
      var startDate="";
      var endDate="";
      setMyfilter(prevState => ({
        ...prevState, // Spread operator to copy previous state
        withdraw_history: {
          ...prevState.withdraw_history, // Spread operator to copy previous open_order_history object
          startdate: startDate, // Updating the fiter value
          enddate: endDate,
        },
      }));
      setAllPagesData((prevState) => ({
        ...prevState, // Keep all existing properties
        withdraw_history: 1, // Reset withdraw_history to its default value or any new value
      }));
    }
    
 
     
      
  },[state])
  const refOne = useRef(null);
useEffect(()=>{
 
  const handleOutsideClick=(e)=>{
    if(refOne.current && !refOne.current.contains(e.target)){
      //onToggle()
    setIsOpen(false);

    }
  }
  document.addEventListener("click",handleOutsideClick,true);
  return () => {
    document.removeEventListener('click', handleOutsideClick, true);
  };
},[])
  // useEffect(() => {
  //   console.log("statusFilterValue ==>>>>>>>>>>>>>> ", statusFilterValue)
  //   if (statusFilterValue) {
  //     (async () => {
  //       const thres = await authRequest(
  //         {
  //           filter: statusFilterValue
  //         },
  //         "order-history"
  //       );
  //       refreshToken(thres.refresh_token);
  //       setDataFunc("trade_history", thres);
  //       console.log("trade history filtered ", thres)
  //     })()
  //   }
  // }, [statusFilterValue]);
  useEffect(() => {
    (async () => {
      if (cancelNo) {
       // const oohres = await authRequestNb("inorder");
       if(myfilter.open_order_history.currency!=="" && myfilter.open_order_history.currency!=="" ){

         const searchData={currency:myfilter.open_order_history.currency,market:myfilter.open_order_history.market}
         searchData.start= allPagesData[activeValue] > 1? allPagesData[activeValue] * 10 - 10:"";
         searchData.limit="10";
         const oohres = await authRequest(searchData,"inorder");
          refreshToken(oohres.refresh_token);
          setDataFunc("open_order_history", oohres);
       }
       else{
        //const oohres = await authRequestNb("inorder");
        const searchData={};
        searchData.start= allPagesData[activeValue] > 1? allPagesData[activeValue] * 10 - 10:"";
         searchData.limit="10";
         const oohres = await authRequest(searchData,"inorder");
        refreshToken(oohres.refresh_token);
        setDataFunc("open_order_history", oohres);
       }
      }
    })();
  }, [cancelNo]);
  return (
    <div className={`${styles.his_l}  ligtMode`}>
      <Navbar />
      <div className={`{styles.his_wrapper} container`}>

        <div className="bannerMy  text-center balanceBanner2">
          <div className="bannerIn">
            <h4>History Transactions</h4>
            <p>Track Deposit, Trade, Withdraw and Open Order activity on the BTRL.</p>
            <a href="/exchange" className="custombtn">Trade Now</a>&nbsp;
            <MyDateRangePicker />
          </div>
        </div>
        <h5 className={`${styles.note} noteHere mt-5 mb-3`}>
          {"Note : - Drag right for all options >>>"}
        </h5>
        <div className={`${styles.his} tbalehead`}>
          <div className={`${styles.sf} flex-between tabbtns`}>
            <div className={styles.slider}>
              <div
                style={{ display: svalue === 4 ? "none" : "flex" }}
                className={`${styles.nevi} flex-center`}
                ref={(node) => setPrevEl(node)}
              >
                <AiOutlineLeft />
              </div>
              <Swiper
                slidesPerView={svalue}
                slidesPerGroup={1}
                loop={svalue > 3 ? false : true}
                navigation={{ prevEl, nextEl }}
                modules={[Navigation]}
                className={styles.options}
              >
                <SwiperSlide
                  style={{ height: "50px" }}
                  className={svalue < 4 ? "flex-center" : styles.start}
                >
                  <button
                    onClick={() => setActiveValue("deposit_history")}
                    className={`${activeValue === "deposit_history"
                      ? styles.active
                      : styles.btno
                      } btn`}
                  >
                    <span>
                      <ImDownload3 />
                    </span>
                    Deposit History
                  </button>
                </SwiperSlide>
                <SwiperSlide
                  style={{ height: "50px" }}
                  className={svalue < 4 ? "flex-center" : styles.start}
                >
                  <button
                    onClick={() => setActiveValue("trade_history")}
                    className={`${activeValue === "trade_history"
                      ? styles.active
                      : styles.btno
                      } btn`}
                  >
                    <span>
                      <BiTransfer />
                    </span>
                    Trade History
                  </button>
                </SwiperSlide>
                <SwiperSlide
                  style={{ height: "50px" }}
                  className={svalue < 4 ? "flex-center" : styles.start}
                >
                  <button
                    onClick={() => setActiveValue("withdraw_history")}
                    className={`${activeValue === "withdraw_history"
                      ? styles.active
                      : styles.btno
                      } btn`}
                  >
                    <span>
                      <ImUpload3 />
                    </span>
                    Withdraw History
                  </button>
                </SwiperSlide>
                <SwiperSlide
                  style={{ height: "50px" }}
                  className={svalue < 4 ? "flex-center" : styles.start}
                >
                  <button
                    onClick={() => setActiveValue("open_order_history")}
                    className={`${activeValue === "open_order_history"
                      ? styles.active
                      : styles.btno
                      } btn`}
                  >
                    <span>
                      <BiSortUp />
                    </span>
                    Open Order
                  </button>
                </SwiperSlide>
              </Swiper>
              <div
                style={{ display: svalue === 4 ? "none" : "flex" }}
                className={`${styles.nevi} flex-center`}
                ref={(node) => setNextEl(node)}
              >
                <AiOutlineRight />
              </div>
            </div>

            <div className={`${styles.filters} flex`}>
              <h3 className="mb-0">Filter By:</h3>
              {/* <div className={`styles.sbox cusSelect`}>
                {activeValue && activeValue === "deposit_history" ? (
                  <select className=''
                    value={allFiters.deposit_history}
                    onChange={onOptionChangeHandler}
                  >
                    <option value={""}>Currency</option>
                    {currencyFilters?.map((option, index) => (
                      <option value={option.currency_symbol} key={index}>
                        {option.currency_symbol}
                      </option>
                    ))}
                  </select>
                ) : activeValue === "withdraw_history" ? (
                  <select
                    value={allFiters.withdraw_history}
                    onChange={onOptionChangeHandler}
                  >
                    <option value={""}>Currency</option>
                    {currencyFilters?.map((option, index) => (
                      <option value={option.currency_symbol} key={index}>
                        {option.currency_symbol}
                      </option>
                    ))}
                  </select>
                ) : activeValue === "trade_history" ? (
                  <div className="d-flex">
                    <select
                      value={allFiters.trade_history}
                      onChange={(e) => { onstType(e) }}
                    >
                      <option value={""}>Pairs</option>
                      {pairsFilters?.map((option, index) => (
                        <option
                          value={[option.currency_symbol, option.market_symbol]}
                          key={index}
                        >
                          {option.pair}
                        </option>
                      ))}
                    </select>
                    <select
                      value={statusFilterValue} onChange={(e) => { onstFilter(e) }}
                    // value={statusFilterValue} onChange={(e) => setStatusFilterValue(e.target.value)}
                    >
                      <option key={0} value={""}>Status</option>

                      <option key={1} value={"Complete"}>
                        Complete
                      </option>
                      <option key={2} value={"Partial Completed"}>
                        Partial Complete
                      </option>
                      <option key={3} value={"Canceled"}>
                        Cancelled
                      </option>

                    </select>
                  </div>
                ) : (
                  <select
                    value={allFiters.open_order_history}
                    onChange={onOptionChangeHandler}
                  >
                    <option value={""}>Pairs</option>
                    {pairsFilters?.map((option, index) => (
                      <option
                        value={[option.currency_symbol, option.market_symbol]}
                        key={index}
                      >
                        {option.pair}
                      </option>
                    ))}
                  </select>
                )}
              </div> */}
              <div className={`styles.sbox cusSelect`}>
                {activeValue && activeValue === "deposit_history" ? (
                  <>
                  <select  className=''
                    //value={allFiters.deposit_history}
                    //value={myfilter.currency}
                    value={activeValue === "deposit_history" ?myfilter.deposit_history.currency:""}
                    onChange={(e) => currenyOnChange(e)}
                  >
                    <option value={""}>Currency</option>
                    {currencyFilters?.map((option, index) => (
                      <option value={option.currency_symbol} key={index}>
                        {option.currency_symbol}
                      </option>
                    ))}
                  </select>
                 {/*  <input type="text " style={{"display":"inline-block"}} /> */}
                  </>
                ) : activeValue === "withdraw_history" ? (
                  <>
                  <select 
                    //value={allFiters.withdraw_history}
                   // value={myfilter.currency}
                   value={activeValue === "withdraw_history" ?myfilter.withdraw_history.currency:""}
                    onChange={(e) => currenyOnChange(e)}
                  >
                    <option value={""}>Currency</option>
                    {currencyFilters?.map((option, index) => (
                      <option value={option.currency_symbol} key={index}>
                        {option.currency_symbol}
                      </option>
                    ))}
                  </select>
                 {/*  <input type="text " style={{"display":"inline-block"}}  /> */}
                  </>
                ) : activeValue === "trade_history" ? (
                  <div className="d-flex">
                    <select
                      //value={allFiters.trade_history}
                     // value={[myfilter.currency, myfilter.market]}
                     value={activeValue === "trade_history" ?[myfilter.trade_history.currency, myfilter.trade_history.market]:""}
                      onChange={(e) => { changeOnPairs(e) }}
                    >
                      <option value={["",""]}>Pairs</option>
                      {pairsFilters?.map((option, index) => (
                        <option
                          value={[option.currency_symbol, option.market_symbol]}
                          key={index}
                        >
                          {option.pair}
                        </option>
                      ))}
                    </select>
                    <select
                      //value={statusFilterValue} 
                      value={activeValue === "trade_history" ?myfilter.trade_history.filter:""}
                      //selected={startDate} 
                      onChange={(e) => { onstFilter(e) }}
                     //value={statusFilterValue} onChange={(e) => setStatusFilterValue(e)}
                    >
                      <option key={0} value={""}>Status</option>

                      <option key={1} value={"Complete"}>
                        Complete
                      </option>
                      <option key={2} value={"Partial Completed"}>
                        Partial Complete
                      </option>
                      <option key={3} value={"Canceled"}>
                        Cancelled
                      </option>

                    </select>
                  </div>
                ) : (
                  <select
                    //value={allFiters.open_order_history}
                   // value={myfilter.currency}
                   value={activeValue === "open_order_history" ?[myfilter?.open_order_history?.currency, myfilter?.open_order_history?.market]:""}
                    onChange={(e) => changeOnPairs(e)}
                  >
                    <option value={["",""]}>Pairs</option>
                    {pairsFilters?.map((option, index) => (
                      <option
                        value={[option.currency_symbol, option.market_symbol]}
                        key={index}
                      >
                        {option.pair}
                      </option>
                    ))}
                  </select>
                )}
                
                
                 </div>
            </div>
            <div className={`mydaterangepicker`} ref={refOne}>

              
                {activeValue && (activeValue === "deposit_history" || activeValue === "withdraw_history") ? (
                  <>
               
                  <input
                    type="text"
                    placeholder="Select Date Range" className="form-control inputdark" defaultValue={(activeValue==="deposit_history")?myfilter.deposit_history.startdate!==""?myfilter.deposit_history.startdate+' to '+myfilter.deposit_history.enddate:"":myfilter.withdraw_history.startdate!==""?myfilter.withdraw_history.startdate+' to '+myfilter.withdraw_history.enddate:""}
                    onClick={onToggle}
                  />
                  {isOpen && (
                            <DateRange
                              onChange={item => setState([item.selection])}
                              editableDateInputs={true}
                              moveRangeOnFirstSelection={false}
                              ranges={state}
                              
                              /* onClickOutside={() => {setIsOpen(!isOpen)}} */
                              
                    />)}
                    </>
                    ):
                    <></>
                }
              </div>
          </div>
          {activeValue === "trade_history" && (
            <Table1
              _activeValue={activeValue}
              _filterData={allFiters}
              allPagesData={allPagesData}
              setAllPagesData={setAllPagesData}
              headers={trade_history_headers}
              tradeHistory={tradeHistory}
              data={data.trade_history}
              statusFilterValue={statusFilterValue}
              myfilter={myfilter}
            />
          )}
          {activeValue === "deposit_history" && (
            <Table1
              _activeValue={activeValue}
              _filterData={allFiters}
              allPagesData={allPagesData}
              setAllPagesData={setAllPagesData}
              headers={deposit_history_headers}
              data={data.deposit_history}
              dmode={dmode}
              myfilter={myfilter}
            />
          )}
          {activeValue === "withdraw_history" && (
            <Table1
              _activeValue={activeValue}
              _filterData={allFiters}
              allPagesData={allPagesData}
              setAllPagesData={setAllPagesData}
              headers={withdraw_history_headers}
              data={data.withdraw_history}
              dmode={dmode}
              myfilter={myfilter}
            />
          )}
          {activeValue === "open_order_history" && (
            <Table1
              _activeValue={activeValue}
              _filterData={allFiters}
              allPagesData={allPagesData}
              setAllPagesData={setAllPagesData}
              headers={open_order_history_headers}
              ooData={ooData}
              tradeHistory={tradeHistory}
              data={data.open_order_history}
              cancelOrder={cancelOrder}
              myfilter={myfilter}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default History;
