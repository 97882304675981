import React, { useContext, useEffect , useState } from "react";
import Context from "../../../Context/Context";
import styles from "./MiniMarket.module.scss";
import {AiFillCaretDown, AiFillCaretUp} from 'react-icons/ai';
import { fixedNumber, isMax } from "../../../Helper/helper";

const MiniMarket = ({ miniData , dmode , popup , setPopup }) => {
  const { states } = useContext(Context);
  const [cs, setCs] = useState();
  const [ms, setMs] = useState();

  const scF = () => {
    if (window.innerWidth <= 1330) {
      if(states.stokenbtn){
        setPopup(false)
      }else{
        setPopup(true)
      }
    }
  };

  useEffect(() => {
    if(miniData){
      let [a , b] = miniData?.pair.split("_")
      setCs(a)
      setMs(b)
    } 
  }, [miniData])
  return (
    <div 
    className={`${styles.mm_l} chartHere`}>
      <div className={`${styles.grids} borderbottom`}>
        <div className={styles.grid_c}>
          <button onClick={scF} className="flex-center currenyboth">

              {/* <h1>BITCOIN</h1> */}
              <h1 className="d-flex">{`${cs}/${ms}`}
            <div className={`${styles.arrow} flex-center`}>{popup ? <AiFillCaretUp /> : <AiFillCaretDown />}</div></h1>

          </button>
        </div>
        <div className={styles.grid_v}>
        <h2 className='text-end vaolumtext'>Volume :<br/>{fixedNumber(miniData?.quote , 12)} {miniData?.currency_symbol} / {fixedNumber(miniData?.base , 12)} {miniData?.market_symbol}</h2>
          {/* ****** price and value ****** */}
          <div className={`${styles.respv} flex-between`}>
          <div className={`${styles.pandc} flex-start column`}>
          
          <div className="flex"><h5>Last price : </h5> <p>{fixedNumber(miniData?.price , 12)}</p></div>
          <div className="flex"><h5>%Change : </h5>
          {/* <p className={parseFloat(miniData?.percent_change).toFixed(2)===0?"":isMax(miniData?.pair , parseFloat(miniData?.percent_change)) ? "textsuccess" :  (parseFloat(miniData?.percent_change).toFixed(2)>0?"textsuccess":parseFloat(miniData?.percent_change).toFixed(2)===0?isMax(miniData?.pair , parseFloat(miniData?.percent_change)) === false ? "textdanger" :"":"textdanger")}> */}
          {/* <p className={isMax(miniData?.pair , parseFloat(miniData?.percent_change)) ? "textsuccess" : isMax(miniData?.pair , parseFloat(miniData?.percent_change)) === false ? "textdanger" : (parseFloat(miniData?.percent_change).toFixed(2)===0?"":(parseFloat(miniData?.percent_change).toFixed(2)>0?"textsuccess":"textdanger"))}> */}
          <p className={((parseFloat(miniData?.percent_change).toFixed(2)===0 || (parseFloat(miniData?.percent_change).toFixed(2)).toString()==="0.00" || (parseFloat(miniData?.percent_change).toFixed(2)).toString()==="-0.00")?"":(parseFloat(miniData?.percent_change).toFixed(2)>0?"textsuccess":"textdanger"))}>
                  {parseFloat(miniData?.percent_change).toFixed(2)}%
                </p>
          </div>
          </div>
          <div className={`${styles.resv} flex-center`}>
            <h5>VOLUME : </h5>
            <p>{fixedNumber(miniData?.quote , 12)} {miniData?.currency_symbol} /{fixedNumber(miniData?.base , 12)} {miniData?.market_symbol}</p> 
          </div>
          </div>
          {/* ****** price and value ****** */}
        </div>

      </div>  
        <div className="allboxesmini d-grid">
        <div className={`${styles.rows} flex-start column`}>
          <h5>Last Price</h5>
          <p>{fixedNumber(miniData?.price , 12)}</p>
        </div>
        <div className={`${styles.rows} flex-start column`}>
          <h5>%Change</h5>
          {/* <p className={isMax(miniData?.pair , parseFloat(miniData?.percent_change)) ? styles.pos : isMax(miniData?.pair , parseFloat(miniData?.percent_change)) === false ? styles.neg : ""}> */}
          {/* <p className={parseFloat(miniData?.percent_change).toFixed(2)===0?"":isMax(miniData?.pair , parseFloat(miniData?.percent_change)) ? "textsuccess" :  (parseFloat(miniData?.percent_change).toFixed(2)>0?"textsuccess":parseFloat(miniData?.percent_change).toFixed(2)===0?isMax(miniData?.pair , parseFloat(miniData?.percent_change)) === false ? "textdanger" :"":"textdanger")}> */}
          {/* <p className={isMax(miniData?.pair , parseFloat(miniData?.percent_change)) ? "textsuccess" : isMax(miniData?.pair , parseFloat(miniData?.percent_change)) === false ? "textdanger" : (parseFloat(miniData?.percent_change).toFixed(2)===0?"":(parseFloat(miniData?.percent_change).toFixed(2)>0?"textsuccess":"textdanger"))}> */}
          <p className={((parseFloat(miniData?.percent_change).toFixed(2)===0  || (parseFloat(miniData?.percent_change).toFixed(2)).toString()==="0.00" || (parseFloat(miniData?.percent_change).toFixed(2)).toString()==="-0.00")?"":(parseFloat(miniData?.percent_change).toFixed(2)>0?"textsuccess":"textdanger"))}>
                  {parseFloat(miniData?.percent_change).toFixed(2)}%
                </p>
        </div>
        <div className={`${styles.rows} flex-start column`}>
          <h5>High</h5>
          <p>{fixedNumber(miniData?.high , 12)}</p>
        </div>
        <div className={`${styles.rows} flex-start column`}>
          <h5>Low</h5>
          <p>{fixedNumber(miniData?.low , 12)}</p>
        </div>
        <div className={`${styles.rows} flex-start column`}>
          <h5>Open</h5>
          <p>{fixedNumber(miniData?.open , 12)}</p>
        </div>
        <div className={`${styles.rows} flex-start column`}>
          <h5>Close</h5>
          <p>{fixedNumber(miniData?.close , 12)}</p>
        </div>
      </div>

        {/* ******** tab mode ****** */}
        <div className={styles.tmmm}>
        <div className={`flex-start column`}>
          <h5>High</h5>
          <p>{fixedNumber(miniData?.high , 12)}</p>
        </div>
        <div className={`flex-start column`}>
          <h5>Low</h5>
          <p>{fixedNumber(miniData?.low , 12)}</p>
        </div>
        <div className={`flex-start column`}>
          <h5>Open</h5>
          <p>{fixedNumber(miniData?.open , 12)}</p>
        </div>
        <div className={`flex-start column`}>
          <h5>Close</h5>
          <p>{fixedNumber(miniData?.close , 12)}</p>
        </div>
        </div>
        {/* ******** tab mode ****** */}
    </div>
  );
};

export default MiniMarket;
