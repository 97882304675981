import { useState , useEffect  } from "react";
import { FileUploader } from "react-drag-drop-files";
import swal from "sweetalert";
import { refreshToken, sendFileRequest } from "../../Helper/helper";
import styles from './DragandDrop.module.scss';

const fileTypes = ["JPEG","JPG", "PNG", "PDF"];



const DragandDrop = ({ type , name , status , img , data , getValue , formKey  }) => {
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [fileRes, setFileRes] = useState();
      const handleChange = (_file) => {
        setFile(_file);
      };

      useEffect(() => {
        img && setFileRes({data: img})
      }, [img])

      useEffect(() => {
        if(file){
          if(file[0].size < parseFloat(data?.file_size)*1e+6){
            (async()=>{
              setUploading(true);
              const res = await sendFileRequest({file : file[0] , type : type , name : name} , "upload-document");
              setUploading(false)
              if(res.status===200){
                setFileRes(res)
                getValue(formKey , res.data)
              }else{
                swal(res.message)
                setFile(null)
              }
              refreshToken(res.refresh_token)
            })()
          }else{
            swal(`Maximum size of image must be ${data?.file_size}MB`)
          }
        }
      }, [file])


      return (
        <div className={styles.dandd}>
          {status && <FileUploader
            multiple={true}
            handleChange={handleChange}
            name="file"
            types={fileTypes}
          />}
          {fileRes && <img src={fileRes.data} alt="file" />}
          {uploading && <p>Uploading <i className="fa fa-spinner fa-spin"></i></p>}
          {!fileRes && !uploading && <p>Drag and drop file here<br/>Only {data?.file_type?.toUpperCase()}<br/><span>(or click to select file)</span></p>}
          {fileRes && !uploading && status && <p className={status ? styles.onHover : ""}>Drag and drop file here for change<br/>Only {data?.file_type?.toUpperCase()}<br/><span>(or click to select file)</span></p>}
        </div>
      );
}

export default DragandDrop