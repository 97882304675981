/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./OpenOrder.module.scss";
import nodata from "../../../Assets/nodata.png";
import {
  authRequest,
  fixedNumber,
  openOrderDataHandler,
  refreshToken,
} from "../../../Helper/helper";
//import swal from "sweetalert";
import Swal from "sweetalert2";
import PaginationBox from "../../../Components/HistoryComponents/PaginationBox/PaginationBoxOpExchange";
typeof localStorage.getItem("exchangeP") !== "string" &&
  localStorage.setItem("exchangeP", "open_order_exchange");

const OpenOrder = ({ dmode, _isLogin, trade, tradeHistory, openOrder,setOpenOrder, marketPair,  setMarkerPair, openOrderTotal,setOpenOrderTotal,loadOpenOrder,setLoadOpenOrder,ooData, setOoData }) => {
  const [ooDataProxy, setOoDataProxy] = useState(true);
  const [mohProxy, setMohProxy] = useState(true);

  const [openOrderSection, setOpenOrderSection] = useState(true);
  //const [ooData, setOoData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [cancelNo, setCancelNo] = useState(0);
  const [cancelNoAfter, setCancelNoAfter] = useState(0);
  const [moh, setMoh] = useState([]);
  const [searchData, setSearchData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);

  const [mohSearchResult, setMohSearchResult] = useState(true);
  const [ooSearchResult, setOoSearchResult] = useState(true);
  const isLogin = _isLogin;
  const callooapi=async (trade,currentPage,searchQuery)=>{
    return await authRequest(
      {
        currency: trade.currency_symbol,
        market: trade.market_symbol,
        // limit: "1",
       // start: currentPage > 1 ? currentPage * 1 - 1 : 0,
       start: currentPage > 1 ? currentPage * 10 - 10 : 0,
        limit: "10",
        filter:searchQuery
  
      },
      "inorder"
    );
  }
  const ooSearchHandler = async (e) => {
    const _search = e.target.value.toUpperCase();
    setSearchQuery(_search);
    var result = [];
    
       const res = await callooapi(trade,0,_search)
      // console.log("res in order here =>", res);
      if (res?.status === 200) {
        if(res.data.length>0){

          result=res.data;
        }
      }
      setSearchData(result);
      if (result.length > 0) {
        setOoSearchResult(true)
        setTotalRecord(res.totalRecords)
        refreshToken(res.refresh_token);
        setOoData(res.data);
        setOpenOrder()
          setOoDataProxy(!ooDataProxy)
          setLoadOpenOrder(res.data);
      } else {
        setOoSearchResult(false)
      }

    
  };

  const mohSearchHandler = async (e) => {
    const _search = e.target.value.toUpperCase();
    setSearchQuery(_search);
    var result = [];
    // moh?.map((value) => {
    //   value.reference_no.search(_search) !== -1 && result.push(value);
    // });
    const resmoh = await authRequest(
      {
        currency: trade.currency_symbol,
        market: trade.market_symbol,
        limit: "10",
        start: "0",
        filter:_search
      },
      "order-history"
    );
    if (resmoh?.status === 200) {

      result=resmoh.data
    }
    setSearchData(result);
    if (result.length > 0) {
      refreshToken(resmoh.refresh_token);
      setMoh(resmoh.data);
      setMohProxy(!mohProxy)
      setMohSearchResult(true)
      
    } else {
      setMohSearchResult(false)
    }
  };

  const cancelOrder = async (_id) => {
    setCancelNo(_id);
   // console.log("id here --> ", _id)
    const res = await authRequest({ orderid: _id }, "cancel-order");
    setCancelNoAfter(_id);
    res.refresh_token && refreshToken(res.refresh_token);
    let timerInterval;
    res &&
      Swal.fire({
        html: res.message,
        timer: 2000,
        timerProgressBar: true,
        position: "top-right",
        padding: "1rem",
        showConfirmButton: false,
        scrollbarPadding: false,
        allowOutsideClick: false,
        backdrop: false,
        customClass: {
          timerProgressBar: "tgb",
          popup: "popup",
          htmlContainer: "htmlContainer",
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      });
  };

  useEffect(() => {
    //console.log("marketPair=>",marketPair);
    isLogin &&
    marketPair &&
      (async () => {
        
        const res = await callooapi(marketPair,currentPage,searchQuery)
       // console.log("res in order here =>", res);
        if (res?.status === 200) {
          setTotalRecord(res.totalRecords)
          refreshToken(res.refresh_token);
          setOoData(res.data);
          setOoDataProxy(!ooDataProxy)
          setLoadOpenOrder(res.data);
        }else{
          setTotalRecord(0)
          refreshToken(res.refresh_token);
          setOoData([]);
          setOoDataProxy(!ooDataProxy)
          setLoadOpenOrder([]);
        }
        setOpenOrder()

 
        const resmoh = await authRequest(
          {
            currency: marketPair.currency_symbol,
            market: marketPair.market_symbol,
            limit: "10",
            start: "0",
            filter:searchQuery
          },
          "order-history"
        );
        if (resmoh) {

          refreshToken(resmoh.refresh_token);
          setMoh(resmoh.data);
          setMohProxy(!mohProxy)
        }
      })();
  }, [isLogin, marketPair, currentPage, cancelNoAfter]);
  useEffect(() => {
    //console.log("openOrderTotal=>",openOrderTotal);
    isLogin &&
    marketPair &&
      (async () => {
        if(openOrderTotal>0){

          // setTotalRecord(totalRecord+openOrderTotal)
          // setOpenOrderTotal(0)
        }
      })();
  }, [isLogin, marketPair, openOrderTotal, currentPage, cancelNoAfter]);

  


  useEffect(() => {
    (async () => {
      if (cancelNo) {
        //console.log("cancelNo=>",cancelNo);
        //if (ooData && ooData.length >= 2) {
        if (ooData && ooData.length >= 1) {
          //console.log("cancelNo1=>",cancelNo);
          setOoData(ooData.filter((obj) => parseInt(obj.tradeID) !== parseInt(cancelNo)) );
        } else {
          //console.log("cancelNo2=>",cancelNo);
          const res = await callooapi(trade,0,searchQuery)
          // console.log("res in order here =>", res);
          if (res?.status === 200) {
            //setOoData([]);
            setTotalRecord(res.totalRecords)
            refreshToken(res.refresh_token);
            setOoData(res.data);
            setOoDataProxy(!ooDataProxy)
            setLoadOpenOrder(res.data);
            setCurrentPage(currentPage>1?currentPage-1:0);
            
          }else{
            setOoData([]);
            setLoadOpenOrder([]);
          }
          setOpenOrder()
        }
      }

    })();
  }, [cancelNo, ooDataProxy]);

  useEffect(() => {
    setSearchData([]);
    setSearchQuery("");
    //console.log("value of meoj ", mohSearchResult)
    //console.log("value of open order ", openOrderSection)
    if (openOrderSection === true) {
      setMohSearchResult(true)
    }
  }, [openOrderSection]);

  useEffect(() => {
    if (tradeHistory && moh && trade) {
      if (tradeHistory?.currency_symbol.toUpperCase() === trade?.currency_symbol.toUpperCase() && tradeHistory?.market_symbol.toUpperCase() === trade?.market_symbol.toUpperCase()) {
        setMoh([tradeHistory, ...moh]);
      }
    }
  }, [tradeHistory, trade, mohProxy]);
  // }, [tradeHistory, trade, moh]);

  useEffect(() => {
    
    if (openOrder && marketPair) {
      
      //console.log();
      //openOrderDataHandler = (_old , current , set)
      if (openOrder?.currency_symbol.toUpperCase() === marketPair?.currency_symbol.toUpperCase() && openOrder?.market_symbol.toUpperCase() === marketPair?.market_symbol.toUpperCase()) {
        openOrderDataHandler(ooData, openOrder, setOoData,totalRecord,setTotalRecord);
      }
    }
  }, [ooDataProxy,  openOrder, marketPair]);  
  /* useEffect(() => {
    if (openOrder && trade) {
      //openOrderDataHandler = (_old , current , set)
      if (openOrder?.currency_symbol.toUpperCase() === trade?.currency_symbol.toUpperCase() && openOrder?.market_symbol.toUpperCase() === trade?.market_symbol.toUpperCase()) {
        openOrderDataHandler(ooData, openOrder, setOoData,totalRecord,setTotalRecord);
      }
    }
  }, [ooDataProxy, openOrder, trade]); */
  // }, [openOrder, trade, ooData]);

  return (
    <section  className={`${styles.oo_l} latesthandle`}>
      <div className="flex-between">
        <div className="flex tabbtnbg">
          <button
            onClick={() => setOpenOrderSection(true)}
            className={openOrderSection ? 'active' : ""}
          >
            Open Orders
          </button>
          <button
            onClick={() => setOpenOrderSection(false)}
            className={openOrderSection ? "" : 'active'}
          >
            My Order History
          </button>
        </div>
        <input
          type="search"
          onChange={openOrderSection ? ooSearchHandler : mohSearchHandler}
          value={searchQuery}
          placeholder="Search By Transaction Type, Price Or Amount"
          name="searchOrder"
        />
      </div>
      {
        openOrderSection ?
          <>
            {isLogin && ooData?.length > 0 && ooSearchResult ? (
              <>
                <div className='custableresponsive table-responsive custableresponsive_height mt-15 '>
                  <table className={styles.c_table}>
                    <thead className='custhead'>
                      <tr>
                        <th><div>Reference No</div></th>
                        <th><div>Price</div></th>
                        <th className='widthincrease1'><div>Amount/Pending</div></th>
                        <th><div>Total</div></th>
                        <th><div>Action</div></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        ooData?.map((order, i) => (
                        
                          <tr
                            /* className={order.type === "Sell" ? `textdanger  ${styles.tr}` : `textsuccess ${styles.tr}` } */
                           /*  className={order.type === "Sell" ? `textdanger` : `textsuccess` `${styles.tr}` } */
                            className={order.type === "Sell" ? `textdanger ${styles.tr}` : `textsuccess ${styles.tr}` }
                            /* style={{
                              color: order.type === "Sell" ? "crimson" : "green",
                            }} */
                            key={i}
                          >
                            <td>{order.reference_no}</td>
                            <td>{fixedNumber(order.price, 12)}</td>
                            <td className='widthincrease1'>
                              {fixedNumber(order.qty, 12)}/
                              {fixedNumber(order.remaining, 12)}
                            </td>
                            <td>{fixedNumber(order.total_amount, 12)}</td>
                            <td>
                              <button onClick={() => cancelOrder(order.tradeID)} className='btntable'>
                                Cancel
                              </button>
                            </td>
                          </tr>
                        ))}
                      
                    </tbody>
                  </table>
                  {
                    <div className={`${styles.pages} flex-between`}>
                      <div></div>
                      <div className={styles.pagesArea}>
                        {ooData.length && (
                          <PaginationBox
                            totalRecords={totalRecord}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                          />
                        )}
                      </div>
                    </div>
                  }
                </div>
              </>
            )
              :
              <>
                <div className={`${styles.nodata} flex-center column`}>
                  <img src={nodata} alt="nodata" />
                  <h1>No data</h1>
                </div>
              </>
            }
          </>
          :
          <>
            {isLogin && moh?.length > 0 && mohSearchResult ?

              <div className='custableresponsive table-responsive custableresponsive_height mt-15'>
                <table className={styles.c_table}>
                  <thead className='custhead'>
                    <tr>
                      <th><div>Reference No</div></th>
                      <th><div>Price</div></th>
                      <th className='widthincrease1'><div>Amount/Pending</div></th>
                      <th><div>Total</div></th>
                      <th><div>Status</div></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      moh?.map((order, i) => (
                        <tr
                        /* className={styles.tr} */
                          className={order.type === "Sell" ? `textdanger ${styles.tr} ${order.type}` : `textsuccess ${styles.tr} ${order.type}` }
                         /*  style={{
                            color: order.type === "Sell" ? "crimson" : "green",
                          }} */
                          key={i}
                        >
                          <td>{order.reference_no}</td>
                          <td>{fixedNumber(order.price, 12)}</td>
                          <td className='widthincrease1'>
                            {fixedNumber(order.qty, 12)}/
                            {fixedNumber(order.remaining, 12)}
                          </td>
                          <td>{fixedNumber(order.total_amount, 12)}</td>
                          <td>{order.status}</td>
                        </tr>
                      ))}
                   
                  </tbody>
                </table>
              </div>

              :
              <>
                <div className={`${styles.nodata} flex-center column moh`}>
                  <img src={nodata} alt="nodata" />
                  <h1>No data</h1>
                </div>
              </>
            }
          </>
      }
      {/* {!isLogin && (
        <div className={`${styles.nodata} flex-center column`}>
          <img src={nodata} alt="nodata" />
          <h1>No data</h1>
        </div>
      )} */}
      {/* {isLogin && ooData?.length == 0 && openOrderSection && (
        <div className={`${styles.nodata} flex-center column`}>
          <img src={nodata} alt="nodata" />
          <h1>No data</h1>
        </div>
      )} */}
      {/* {isLogin && moh?.length === 0 && !openOrderSection || !mohSearchResult && (
        <div className={`${styles.nodata} flex-center column`}>
          <img src={nodata} alt="nodata" />
          <h1>No data</h1>
        </div>
      )} */}
    </section>
  );
};

export default OpenOrder;
