import React, { useContext , useState , useEffect } from "react";
import Context from "../../../Context/Context";
import styles from './PaginationBox.module.scss';
import {AiOutlineRight , AiOutlineLeft} from 'react-icons/ai';



// const Pages = JSON.parse(localStorage.getItem("Pages")) ? JSON.parse(localStorage.getItem("Pages")) : null;
// Pages === null  && localStorage.setItem("Pages" , JSON.stringify({deposit_history: 1, trade_history: 1, withdraw_history: 1, open_order_history: 1}))


const PaginationBox = ({_totalPage , activePage , setActivePage}) => {
    const { states } = useContext(Context); 

  const dmode = states.dmode;
  const totalPage = Math.ceil(_totalPage/10);

  const pageHandler = ( value) => {
    
    setActivePage(parseInt(value));
  }

  useEffect(() => {
    totalPage === 1 && pageHandler( 1)
    localStorage.setItem("RankPages", activePage)
  }, [activePage , totalPage])

  return (
    <div className={dmode ? styles.page_d : styles.page_l}>
    <button  onClick={()=>pageHandler( 1)} className={activePage === 1 ? "" : styles.active}>First</button>
    <button onClick={()=>activePage !== 1 ? pageHandler(activePage-1) : pageHandler(1)} className={`${activePage === 1 ? "" : styles.active} flex-center`}><AiOutlineLeft /></button>
    <button>Page {activePage} of {totalPage} <br/>Total Records: {_totalPage}</button>
    <button onClick={()=>activePage < totalPage ? pageHandler(activePage+1) : pageHandler(totalPage)} className={`${activePage === totalPage ? "" : styles.active} flex-center`}><AiOutlineRight /></button>
    <button onClick={()=>pageHandler(totalPage)} className={activePage === totalPage ? "" : styles.active}>Last</button>
    </div>
  )
}

export default PaginationBox