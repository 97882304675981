import React, { useContext, useState, useEffect } from "react";

import insi from '../../Assets/Wallet.png';
import cloud from '../../Assets/Cloud.png';
import supp from '../../Assets/about-icon-2.png';


const Features = () => {
  
  return (
    <section  className='featureSec padSec text-center  scrollFade'>
      <div className="container">
        <h2 className="text-center fade1 titleh2">Your Asset is Secured with BTRL</h2>
        <div className='row mt-5'>
          <div className="col-sm-4">
            <div className="snfeatue boxanimation1">
              <div className="featureImg">
                <img src={insi} className="img-fluid" />
              </div>
              <h4>Various Token Types Supported</h4>
              <p>These tokens are used to grant access to specific resources or services within a system. Access tokens are usually generated after successful authentication and are associated with a set of permissions that dictate what the user can and cannot access.</p>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="snfeatue boxanimation2">
              <div className="featureImg">
                <img src={cloud} className="img-fluid" />
              </div>
              <h4>User Experience</h4>
              <p>The design of the product should be focused on the needs, goals, and behaviors of the user. This means considering factors such as usability, accessibility, and ease of use when designing the product.</p>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="snfeatue boxanimation3">
              <div className="featureImg">
                <img src={supp} className="img-fluid" />
              </div>
              <h4>Secure And Stable</h4>
              <p>Use HTTPS instead of HTTP to ensure that all data transmitted between your website and your users is encrypted and secure.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
