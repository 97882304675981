import React, { useContext } from "react";
import { NavLink as Link, useNavigate } from "react-router-dom";
import Context from "../../../Context/Context";
import styles from "./Footer.module.scss";
import logo from "../../../Assets/logo.png";
import {
  BsWhatsapp,
  BsInstagram,
  BsTwitter,
  BsYoutube,
  BsLinkedin,
  BsMedium,
} from "react-icons/bs";

import { FaTelegramPlane, FaFacebookF, FaRedditAlien } from "react-icons/fa";

const Footer = () => {
  const { states } = useContext(Context);

  const redirectHome = () => {
    document.location.href = "www.yoursite.com";
  };

  const dmode = states.dmode;
  return (
    <>
      <footer  className={dmode ? `${styles.d_footer}  ` : `${styles.l_footer}  `}>
        <div className={`${styles.footer} container `}>
          <div className="row w-100">
          <div className='col-sm-3'>
            <div className="footerLogo"><img onClick={redirectHome} src={logo} alt="logo" className="img-fluid" /></div>
            <div className="SocialLinkFooter">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/+919714826176"
              >
                <BsWhatsapp />
              </a>
              <a target="_blank" rel="noreferrer" href="/">
                <BsInstagram />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/bitcoin_regular"
              >
                <BsTwitter />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCBVuQ834SL8E7T4SVnc8hiw"
              >
                <BsYoutube />
              </a>
              
              <a
                target="_blank"
                rel="noreferrer"
                href="https://medium.com/@bitcoinregular"
              >
                <BsMedium />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://t.me/BITCOINREGULAR"
              >
                <FaTelegramPlane />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/bitcoinregular/"
              >
                <FaFacebookF />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.reddit.com/user/BitcoinRegular?utm_source=reddit-android"
              >
                <FaRedditAlien />
              </a>
            </div>
          </div>

          <div className='col-sm-2 fLink'>
            <div className={styles.f_links_grid}>
              <h3>Legal</h3>

              {/* <a href="/privacy-policy">Privacy Policy</a>
              <a href="/terms-and-conditions">Terms & Conditions</a>
              <a href="/risk-statement">Risk Statement</a>
              <a href="/refund-policy">Refund Policy</a>
              <a href="/about">About Us</a>
              <a href="/contact-us">Contact Us</a> */}
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
              <Link to="/risk-statement">Risk Statement</Link>
              <Link to="/refund-policy">Refund Policy</Link>
              <Link to="/about">About Us</Link>
              <Link to="/contact-us">Contact Us</Link>
            </div>
          </div>
            <div className='col-sm-2 fLink'>
              <h3>Exchange</h3>
              <Link to="/coininfo">Coin Info</Link>
              <Link to="/fees">Fees Us</Link>
              {/* <a href="/coininfo">Coin Info</a>
              <a href="/fees">Fees</a> */}
              {/* <a href="/">Public API</a> */}
              <a target="_blank"  rel="noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSdO6iiJTHeBs7JJ4jJQIx8rUtdPusaysDhs8dGmldo1I3ZCzw/viewform?vc=0&c=0&w=1">Listing Request</a>
              {/* <a href="/">About Us</a> */}
            </div>

            <div className='col-sm-2 fLink'>
              <h3>Legal Documents</h3>

              <a target="_blank" rel="noreferrer" href="https://btrlexchange.com/backend/assets/certificate-of-incorporation.pdf">Certificate of Incorporation</a>
              <a target="_blank" rel="noreferrer" href="https://btrlexchange.com/backend/assets/memorandum-of-association.pdf">Memorandum of Association</a>
              <a target="_blank" rel="noreferrer" href="https://btrlexchange.com/backend/assets/pancard.pdf">PAN Card</a>
            </div>

            <div className='col-sm-2 fLink'>
              <h3>Other Links</h3>
            
              <a target="_blank" rel="noreferrer" href="https://bitcoinregular.com/">BTRL Token Website</a>
<a target="_blank" rel="noreferrer" href="/news">News</a>
            </div>
          </div>
          </div>
      </footer>
      <div className={dmode ? styles.d_copyright : styles.l_copyright}>
        © 2019 - {new Date().getFullYear()} BTRL Exchange. All Rights Reserved.
      </div>
    </>
  );
};

export default Footer;
