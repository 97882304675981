import React, { useState, useEffect, useMemo } from "react";
import { authRequest, fixedNumber, refreshToken, shorter } from "../../../Helper/helper";
import styles from "./History.module.scss";
import nodata from "../../../Assets/nodata.png";
import PaginationBox from "../../../Components/HistoryComponents/PaginationBox/PaginationBox";
import Table from 'react-bootstrap/Table';
const Table1 = ({
  headers,
  ooData,
  tradeHistory,
  data,
  _activeValue,
  _filterData,
  allPagesData,
  setAllPagesData,
  statusFilterValue,
  dmode,
  cancelOrder,
  myfilter
}) => {
  const [allData, setAllData] = useState();
  const activePage = allPagesData[_activeValue];
  const [totalRecord, setTotalRecord] = useState(data?.totalRecords);
  const [totalBalance, setTotalBalance] = useState(data?.totalBalance);
// console.log("statusFilterValue=>",statusFilterValue)
// console.log("myfilter=>",myfilter)
  useEffect(() => {
    setTotalBalance(data?.totalBalance)
    //console.log("totalBalance12=>"  );
  })
  useEffect(() => {
    // if(myfilter.open_order_history.currency!=="" && myfilter.open_order_history.currency!=="" ){
    (async () => {
      if (_activeValue === "deposit_history") {
        const dhres = await authRequest(
          {
            currency:
            myfilter.deposit_history.currency!==""
                ?  myfilter.deposit_history?.currency
                : "",
            start: activePage > 1 ? activePage * 10 - 10 : "",
            limit: "10",
          },
          "deposit-history"
        );
        /* const dhres = await authRequest(
          {
            currency:
              _filterData.deposit_history?.length > 0
                ? _filterData.deposit_history
                : "",
            start: activePage > 1 ? activePage * 10 - 10 : "",
            limit: "10",
          },
          "deposit-history"
        ); */
        refreshToken(dhres.refresh_token);
        setTotalRecord(dhres.totalRecords)
        setTotalBalance(dhres.totalBalance)
        setAllData(dhres.data);
      } else if (_activeValue === "trade_history") {
        const etv = _filterData.trade_history.split(",");
        const thres = await authRequest(
          {
            // currency: myfilter.trade_history?.currency,
            // market: myfilter.trade_history?.market,
            currency: myfilter.trade_history.currency!==undefined? myfilter.trade_history.currency:"",
            market: myfilter.trade_history.market!==undefined?myfilter.trade_history.market:"",
            start: activePage > 1 ? activePage * 10 - 10 : "",
            limit: "10",
              filter:myfilter.trade_history.filter
           // filter:statusFilterValue
          },
          "order-history"
        );
       /*  const etv = _filterData.trade_history.split(",");
        const thres = await authRequest(
          {
            currency: etv[0],
            market: etv[1] ? etv[1] : "",
            start: activePage > 1 ? activePage * 10 - 10 : "",
            limit: "10",
            filter:statusFilterValue
          },
          "order-history"
        ); */
        refreshToken(thres.refresh_token);
        setTotalRecord(thres.totalRecords)
        setAllData(thres.data);
      } else if (_activeValue === "withdraw_history") {
        const whres = await authRequest(
          {
            currency: myfilter.withdraw_history?.currency,
            start: activePage > 1 ? activePage * 10 - 10 : "",
            limit: "10",
          },
          "withdraw-history"
        );
        /* const whres = await authRequest(
          {
            currency: _filterData.withdraw_history,
            start: activePage > 1 ? activePage * 10 - 10 : "",
            limit: "10",
          },
          "withdraw-history"
        ); */
        refreshToken(whres.refresh_token);
        setTotalRecord(whres.totalRecords)
        setTotalBalance(whres.totalBalance)
        setAllData(whres.data);
      } else if (_activeValue === "open_order_history") {
        const etv = _filterData.open_order_history.split(",");
        const oohres = await authRequest(
          {
            currency: myfilter.open_order_history.currency!==undefined? myfilter.open_order_history.currency:"",
            market: myfilter.open_order_history.market!==undefined?myfilter.open_order_history.market:"",
            start: activePage > 1 ? activePage * 10 - 10 : "",
            limit: "10",
             // filter:myfilter.trade_history.filter
          },
          "inorder"
        );
       /*  const oohres = await authRequest(
          {
            currency: etv[0],
            market: etv[1] ? etv[1] : "",
            start: activePage > 1 ? activePage * 10 - 10 : "",
            limit: "10",
          },
          "inorder"
        ); */
        refreshToken(oohres.refresh_token);
        setTotalRecord(oohres.totalRecords)
        setAllData(oohres.data);
      }

    })();
  }, [activePage, _activeValue]);

  useMemo(() => {
    if (data && _filterData) {
      if (Object.values(_filterData).length > 0) {
        setTotalRecord(data.totalRecords)
        setAllData(data.data)
      }
    }
  }, [_filterData, data])

  useMemo(() => {
    if (ooData) {
      if (_activeValue === "open_order_history" && allData) {
        const _allData = allData;
        for (const coin of ooData) {
          const index = _allData.findIndex(obj => obj.reference_no === coin.reference_no)
          if (index === -1) {
            _allData.unshift(coin)
            setTotalRecord(totalRecord + 1)
          } else {
            _allData[index] = coin;
          }
        }
        setAllData(_allData)
      }
    }
    setTotalRecord(totalRecord)
  }, [ooData])

  const isNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  useMemo(() => {
    if (tradeHistory) {
      // if(_activeValue === "open_order_history"){
      //   const cancelNo = tradeHistory.tradeID
      //   setTotalRecord(totalRecord - 1)
      //   if(allData && allData.length >=2){
      //     setAllData(allData.filter(obj => parseInt(obj.tradeID) !== parseInt(cancelNo)))
      //   }else{
      //     setAllData([])
      //   }
      // }else
      if (_activeValue === "trade_history" && allData) {
        // const ls = JSON.parse(localStorage.getItem("Pages"))
        // localStorage.setItem("Pages" , JSON.stringify({...ls, "trade_history":1}))
        // console.log(ls) inner
        const _allData = allData;
        _allData.unshift(tradeHistory)
        _allData.pop()
        setAllData(_allData)
      }
    }
  }, [tradeHistory, _activeValue])
  //console.log("totalBalance=>",totalBalance);
  return allData?.length > 0 ? (
    <>
      <div className=" ">
        <div className={"historyTable mt-15" }>
        
      {/* <div className="tableDesign latesthandle ">
        <div className={_activeValue === "deposit_history"?" custableresponsive table-responsive custableresponsive_height mt-15 desktopScroolTable":" custableresponsive table-responsive custableresponsive_height mt-15 desktopScroolTable" }>
         */}
          {/* <table > */}
          <Table responsive>
            <thead >
              
              <tr>
                {headers.map((value, i) => (
                  <th key={i===""?-"1":i}><div>{value[0]}</div></th>
                ))}
              </tr>
            </thead>
            <tbody >
              {allData?.map((coin, bi) => (
                <tr className={styles.tr} key={bi}>
                  {headers?.map((h, i) =>
                    coin[h[1]] !== null && coin[h[1]].length > 25 ? (
                      <>
                        <td key={i} >
                        {/*   {h[1]} */}
                            {(coin[h[1]])} 
                           
                            {/* {totalBalance} */} 
                        
                        </td>
                        {/* <td key={i}>{shorter(coin[h[1]])}</td> */}
                       
                      </>
                    ) : (
                      <>
                        <td  key={i}>
                       
                        {/* {
                        h[1]==="qty"? */}
                          {coin[h[1]] === null ? 
                          "" : 
                          h[1] === "action"?
                           <>
                              <button className="btntable" onClick={() => cancelOrder(coin["tradeID"])}>
                                Cancel
                              </button>
                           </>
                          :
                          
                            h[1] === "currency_symbol" || h[1] === "market_symbol" ? coin[h[1]].toUpperCase() : isNumber(coin[h[1]]) && h[1] !== "utr_no" ? h[1] === 'qty' ? `${fixedNumber(coin[h[1]], 12)}/ ${fixedNumber((parseFloat(coin[h[1]]) - parseFloat(coin.remaining)).toString(), 12)}` : fixedNumber(coin[h[1]], 12) : coin[h[1]]}
                          
                        {/* :
                          coin[h[1]] === null ? "" : h[1] === "currency_symbol" || h[1] === "market_symbol" ? coin[h[1]].toUpperCase() : isNumber(coin[h[1]]) && h[1] !== "utr_no" ? h[0] === 'Quantity/Executed' ? `${fixedNumber(coin[h[1]], 12)}/\ ${fixedNumber((parseFloat(coin[h[1]]) - parseFloat(coin.remaining)).toString(), 12)}` : fixedNumber(coin[h[1]], 12) : coin[h[1]]
                        } */}
                          {/*  {coin[h[1]]} */}
                         
                          {/*  
                                <br/>
                                {h[1]}
                                <br/> 
                      
                          */}
                         
                        </td>

                        {/* onClick={() => cancelOrder(coin[tradeID])} */}
                      </>

                    )
                   /* ( <>
                    <td>
                    <button >
                      Cancel
                    </button>
                  </td></>) */
                  )}
                </tr>
                
              ))}
             
            </tbody>
          {/* </table> */}
          </Table>
        </div>
          {
                
                (totalBalance)?
                <>
                  <div key={_activeValue === "withdraw_history"?"Withdraw-total-tr":"Deposit-total-tr"} style={{float:"right",marginTop:"10px"}}>
                    <div key={_activeValue === "withdraw_history"?"Withdraw-total-td":"Deposit-total-td"}>

                      <h6 className={dmode?"darkModeText":"lightModeText"}>Total {_activeValue === "withdraw_history"?"Withdraw":"Deposit"}  {totalBalance }</h6>
                    </div>
                  </div>
                </>
                :
                <></>
              }
      </div>
      {/* <div className="historyTable ">
      <Table responsive style={{color:"#fff"}}>
        <thead>
          <tr>
            <th>#</th>
            <th>Table heading</th>
            <th>Table heading</th>
            <th>Table heading</th>
            <th>Table heading</th>
            <th>Table heading</th>
            <th>Table heading</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>TablecellTablecellTablecellTablecellTablecellTablecellTablecellTablecellTablecellTablecellTablecellTablecellTablecellTablecellTablecellTablecellTablecellTablecell</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
          </tr>
        </tbody>
      </Table>
      </div> */}
      <div className={`${styles.pages} flex-between`}>
        <div></div>
        <div className={styles.pagesArea}>
          {allData && (
            <PaginationBox
              loc={_activeValue}
              allPagesData={allPagesData}
              setAllPagesData={setAllPagesData}
              _totalPage={totalRecord}
            />
          )}
        </div>
      </div>
    </>
  ) : allData?.length === 0 ? (
    <div className={`${styles.nodata} flex-center column`}>
      <img src={nodata} alt="nodata" />
      <h1>No data found !</h1>
    </div>
  ) : (
    <div style={{ height: "50vh" }}></div>
  );
};

export default Table1;
