import {apiUrl} from '../../../Helper/helper';

// Make requests to CryptoCompare API
export async function makeApiRequest(path) {
	try {
		const response = await fetch(`https://min-api.cryptocompare.com/${path}`);
		//`https://api.binance.com/api/v3/ticker/24hr?symbol=${tradingSymbol.tradingSymbol}`
		//const response = await fetch(`https://api.binance.com/${path}`);
		return response.json();
	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
}
export async function makeApiRequest1(path , formData=null) {

	try {
		const formBody={
			method: "POST",
			headers: {
			  "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
			},
			}
			if(formData!==null){
				formBody.body=formData
			} 
		//const response = await fetch(`https://demo.btrlexchange.com/backend/index.php/api/${path}`,formBody);
		const response = await fetch(`${apiUrl}${path}`,formBody);
		return response.json();
	} catch (error) {
		throw new Error(`btrlexchange ${path} ${formData} request error: ${error} `);
	}
}
/* export async function makeApiRequest1(path,type) {
	try {
		//const response = await fetch(`https://min-api.cryptocompare.com/${path}`);
		
		
		const response = await fetch(`https://api.binance.com/api/v3/uiKlines${path}`);
		return response.json();
	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
} */

// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
	const short = `${fromSymbol}/${toSymbol}`;
	return {
		short,
		full: `${exchange}:${short}`,
	};
}

export function parseFullSymbol(fullSymbol) {
	const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
	if (!match) {
		return null;
	}

	return {
		exchange: match[1],
		fromSymbol: match[2],
		toSymbol: match[3],
	};
}
