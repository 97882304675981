import React, { useEffect, useRef,useState } from 'react';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';



SwiperCore.use([Autoplay, Pagination, Navigation]);


const Adds = () => {
    
    return (
    <>
        {/*<section className="Adds padBottom scrollFade ">
            <div className="container">
            <div className="banners boxanimation2 text-center">
                 <Swiper
                  loop={true}
                  speed={5000}

                  modules={[Autoplay]}
                  className='' >
                    <SwiperSlide className="flex-center">
                        <img src="latestimages/home/banner1.png" className="img-fluid" />
                    </SwiperSlide>
                    <SwiperSlide className="flex-center">
                        <img src="latestimages/home/banner1.png" className="img-fluid" />
                    </SwiperSlide>
                    <SwiperSlide className="flex-center">
                        <img src="latestimages/home/banner1.png" className="img-fluid" />
                    </SwiperSlide>
                </Swiper>
            </div>
            </div>
        </section>*/}

    <section  className='getstarted featureSec padSec text-center  scrollFade'>
      <div className="container">
        <h2 className="text-center fade1 titleh2">Get Started in a Few Minutes</h2>
        <p>Btrl Exchange supports a variety of the most popular digital currencies.</p>
        <div className='row mt-5 justify-content-center'>
          <div className="col-sm-6 col-md-6 col-lg-4">
            <div className="snfeatue boxanimation1 mb-4">
              <div className="getstartedImg">
                <img src='/latestimages/home/icon1.png' className="img-fluid" />
              </div>
              <h4>Create Account</h4>
              <p>These tokens are used to grant access to specific resources or services within a system. Access tokens are usually generated after successful authentication and are associated with a set of permissions that dictate what the user can and cannot access.</p>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4">
            <div className="snfeatue boxanimation2 mb-4">
              <div className="getstartedImg">

                <img src='/latestimages/home/icon2.png' className="img-fluid" />
              </div>
              <h4>Link Your Bank Account</h4>
              <p>The design of the product should be focused on the needs, goals, and behaviors of the user. This means considering factors such as usability, accessibility, and ease of use when designing the product.</p>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4">
            <div className="snfeatue boxanimation3 mb-4">
              <div className="getstartedImg">
                <img src='/latestimages/home/icon3.png' className="img-fluid" />
              </div>
              <h4>Start Buying & Selling</h4>
              <p>Use HTTPS instead of HTTP to ensure that all data transmitted between your website and your users is encrypted and secure.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    </>
   );
};

export default Adds;