import React, { useContext, useEffect, useState, useRef } from "react";
//import Context from "../../../Context/Context";
//import { refreshToken, sendGetRequest } from "../../../Helper/helper";
import Context from "../../Context/Context";
import { refreshToken, sendGetRequest } from "../../Helper/helper";

const Newlistingtokens = () => {
    const [data, setData] = useState();
  const { states } = useContext(Context);

  const dmode = states.dmode;

  useEffect(() => {
    (async () => {
      const res = await sendGetRequest("global");
      res.refresh_token && refreshToken(res.refresh_token);
      setData(res.data.listingAnnoucement);
    })();
  }, []);
    return (
    <>
        <section className="newListing padtop0Sec   scrollFade">
            <div className="container">
                
                <h2 className='fade1 titleh2 text-center'>New Listing Announcement</h2>
                <div className="row listingMulti">
                {data?.map((value, i) => (
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="snBoxlisting gap-2 boxanimation1 d-flex justify-content-between">
                            <div className="leftCoin">
                                <img src={value.image} className="img-fluid snBoxImage" />
                            </div>
                            <div className="rightContent gap-1 d-flex rigthbigcon">
                                <div className="leftCon justify-content-between">
                                    <h5>{value.currency_symbol}</h5>
                                    <p>{value.name}</p>
                                </div>
                                <div className="rightCon">
                                    <h6>{parseFloat(value.price).toFixed(4)}</h6>
                                    <p className={((parseFloat(value.percent_change).toFixed(2)===0 || (parseFloat(value.percent_change).toFixed(2)).toString()==="0.00" || (parseFloat(value.percent_change).toFixed(2)).toString()==="-0.00")?" percentage":(parseFloat(value.percent_change).toFixed(2)>0?"greentext percentage":"redtext percentage"))}  data={parseFloat(value.percent_change).toFixed(2)}>{parseFloat(value.percent_change).toFixed(2)}%</p>
                                    {/* <p className="greentext percentage">{parseFloat(value.percent_change).toFixed(4)}%</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                 ))}
                   {/*  <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="snBoxlisting gap-2 boxanimation1 d-flex justify-content-between">
                            <div className="leftCoin">
                                <img src="latestimages/home/btrltoken.png" className="img-fluid snBoxImage" />
                            </div>
                            <div className="rightContent gap-1 d-flex rigthbigcon">
                                <div className="leftCon justify-content-between">
                                    <h5>Btrl Regular</h5>
                                    <p>Btrl COin</p>
                                </div>
                                <div className="rightCon">
                                    <h6>$326.15</h6>
                                    <p className="greentext percentage">+5.00%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="snBoxlisting gap-2 boxanimation2 d-flex justify-content-between">
                            <div className="leftCoin">
                                <img src="latestimages/home/btrltoken.png" className="img-fluid snBoxImage" />
                            </div>
                            <div className="rightContent gap-1 d-flex rigthbigcon">
                                <div className="leftCon justify-content-between">
                                    <h5>Btrl Regular</h5>
                                    <p>Btrl COin</p>
                                </div>
                                <div className="rightCon">
                                    <h6>$326.15</h6>
                                    <p className="greentext percentage">+5.00%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="snBoxlisting gap-2 boxanimation3 d-flex justify-content-between">
                            <div className="leftCoin">
                                <img src="latestimages/home/btrltoken.png" className="img-fluid snBoxImage" />
                            </div>
                            <div className="rightContent gap-1 d-flex rigthbigcon">
                                <div className="leftCon justify-content-between">
                                    <h5>Btrl Regular</h5>
                                    <p>Btrl COin</p>
                                </div>
                                <div className="rightCon">
                                    <h6>$326.15</h6>
                                    <p className="greentext percentage">+5.00%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="snBoxlisting gap-2 boxanimation4 d-flex justify-content-between">
                            <div className="leftCoin">
                                <img src="latestimages/home/btrltoken.png" className="img-fluid snBoxImage" />
                            </div>
                            <div className="rightContent gap-1 d-flex rigthbigcon">
                                <div className="leftCon justify-content-between">
                                    <h5>Btrl Regular</h5>
                                    <p>Btrl COin</p>
                                </div>
                                <div className="rightCon">
                                    <h6>$326.15</h6>
                                    <p className="greentext percentage">+5.00%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="snBoxlisting gap-2 boxanimation5 d-flex justify-content-between">
                            <div className="leftCoin">
                                <img src="latestimages/home/btrltoken.png" className="img-fluid snBoxImage" />
                            </div>
                            <div className="rightContent gap-1 d-flex rigthbigcon">
                                <div className="leftCon justify-content-between">
                                    <h5>Btrl Regular</h5>
                                    <p>Btrl COin</p>
                                </div>
                                <div className="rightCon">
                                    <h6>$326.15</h6>
                                    <p className="greentext percentage">+5.00%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="snBoxlisting gap-2 boxanimation6 d-flex justify-content-between">
                            <div className="leftCoin">
                                <img src="latestimages/home/btrltoken.png" className="img-fluid snBoxImage" />
                            </div>
                            <div className="rightContent gap-1 d-flex rigthbigcon">
                                <div className="leftCon justify-content-between">
                                    <h5>Btrl Regular</h5>
                                    <p>Btrl COin</p>
                                </div>
                                <div className="rightCon">
                                    <h6>$326.15</h6>
                                    <p className="greentext percentage">+5.00%</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    </>
   );
};

export default Newlistingtokens;