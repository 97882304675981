import React, { useContext, useEffect, useState } from "react";
import Footer from "../../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../../Components/HomeComponents/Navbar/Navbar";
import Context from "../../../Context/Context";
import styles from "./Stack.module.scss";
import nodata from "../../../Assets/nodata.png";
import Table from 'react-bootstrap/Table';
import {
  authRequest,
  authRequestNb,
  fixedNumber,
  refreshToken,
} from "../../../Helper/helper";
import swal from "sweetalert";
import swal2 from "sweetalert2";

const rData = [];

const Stack = () => {
  const [Coins, setCoins] = useState();
  const [formData, setFormData] = useState({});
  const [submitBtn, setSubmitBtn] = useState(false);
  const [stackingData, setStackingData] = useState();
  const [errorMessage, setErrorMessage] = useState({});
  const { states } = useContext(Context);

  const formDataHandler = (key, value) => {
    let change = formData;
    change[key] = value;
    setFormData({ ...change });
    errorHandler(key, "");
  };

  const errorHandler = (key, value) => {
    let change = errorMessage;
    change[key] = value;
    setErrorMessage({ ...change });
  };

  const earlyWithdraw = async (id) => {
    swal2.fire({
      title: 'Are you sure?',
      text: `Do you willing to pay panelty fee?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, withdraw it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await authRequest({ stackingid: id }, "early-withdraw");
        const _swal = await swal(res.message);
        if (res.status === 200 && _swal) {
          refreshToken(res.refresh_token);
          await autoData();
        }
        
      }
    });
  };

  const submitStacking = async () => {
    if (formData.currency && formData.amount) {
      setSubmitBtn(true);
      const res = await authRequest(
        { currency: formData.currency, amount: formData.amount },
        "submit-stacking"
      );
      setSubmitBtn(false);
      if (res.status === 200) {
        const _swal = swal(res.message);
        if (_swal) {
          document.querySelector("form").reset();
          setFormData({});
          refreshToken(res.refresh_token);
          await autoData();
        }
      } else {
        swal(res.message);
      }
    } else {
      if (!formData.currency) {
        errorHandler("currency", "Please select Currency ");
      }
      if (!formData.amount) {
        errorHandler("amount", "Please enter Amount ");
      }
    }
  };

  const dmode = states.dmode;

  const autoData = async () => {
    const resData = await authRequestNb("stacking");
    refreshToken(resData.refresh_token);
    setStackingData(resData);
  };

  useEffect(() => {
    (async () => {
      // window.scrollTo(0, 0)
      const res = await authRequestNb("stacking-currency");
      refreshToken(res.refresh_token);
      setCoins(res.data);
      await autoData();
    })();
  }, []);

  return (
    <div className={`${styles.stack_l} stakePage ligtMode`}>
      <Navbar />
      <div className="stakeMain" >
      <div className={`${styles.stack} container`}>
      <div className='row align-items-center justify-content-center'>
      <div className='col-sm-7'>
        <div className={styles.impn}>
          <h1>Important Note:</h1>
          <h2>What is staking?</h2>
          <h3>
            In our platform staking is smarter way to increase you asset you
            having to put some asset for a locking time period and after end of
            locking time you receive extra asset without any loss.
          </h3>
          <div className="row align-items-center">
            <div className="col-sm-4">
              <div className="stBox d-flex align-items-center"><div>
                <p>Staking time period</p>
                <h4>1 Year</h4>
              </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="stBox d-flex align-items-center"><div>
                <p>Staking Interest for Coin/Tokens</p>
                <h4>9.00% annually</h4>
              </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="stBox d-flex align-items-center"><div>
                <p>Before said period penalty for Coin Tokens</p>
                <h4>25.00%</h4>
              </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="stBox d-flex align-items-center"><div>
                <p>Staking Interest for INR</p>
                <h4>15.00% annually</h4>
              </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="stBox d-flex align-items-center"><div>
                <p>Before said period penalty for INR</p>
                <h4>5.00%</h4>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='col-sm-5'>
        <div className="text-center imgmobhide">
          <img src="/stakeimg.png" className="img-fluid" />
        </div>
      </div>
      </div>
    </div>
    </div>
      <div className={`${styles.stack} container`}>
        <div className='signPage '>

        <form className={`${styles.sca} pad-24 stakeMain StakeForm `}>
        <h1>Stack To Earn More</h1>
        <div className="flex">
          <div className={styles.sbox}>
            <select
              value={formData.currency}
              onChange={(e) => formDataHandler("currency", e.target.value)}
            >
              <option value={""}>Select Currency *</option>
              {Coins?.map((coin, index) => {
                return (
                  <option key={index} value={coin.currency_symbol}>
                    {coin.currency_symbol}
                  </option>
                );
              })}
            </select>
            {errorMessage.currency && (
              <p className={styles.warning_note}>{errorMessage.currency}</p>
            )}
          </div>

          <div className={styles.ebox}>
            <input
              type="number"
              onChange={(e) => formDataHandler("amount", e.target.value)}
              placeholder="Amount*"
              name="amount"
            />
            {errorMessage.amount && (
              <p className={styles.warning_note}>{errorMessage.amount}</p>
            )}
          </div>
        </div>
          <button
            type="button"
            onClick={submitStacking}
            disabled={submitBtn}
            className={`btn mobile100`}
          >
            {submitBtn ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
          </button>
        </form>
      </div>
        {/* <div>
          <p>Are you sure you want to pay panelty fee?</p>
          <button onClick={handleDelete}>Yes</button>
          <button onClick={() => setShowConfirmation(false)}>No</button>
        </div> */}
        <div className="">
 <div className='tableDesign custableresponsive table-responsive custableresponsive_height mt-15 heightAutoTable'>
        <Table responsive>
        <thead className='headStyle' >
        <tr>
              <th ><div>#</div></th>
              <th ><div>Currency</div></th>
              <th ><div>Interest%</div></th>
              <th ><div>Penalty%</div></th>
              <th ><div>Stacked Amount</div></th>
              <th ><div>Total Return</div></th>
              <th ><div>Date Of Joining</div></th>
              <th ><div>Date Of Maturity</div></th>
              <th ><div>Date Of Claim</div></th>
              <th ><div>Staking Type</div></th>
              <th ><div>Status</div></th>
              <th ><div>Action</div></th>
            </tr>
          </thead>
            {stackingData?.data && (
              <tbody className={styles.tbody}>
                {stackingData?.data?.map((order, i) => (
                  <tr className={styles.tr} key={i}>
                    <td>{i + 1}</td>
                    <td>{order.currency_symbol}</td>
                    <td>{order.interest}</td>
                    <td>{order.panelty}</td>
                    <td>{fixedNumber(order.amount, 12)}</td>
                    <td>{fixedNumber(order.totalReturn, 12)}</td>
                    <td style={{ width: "100px" }}>{order.dateofjoining}</td>
                    <td style={{ width: "100px" }}>{order.dateofmaturity}</td>
                    <td style={{ width: "100px" }}>{order.dateofclaim}</td>
                    <td style={{ width: "100px" }}>{order.lottery==="0"?"Normal Staking":"Crorepati Lucy Draw Staking"}</td>
                    <td style={{ width: "100px" }}>{order.claimstatus}</td>
                    <td >
                    {order.claimstatus === "Ongoing" ? (
                        <button onClick={() => earlyWithdraw(order.id)} style={{width: 141}}>
                          Early Withdraw
                        </button>
                      ) : (
                        "-"
                      )}
                   {/*  <button onClick={() => earlyWithdraw(order.id)} style={{width: 141}}>
                          Early Withdraw
                        </button> */}
                     {/*  {order.lottery==="0"?
                      <>
                      {order.claimstatus === "Ongoing" ? (
                        <button onClick={() => earlyWithdraw(order.id)} style={{width: 141}}>
                          Early Withdraw
                        </button>
                      ) : (
                        "-"
                      )}
                      </>
                      :<>-</>
                      } */}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
        </div>

        {stackingData?.data.length > 0 ? (
          ""
        ) : (
          <div className={`${styles.nodata} flex-center column`}>
            <img src={nodata} alt="nodata" />
            <h1>No data</h1>
          </div>
        )}
      </div>
          

        {/* 
        <div className="tableDesign">
            <div className='custableresponsive table-responsive custableresponsive_height mt-15 heightAutoTable'>
              <table className={styles.c_table}>
              <thead className='headStyle' >
              <tr>
                    <th ><div>#</div></th>
                    <th ><div>Currency</div></th>
                    <th ><div>Interest%</div></th>
                    <th ><div>Penalty%</div></th>
                    <th ><div>Stacked Amount</div></th>
                    <th ><div>Total Return</div></th>
                    <th ><div>Date Of Joining</div></th>
                    <th ><div>Date Of Maturity</div></th>
                    <th ><div>Date Of Claim</div></th>
                    <th ><div>Staking Type</div></th>
                    <th ><div>Status</div></th>
                    <th ><div>Action</div></th>
                  </tr>
                </thead>
                  {stackingData?.data && (
                    <tbody className={styles.tbody}>
                      {stackingData?.data?.map((order, i) => (
                        <tr className={styles.tr} key={i}>
                          <td>{i + 1}</td>
                          <td>{order.currency_symbol}</td>
                          <td>{order.interest}</td>
                          <td>{order.panelty}</td>
                          <td>{fixedNumber(order.amount, 12)}</td>
                          <td>{fixedNumber(order.totalReturn, 12)}</td>
                          <td style={{ width: "100px" }}>{order.dateofjoining}</td>
                          <td style={{ width: "100px" }}>{order.dateofmaturity}</td>
                          <td style={{ width: "100px" }}>{order.dateofclaim}</td>
                          <td style={{ width: "100px" }}>{order.lottery==="0"?"Normal Staking":"Crorepati Lucy Draw Staking"}</td>
                          <td style={{ width: "100px" }}>{order.claimstatus}</td>
                          <td >
                            {order.lottery==="0"?
                            <>
                            {order.claimstatus === "Ongoing" ? (
                              <button onClick={() => earlyWithdraw(order.id)} style={{width: 141}}>
                                Early Withdraw
                              </button>
                            ) : (
                              "-"
                            )}
                            </>
                            :<>-</>
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>

              {stackingData?.data.length > 0 ? (
                ""
              ) : (
                <div className={`${styles.nodata} flex-center column`}>
                  <img src={nodata} alt="nodata" />
                  <h1>No data</h1>
                </div>
              )}
            </div> 
          */}
          </div>
      <Footer />
    </div>
  );
};

export default Stack;
