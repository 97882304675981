import React, { useContext, useState, useEffect } from "react";
import Footer from "../../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../../Components/HomeComponents/Navbar/Navbar";
import Context from "../../../Context/Context";
import styles from "./Security.module.scss";
import twov from "../../../Assets/twov.svg";
import backup from "../../../Assets/backup.svg";
import dis from "../../../Assets/disabled.svg";
import login from "../../../Assets/login.svg";
import { MdSecurity } from "react-icons/md";
import { BsPersonCheckFill } from "react-icons/bs";
import DownloadAndPrint from "./DownloadAndPrint";
import { authRequestNb, refreshToken, sendRequest } from "../../../Helper/helper";
import swal from "sweetalert";
const Step1 = ({status,  secret,  qrCodeUrl, enable2FA, code, setCode}) => {
  return (

    <div className={`${styles._container} flex-between mainSecut`}>
      <h1 className={`${styles.title} flex`}>
        <MdSecurity />
        <span>GOOGLE AUTHENTICATION</span>
      </h1>
      <div className={styles.box}>
        <p className={styles.note}>
          * Please download and install Google authenticate app on your phone,
          and scan following QR code to configure your device.
        </p>

        <div className="flex-start column">
          {/* <div className={`${styles.qrcode} flex-start`}> */}
          <div className={`flex-start`}>
            {/* <QRCode value="jai rawat" /> */}
            <img src={qrCodeUrl}  width="250" height="250" className="" alt="BTRL Exchange"></img>
          </div>
          <p className={styles.n2}>
            Manually Enter the Code In Your Google Authenticator App.
          </p>
          <h4>16 Digit Key : {secret}</h4>
          <div className='signPagesamelayout'>
          <input type="text" placeholder="6 Digit code" className='form-control' onChange={e => setCode(e.target.value)}/>
          <button className="flex-start" onClick={enable2FA}>
            <BsPersonCheckFill />
            <span>Validate</span>
          </button>
          </div>
        </div>
      </div>
      <img className={styles.twov} src={twov} alt="two way verification" />
    </div>
  );
};
const Step2 = ({status,  secret,  qrCodeUrl, isDownloadable}) => {
 /* const htmlContent= */
  return (
     <DownloadAndPrint isDownloadable={isDownloadable} qrCodeUrl={qrCodeUrl}>
    <div className={`${styles._container} flex-between mainSecut`} style={{borderBottom:"none"}}>
      <h1 className={`${styles.title} flex`}>
        <MdSecurity />
        <span>GOOGLE AUTHENTICATION</span>
      </h1>
      <div className={styles.box}>
        <h1 className={styles.title2}>BTRL Exchange Backup Code</h1>
        <p className={styles.note}>
          * If your phone is lost, stolen, or erased you'll need to restore vour
          authenticator application from this backup. Please store it in a safe
          place. <br />* This code changes each time you enable 2FA. If you
          disable 2FA, this code will no longer be valid.
        </p>
        <div className="signPagesamelayout">
        {/* <div className="flex-start column"> */}
          {/* <div className={`${styles.qrcode} flex-start`}> */}
          <div className={`flex-start`}>
           {/*  <QRCode value="jai rawat" /> */}
           <img src={`${qrCodeUrl}`}  width="250" height="250" className=""  alt="BTRL Exchange" id="qrcodeUrl" key="qrcodeUrl"></img>
          </div>
          <p className={styles.n2}>
            Manually Enter the Code In Your Google Authenticator App.
          </p>
          <h4>16 Digit Key : {secret}</h4>
        </div>
      </div>
      <img className={styles.twov} src={backup} alt="backup" />
    </div>
    </DownloadAndPrint>
  );
};
const Step3 = ({disable2FA,code, setCode,status,secret,  qrCodeUrl }) => {
  return (
    <div className={`${styles._container} flex-between mainSecut`}>
      <h1 className={`${styles.title} flex`}>
        <MdSecurity />
        <span>GOOGLE AUTHENTICATION</span>
      </h1>
      <div className={styles.box}>
        <h1 className={styles.title2}>Disable Two Factor Authentication</h1>
        <p className={styles.note}>
          * Once you disable this. Your previous 2FA backup is useless <br />*
          This feature is disabled from your login.
        </p>

       {/*  <div className="flex-start column"> */}
        <div className="signPagesamelayout">
          <input type="text" placeholder="6 Digit code"  className='form-control' onChange={e => setCode(e.target.value)}/>
          <button className="flex-start" onClick={disable2FA}>
            <BsPersonCheckFill />
            <span>Validate</span>
          </button>
        </div>
      </div>
      <img className={styles.twov} src={dis} alt="backup" />
    </div>
  );
};
const Step4 = () => {
  return (
    <div className={`${styles._container} flex-between mainSecut`}>
      <h1 className={`${styles.title} flex`}>
        <MdSecurity />
        <span>GOOGLE AUTHENTICATION</span>
      </h1>
      <div className={styles.box}>
        <h1 className={styles.title2}>BTRL Exchange - Verify 2FA Code</h1>

        <div className="flex-start column">
          <input type="text" placeholder="Google Authentication Code" />
          <button className="flex-start">
            <BsPersonCheckFill />
            <span>Validate</span>
          </button>
        </div>
      </div>
      <img className={styles.twov} src={login} alt="backup" />
    </div>
  );
};

const Security = () => {
 

  const { states } = useContext(Context);
  const [code, setCode] = useState(null);
  const [status, setStatus] = useState(0);
  const [secret, setSecret] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = useState();
  const [isEnabled, setIsEnabled] = useState(false);
  const [isDownloadable, setIsDownloadable] = useState(false);
 

  const dmode = states.dmode;
  const enable2FA = async () => {
    console.log("code",code)
    const res = await sendRequest({
      "submit":"submit","code":code
    },'enable-code',"post");
    if(res){

      refreshToken(res.refresh_token);
       if(res.status===200){
        setIsEnabled(true);
        setIsDownloadable(true);
       }
    }
    else{
      swal(res.message);
    }
  };

  const disable2FA = async () => {
    const res = await sendRequest(
      {
        "submit":"submit","code":code
      },'disable-code',"post");
      if(res){

        refreshToken(res.refresh_token);
         if(res.status===200){
          setIsEnabled(false);
          setIsDownloadable(false);
          setStatus(0)
          setTimeout(()=>{setIsDownloadable(false)},5000)
         }
      }
      else{
        swal(res.message);
      }
    
    //setLoginError(null);
  };
  useEffect(() => {
    //console.log("isDownloadable",isDownloadable);
    //console.log("isEnabled",isEnabled);
    (async () => {
      // window.scrollTo(0, 0)
      const res = await authRequestNb("get-code");
      refreshToken(res.refresh_token);
     if(res.status===200){
      setStatus(parseInt(res.data.status))
      //setIsEnabled(res.data.status===1?true:false);
      setSecret(res.data.secret)
      console.log("qrCodeUrl => ",res.data.qrCodeUrl)
      setQrCodeUrl(res.data.qrCodeUrl)
     }
    })();
  }, [isEnabled, isDownloadable]);
  useEffect(() => {
    // window.scrollTo(0, 0)
  }, []);
 
  const All = [
                <Step1 status={status} secret={secret} qrCodeUrl={qrCodeUrl} enable2FA={enable2FA} code={code} setCode={setCode}/>, 
                <Step2 status={status} secret={secret} qrCodeUrl={qrCodeUrl} setIsDownloadable={setIsDownloadable} isDownloadable={isDownloadable} />, 
                <Step3 code={code} setCode={setCode} disable2FA={disable2FA} status={status} secret={secret} qrCodeUrl={qrCodeUrl} />, 
                <Step4 />
              ];
       /* isEnabled &&  */      
  return (
    <div className={`${styles.secu_l} `}>
      <Navbar />
      <div className="secuityPage">
      <div className="container">
        <div className={`${styles.secu}`}>{ /* All[1] */
          ((status===0) ?
            All[0]:
            (isDownloadable?
              All[1]
              :All[2])
            )
        
        }</div>
      </div>
    </div>
      <Footer />
    </div>
  );
};

export default Security;
