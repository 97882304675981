import React, { useState, useEffect, useMemo } from "react";
import styles from "./Search.module.scss";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { fixedNumber, isMax, refreshToken, sendGetRequest } from "../../../Helper/helper";
import { useNavigate } from "react-router-dom";
import "../../../customcode.css";
import nodata from "../../../Assets/nodata.png";
//,marketPair, setMarkerPair

const SearchCurrency = ({ dmode, globalRes, ticker, tradeChange, smdata, popup, setPopup, marketPair,
  setMarkerPair }) => {
  const [filter, setFilter] = useState({
    market: "",
    coin: "",
    price: "",
    vol: "dic",
    chg: "",
  });
 
  const [searchDataResult, setSearchDataResult] = useState(true);
  const [searchCoin, setSearchCoin] = useState("");
  const [data, setData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [marketSymbolArray, setMarketSymbolArray] = useState([
    "",
    " ",
    "  ",
    "    ",
  ]);
  const [searchData, setSearchData] = useState([]);
  const [ssize, setSsize] = useState(window.innerWidth);
  const [msas, setMsas] = useState(ssize && ssize > 380 ? 4 : 3);

  const navigate = useNavigate();

  let perSection = ssize && ssize > 380 ? 4 : 3;
  const setCoin = (_coin) => {
    if (_coin !== filter.market) {
      filterHandler("market", _coin);
    } else {
      filterHandler("market", "");
    }
  };

  const tradeChangeHandler = (pair) => {
    tradeChange(pair);
    updatedData?.map((value) => value.pair.toLowerCase() === pair.toLowerCase() && smdata(value));
    // setPopup(false);
  };

  const searchHandler = (val) => {
    const _search = val.toLowerCase();
    if (_search !== "") {
      const result = [];
      updatedData?.map((value) => {
        value.currency_symbol.toLowerCase().search(_search) !== -1 && result.push(value);
      });
      // console.log("result ", result)
      setSearchData(result);
      if (result.length > 0) {
        setSearchDataResult(true)
      } else {
        setSearchDataResult(false)
      }
    } else {
      setSearchData([])
    }
  };



  const filterHandler = (key, value) => {
    let change = filter;
    if (key === "coin" || key === "price" || key === "vol" || key === "chg") {
      if (key === "coin") {
        change.coin = value;
        change.price = "";
        change.vol = "";
        change.chg = "";
      }
      if (key === "price") {
        change.price = value;
        change.coin = "";
        change.vol = "";
        change.chg = "";
      }
      if (key === "vol") {
        change.price = "";
        change.coin = "";
        change.vol = value;
        change.chg = "";
      }
      if (key === "chg") {
        change.price = "";
        change.coin = "";
        change.vol = "";
        change.chg = value;
      }
    } else {
      change[key] = value;
    }

    setFilter({ ...change });
  };

  const coinFilter = () => {
    if (filter.coin === "") {
      filterHandler("coin", "alpharev");
    }
    if (filter.coin === "alpharev") {
      filterHandler("coin", "alpha");
    } else if (filter.coin === "alpha") {
      filterHandler("coin", "alpharev");
    }
  };

  const numFilter = (value) => {
    if (filter[value] === "") {
      filterHandler(value, "asc");
    }
    if (filter[value] === "asc") {
      filterHandler(value, "dic");
    } else if (filter[value] === "dic") {
      filterHandler(value, "asc");
    }
  };

  useMemo(() => {
    if (filter.market) {
      const temp = data?.filter((a) => a.market_symbol === filter.market);
      if (filter.coin === "alpha") {
        const _temp = temp?.sort((a, b) => {
          const _a = a.currency_symbol;
          const _b = b.currency_symbol;
          return _a < _b ? -1 : _a > _b ? 1 : 0;
        });
        setUpdatedData(_temp);
      }
      if (filter.coin === "alpharev") {
        const _temp = temp?.sort((a, b) => {
          const _a = a.currency_symbol;
          const _b = b.currency_symbol;
          return _a > _b ? -1 : _a < _b ? 1 : 0;
        });
        setUpdatedData(_temp);
      }
      if (filter.vol === "dic") {
        const _temp = temp?.sort((a, b) => {
          const _a = parseFloat(a.base);
          const _b = parseFloat(b.base);
          return _a > _b ? -1 : _a < _b ? 1 : 0;
        });
        setUpdatedData(_temp);
      }
      if (filter.chg === "dic") {
        const _temp = temp?.sort((a, b) => {
          const _a = parseFloat(a.percent_change);
          const _b = parseFloat(b.percent_change);
          return _a > _b ? -1 : _a < _b ? 1 : 0;
        });
        setUpdatedData(_temp);
      }
      if (filter.price === "dic") {
        const _temp = temp?.sort((a, b) => {
          const _a = parseFloat(a.price);
          const _b = parseFloat(b.price);
          return _a > _b ? -1 : _a < _b ? 1 : 0;
        });
        setUpdatedData(_temp);
      }
      if (filter.vol === "asc") {
        const _temp = temp?.sort((a, b) => {
          const _a = parseFloat(a.base);
          const _b = parseFloat(b.base);
          return _a < _b ? -1 : _a > _b ? 1 : 0;
        });
        setUpdatedData(_temp);
      }
      if (filter.chg === "asc") {
        const _temp = temp?.sort((a, b) => {
          const _a = parseFloat(a.percent_change);
          const _b = parseFloat(b.percent_change);
          return _a < _b ? -1 : _a > _b ? 1 : 0;
        });
        setUpdatedData(_temp);
      }
      if (filter.price === "asc") {
        const _temp = temp?.sort((a, b) => {
          const _a = parseFloat(a.price);
          const _b = parseFloat(b.price);
          return _a < _b ? -1 : _a > _b ? 1 : 0;
        });
        setUpdatedData(_temp);
      }
    } else {
      setUpdatedData(data);
    }
  }, [filter, data]);
  const path = window.location.pathname;
  useEffect(() => {
    (async () => {
      setUpdatedData(data);
      const resGlobal = await sendGetRequest("global");
      const temp = [];
      data?.map(
        (value) =>
          temp.indexOf(value.market_symbol) === -1 &&
          temp.push(value.market_symbol)
      );
      setMarketSymbolArray(temp);
      const trade = await resGlobal.data.landing_page.toUpperCase().split("/");
      filterHandler("market", trade[1]);
      // data && tradeChangeHandler(resGlobal.data.landing_page.toUpperCase().replace("/","_"));
    })();
  }, [data]);

  useEffect(() => {
    (async () => {
      window.addEventListener("resize", () => setSsize(window.innerWidth));
      
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const res = await sendGetRequest("get-pairs");
      res.refresh_token && refreshToken(res.refresh_token);
     
      const currency = path.split("/")[2]?.toUpperCase()
      const market = path.split("/")[3]?.toUpperCase()
      //console.log("res.data=>",res.data);
      //console.log("currency=>",currency);
     // console.log("market=>",market);
     
      if (res.data.some(e => e.market_symbol.toUpperCase() === market && e.currency_symbol.toUpperCase() === currency)) {
        /* vendors contains the element we're looking for */
       
        setMarkerPair({"currency_symbol":currency,"market_symbol":market});
      }
      
      setData(res.data);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    if (ticker && updatedData) {
      const temp = updatedData;
      const index = temp.findIndex(obj => obj.pair.toUpperCase() === `${ticker.currency_symbol.toUpperCase()}_${ticker.market_symbol.toUpperCase()}`)
      temp[index] = { ...temp[index], ...ticker, currency_symbol: ticker.currency_symbol.toUpperCase(), market_symbol: ticker.market_symbol.toUpperCase() };
      setUpdatedData(temp)
      if (searchData) {
        const _tempS = searchData;
        const index = _tempS.findIndex(obj => obj.pair.toUpperCase() === `${ticker.currency_symbol.toUpperCase()}_${ticker.market_symbol.toUpperCase()}`)
        _tempS[index] = { ..._tempS[index], ...ticker, currency_symbol: ticker.currency_symbol.toUpperCase(), market_symbol: ticker.market_symbol.toUpperCase() };
        // console.log("values after seacrh ", _tempS)
        setSearchData(_tempS)
      }
    }
  }, [ticker])

 

  useEffect(() => {
    (() => {
      if (globalRes && data) {
        const res = globalRes
        const currency = path.split("/")[2]?.toUpperCase();
        const market = path.split("/")[3]?.toUpperCase();
        if (updatedData && currency && market) {
          let pair;
          for (let i = 0; i < data.length; i++) {
            const value = data[i];
            // console.log('value.pair=>',value.pair)
            // console.log('updatedData=>',updatedData)
            if (value.pair.toLowerCase() === `${currency.toLowerCase()}_${market.toLowerCase()}`) {
              pair = value.pair;
            }
            if (i === data.length - 1) {
              if (pair) {
                tradeChangeHandler(pair);
              } else {
                tradeChangeHandler(
                  res.data.landing_page.toUpperCase().replace("/", "_")
                );
                // navigate(`/exchange/${res.data.landing_page.toUpperCase()}`,{ replace: true })
              }
            }
          }
        } else {
          // tradeChangeHandler(
          //   res.data.landing_page.toUpperCase().replace("/", "_")
          // );
          navigate(`/exchange/${res.data.landing_page.toUpperCase()}`, { replace: true })
        }
      }
    })()
  }, [path, updatedData])


  useEffect(() => {
    searchCoin !== undefined && searchHandler(searchCoin)
  }, [searchCoin, updatedData])




  return (
    <section
      style={{ display: popup || ssize > 1330 ? "block" : "none" }}
      // style={{ display: popup || ssize > 1050 ? "block" : "none" }}
      className={`${styles.searchcurreny_l} latesthandle`}
    >
      <div
        onClick={() => setPopup(false)}
        style={{ display: popup ? "block" : "none" }}
        className={styles.cover}
      ></div>
      <div className={`${styles.coins} topnavigationsearch`}>
        <button
          className={`${styles.navigate} flex-center`}
          onClick={() =>
            setMsas((msas) => (msas > perSection ? msas - perSection : marketSymbolArray.length))
          }
        >
          <i className="fa-solid fa-angle-left"></i>
        </button>
        {marketSymbolArray
          .slice(msas > perSection ? msas - perSection : 0, msas > perSection ? msas : perSection)
          ?.map((value, key) => (
            <button
              className={filter.market === value ? "navactive" : ""}
              onClick={() => setCoin(value)}
              key={key}
            >
              {value}
            </button>
          ))}
        <button
          className={`${styles.navigate} flex-center`}
          onClick={() =>
            setMsas((msas) =>
              marketSymbolArray.length < msas + perSection
                ? marketSymbolArray.length !== msas
                  ? marketSymbolArray.length
                  : perSection
                : msas + perSection
            )
          }
        >
          <i className="fa-solid fa-angle-right"></i>
        </button>
      </div>

      <input
        type="input"
        placeholder="Search by Currency Name"
        className={styles.coinSearch}
        onChange={(e) => setSearchCoin(e.target.value)}
      />

      <div className='custableresponsive table-responsive inner'>
        <table className={styles.c_table}>
          <thead className={styles.thead}>
            <tr>
              <th onClick={coinFilter} className="">
                <div><span className="sortIcon">
                  <AiFillCaretUp
                    className={filter.coin === "alpha" && styles.active}
                  />
                  <AiFillCaretDown
                    className={filter.coin === "alpharev" && styles.active}
                  />
                </span>{" "}
                  Coin
                </div></th>
              <th onClick={() => numFilter("price")} className="">
                <div><span className="sortIcon">
                  <AiFillCaretUp
                    className={filter.price === "dic" && styles.active}
                  />
                  <AiFillCaretDown
                    className={filter.price === "asc" && styles.active}
                  />
                </span>{" "}
                  Price
                </div></th>
              <th onClick={() => numFilter("vol")} className="">
                <div><span className={`sortIcon`}>
                  <AiFillCaretUp
                    className={filter.vol === "dic" && styles.active}
                  />
                  <AiFillCaretDown
                    className={filter.vol === "asc" && styles.active}
                  />
                </span>{" "}
                  Vol
                </div></th>
              <th onClick={() => numFilter("chg")} className="">
                <div><span className="sortIcon">
                  <AiFillCaretUp
                    className={filter.chg === "dic" && styles.active}
                  />
                  <AiFillCaretDown
                    className={filter.chg === "asc" && styles.active}
                  />
                </span>{" "}
                  CHG
                </div></th>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {searchCoin == "" ?
              // {(searchData?.length == 0 || !searchData) ?
              <>
                {
                  updatedData?.map((coin, i) => (
                    <tr
                      onClick={() => {
                        tradeChangeHandler(coin.pair)
                        setPopup(false);
                      }}
                      className={styles.tr}
                      key={i}
                    >
                      <td>{coin.pair?.replace("_", "/")}</td>
                      <td>{fixedNumber(coin.price, 10)}</td>
                      <td>{fixedNumber(coin.base, 10)}</td>
                      {/* <td className={isMax(coin.pair, parseFloat(coin.percent_change)) ? styles.pos : isMax(coin.pair, parseFloat(coin.percent_change)) === false ? styles.neg : (parseFloat(coin.percent_change)>0?styles.pos:styles.neg)}> */}
                      {/* <td className={parseFloat(coin.percent_change).toFixed(2)===0?"":isMax(coin.pair, parseFloat(coin.percent_change)) ? "textsuccess" : (parseFloat(coin.percent_change).toFixed(2)>0?"textsuccess":parseFloat(coin.percent_change).toFixed(2)===0?isMax(coin.pair, parseFloat(coin.percent_change)) === false ? "textdanger" :"":"textdanger")}> */}
                      <td className={((parseFloat(coin.percent_change).toFixed(2)===0 || (parseFloat(coin.percent_change).toFixed(2)).toString()==="0.00" || (parseFloat(coin.percent_change).toFixed(2)).toString()==="-0.00")?"":(parseFloat(coin.percent_change).toFixed(2)>0?"textsuccess":"textdanger"))}  data={parseFloat(coin.percent_change).toFixed(2)}>
                        {parseFloat(coin.percent_change).toFixed(2)}%
                      </td>
                    </tr>
                  ))
                }
              </>
              :
              <>
                {searchDataResult ?
                  // {searchData && searchDataResult ?
                  <>
                    {searchData?.map((coin, i) => (
                      <tr
                        onClick={() => {
                          tradeChangeHandler(coin.pair)
                          setPopup(false);
                        }}
                        className={styles.tr}
                        key={i}
                      >
                        <td>{coin.pair?.replace("_", "/")}</td>
                        <td>{fixedNumber(coin.price, 10)}</td>
                        <td>{fixedNumber(coin.base, 10)}</td>
                        {/* <td className={isMax(coin.pair, parseFloat(coin.percent_change)) ? styles.pos : isMax(coin.pair, parseFloat(coin.percent_change)) === false ? styles.neg : ""}> */}
                        {/* <td className={parseFloat(coin.percent_change).toFixed(2)===0?"":isMax(coin.pair, parseFloat(coin.percent_change)) ? "textsuccess" : (parseFloat(coin.percent_change).toFixed(2)>0?"textsuccess":parseFloat(coin.percent_change).toFixed(2)===0?isMax(coin.pair, parseFloat(coin.percent_change)) === false ? "textdanger" : "":"textdanger")}> */}
                        <td className={(parseFloat(coin.percent_change).toFixed(2)===0 || (parseFloat(coin.percent_change).toFixed(2)).toString()==="0.00" || (parseFloat(coin.percent_change).toFixed(2)).toString()==="-0.00")?"":(parseFloat(coin.percent_change).toFixed(2)>0?"textsuccess":"textdanger")} data={(parseFloat(coin.percent_change).toFixed(2)).toString()}>
                          {parseFloat(coin.percent_change).toFixed(2)}%
                        </td>
                      </tr>
                    ))}
                  </>
                  :
                  <>
                    <tr key=""></tr>
                    <tr className={`${styles.nodata} flex-center column`}>
                      <img src={nodata} alt="nodata" />
                      <h1>No data</h1>
                    </tr>
                  </>
                }
              </>
            }



          </tbody>
        </table>
      </div>
    </section>
  );
};

export default SearchCurrency;
