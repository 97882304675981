/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState, useEffect, useMemo } from "react";
import { NavLink as Link, useNavigate } from "react-router-dom";
import Footer from "../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../Components/HomeComponents/Navbar/Navbar";
import { authRequestNb, refreshToken } from "../../Helper/helper";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const MyRank = () => {
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState(null);
  const pensionPlan = () => navigate("/pension-plan");
  useEffect(() => {
    (async () => {
      
      const res = await authRequestNb("pension-plan");
      refreshToken(res.refresh_token);
      if(res.data.self_details.profile_image){

        setProfileImage(res.data.self_details.path+res.data.self_details.profile_image)
      }
      setData(res.data);
      // console.log("res.data.self_details.super_booster_status ",res.data.self_details.super_booster_staus );
      //  console.log("res.data.pension_rank ",res.data.pension_rank );
      if ((res.data.self_details.super_booster_staus !== "1" || res.data.pension_rank === "") ) {
        pensionPlan();
      }
     
   
    })();
  }, []);
  const handleDownloadPDF = () => {
    const element = document.getElementById('rankCard');
    
    html2canvas(element, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      // ID card size in mm (85.60 x 53.98 mm)
      //const pdf = new jsPDF('landscape', 'mm', [85.60, 53.98]); // Set ID card size
      const pdf = new jsPDF('portrait', 'mm', [53.98,85.60]);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

      pdf.save('i-card.pdf');
    });
  };
  
  return (
    <div className={`ligtMode`}>
      <Navbar />
      <div className="myRanks sn_page_pad">
        <div className="container">
           <div className="row w-100 justify-content-center">
            <div className="col-sm-8 text-center ">
                  <button className="btn btn-primary mt-3 mb-3"  onClick={handleDownloadPDF}>
                    Download as PDF
                  </button>
            </div>
           </div>
        
          <div className="snRank"  id="rankCard">
            <div className="companyLogo text-center">
              <img src="/latestimages/newlogo.png" className="img-fluid logoRank" />
            </div>
            <div className="rankInfo">
              <div className=" text-center">
                <img src={profileImage ? profileImage : "/user.jpg"} className="img-fluid rankuser" />
                <div className="userName">
                  <h3><span>Name:</span><br/> {data?.self_details?.name}</h3>
                  
                  <img src={(data)?`/latestimages/ranks/${data?.pension_rank.toLowerCase()}.png`:`/latestimages/ranks/bronze.png`} className="img-fluid rankimg" />
                </div>
              </div>
              <div className="RankPerInfo">
                <div className="d-flex gap-2 justify-content-between">
                  <p className="l">Pension Rank:</p>
                  <p className="r">{data?.pension_rank}</p>
                </div>
                <div className="d-flex gap-2 justify-content-between">
                  <p className="l">ID:</p>
                  <p className="r">{data?.self_details?.refferal_id}</p>
                </div>
                <div className="d-flex gap-2 justify-content-between">
                  <p className="l">Phone:</p>
                  <p className="r">{data?.self_details?.mobile}</p>
                </div>
                <div className="d-flex gap-2 justify-content-between">
                  <p className="l">Email:</p>
                  <p className="r">{data?.self_details?.email}</p>
                </div>
              </div>

            </div>
                  <img src="/latestimages/signature.png" className="img-fluid signature" />
                
          </div>
          
        </div>
      </div>
      <Footer />
    </div>

  );
};

export default MyRank;
