/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState, useEffect, useMemo } from "react";
import { NavLink as Link, useNavigate } from "react-router-dom";
import Footer from "../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../Components/HomeComponents/Navbar/Navbar";
import Context from "../../Context/Context";
//import { useNavigate } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import styles from "./module.css";
import Modal from 'react-modal';
import 'react-tabs/style/react-tabs.css';
import lottery1 from "../../Assets/lottery/lottery1.png";
import lottery2 from "../../Assets/lottery/lottery2.png";
import { BiCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Table from 'react-bootstrap/Table';
import swal from "sweetalert";
import DataTable, { createTheme } from 'react-data-table-component';
import FilterComponent from "./FilterComponent";
import {
  authRequest,
  refreshToken,
  shortener
} from "../../Helper/helper.js";
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const _path = window.location.pathname;
const Lottery = ({lotteryStatus, setLotteryStatus}) => {
 
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  
  function closeModal() {
    setIsOpen(false);
  }
  const { states } = useContext(Context);
  const [displayclass, setDisplayClass] = useState('show');
  const navigate = useNavigate();
  const signin = () => navigate("/signin");
  //const isLogin = states.isLogin;
  const isLogin = sessionStorage.getItem("isLogin") === "true" ? true : false;
 
  const dmode = states.dmode;
  const ticketPrice = 50000000;
  const currency_symbol = "SUNNY";
  const totalTicket = 270000;
  const [token, setToken] = useState(1);
  const [infos, setInfos] = useState({});
  const [soldTicket, setSoldTicket] = useState(0);
  const [totalBtrl, setTotalBtrl] = useState(token*ticketPrice);
  const [btrlBalance, setBtrlBalance] = useState(0);
  const [errormessage, setErrormessage] = useState({});
  const [userTickets, setUserTickets] = useState([]);
  const [winners, setWinners] = useState([]);
  const [contractAddress, setContractAddress] = useState("");
  const [contractLink, setContractLink] = useState("");
  const [winnerStatus, setWinnerStatus] = useState(0);
  const [pending, setPending] = React.useState(true);
  const handleChange = (event) => {
      const result=event.target.value.replace(/\D/g,'');
      if(result!==""){
        const RemainingTickets=totalTicket-soldTicket;
        if(RemainingTickets>=result){
          setToken(result);
          setTotalBtrl(result*ticketPrice);
        }
        else{
          setToken(RemainingTickets);
          setTotalBtrl(RemainingTickets*ticketPrice);
        }

      }
      else{
        setToken(result);
      }
  };
  const setInfosHandler = (key) => {
    let change = infos;
    change[key] = true;
    setInfos({ ...change });
    setTimeout(() => {
      setInfos({});
    }, 2000);
  };
  const errorHandler = (key, value) => {
    let change = errormessage;
    change[key] = value;
    setErrormessage({ ...change });
  };
  const getuserticket=async ()=>{
    const resBal1 = await authRequest({ currency: currency_symbol }, "user-lotteries");
        if(resBal1)
        {
          if(resBal1.status===200){
            setUserTickets(resBal1.data)
          }
        }
  }
  const getwinnersticket=async ()=>{
    const resBal1 = await authRequest({ currency: currency_symbol }, "luckydraw-winners");
        if(resBal1)
        {
          if(resBal1.status===200){
            setWinners(resBal1.data)
          }
          setContractAddress(resBal1.contract_address);
          setContractLink(resBal1.link);
          setWinnerStatus(parseInt(resBal1.lotterystatus));
        }
  }

  useEffect(()=>{
    (async()=>{
      console.log("isLogin",isLogin);
      if(isLogin){

        getuserticket();
        const resBal = await authRequest({ currency: currency_symbol }, "balance");
        refreshToken(resBal.refresh_token);
        if(resBal.status===200){
          setBtrlBalance(resBal.data[0]?.available_balance)
        }
      }
      else{
        setBtrlBalance(0)
      }
    })()
  },[isLogin])
  useEffect(()=>{
    (async()=>{
      if(soldTicket===0){

        const resBal1 = await authRequest({ currency: currency_symbol }, "total-lottery-sold");
        refreshToken(resBal1?.refresh_token);
        if(resBal1?.status===200){
          setLotteryStatus(1);
          setSoldTicket(resBal1?.data)
        }
      }
    })()
  })
  useEffect(()=>{
    (async()=>{
      /* 
        contractAddress
        contractLink
        winnerStatus
      */
        if(winners.length===0 && contractAddress==="" && contractLink==="" && winnerStatus===0){

          //getwinnersticket();
        }
    })()
  })
  const formDataHandler = async () => {
  
   if(token>0){
    if(btrlBalance >= totalBtrl){
  const RemainingTickets=totalTicket-soldTicket;
  if(token > RemainingTickets){
        swal("Tickets must be less than or equals to "+RemainingTickets+".")
      }
      else{
        const res = await authRequest({
          ticket : token,
          
        } , "buy-lottery");
        refreshToken(res.refresh_token)
    
        if(res.status === 200){
           swal(res.message)
           await getuserticket();
           setBtrlBalance(btrlBalance-totalBtrl)
           setSoldTicket(parseInt(soldTicket)+parseInt(token))
           closeModal()
        }else if(res.status === 401){
          for (const keys of Object.keys(res.data)) {
            errorHandler(keys,res.data[keys])
          }
        }else{
          swal(res.message)
        }

      }
    }
    else{
      swal("Balance must be greater than "+totalBtrl+".")
     }
   }
   else{
    errorHandler("ticket","Ticket is required.")
   }
  };
  const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  useEffect(() => {
		const timeout = setTimeout(() => {
			setRows(winners);
			setPending(false);
		}, 2000);
		return () => clearTimeout(timeout);
	}, [winners]);
  const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);
  const columns =  [
    {
      name: 'Ticket Number',
      selector: 'ticket',
      sortable: true,
    },
   /*  {
      name: 'Winner',
      selector: 'winner',
      sortable: true,
    }, */
    {
      name: 'Winning Prize',
      selector: 'winningAmount',
      sortable: true,
    },
  ];
  const clickHandler = (state) => {
    // console.log("ID ", state);
    // window.open("https://wa.me/"+state.mobile, '_blank', 'noopener,noreferrer');
  };
  createTheme('dark', {
    background: {
      default: 'transparent',
    },
    
  });
  const customStyles = {
    title: {
      style: {
        fontColor: 'red',
        fontWeight: '900',
      }
    },
    
  };
/* (e) =>
          setToken((v) => (e.target.validity.valid ? e.target.value : v)) */
  return (
    <div className={dmode ? `  ligtModeInfoPage ligtModeLottery  ` : `  ligtModeInfoPage ligtModeLottery `}>
      <Navbar />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal" style={customStyles}
        ariaHideApp={false}
      ><div className={dmode ? `  popupcenter darkModeLottery  ` : `  popupcenter ligtModeLottery `} >
            <div className="modalHeader d-flex justify-content-between">
              <h2>Buy Ticket</h2>
              <button onClick={closeModal}><i className="fa-solid fa-times" aria-hidden="true"></i></button>
            </div>
            <div className="modalBody">
               <label>{token>1?"Tickets":"Ticket"}:</label>
               <div className="inPut">
                 <input type="text" name="ticket" className="form-control" placeholder="1" value={token} onChange={handleChange.bind(this)}/>
                 {errormessage.ticket && (
                    <p className={styles.warning_note}>{errormessage.ticket}</p>
                  )}
               </div>
               <div className="valuemodal d-flex justify-content-between align-items-center">
                  <p>Stake</p>
                  <p>{ticketPrice} SUNNY</p>
               </div>
               <div className="valuemodal d-flex justify-content-between align-items-center">
                  <p>Balance</p>
                  <p>{btrlBalance} SUNNY</p>
               </div>
               <hr />
               <div className="valuemodal d-flex justify-content-between align-items-center">
                  <p>Total Stake</p>
                  <p><b>{totalBtrl} SUNNY</b></p>
               </div>
               {(btrlBalance>0 && btrlBalance >= totalBtrl)?
               <>
                <button className="btn" onClick={formDataHandler}>Stake Now</button>
               </>
               :
               <>
               <button className="btn" disabled={true}>Stake Now</button>
               </>
               }
               
            </div>
          </div>
      </Modal>
      	<div className="sn_page_pad looterpage ticketSection  p-relative">


        {/*   <img src='lottery/firefixed.png' className="firefixed img-fluid"/>
          <img src='lottery/firefixed.png' className="firefixed firefixed2 img-fluid"/> */}
          
          {/* <img src='lottery/diwali-flower.png' className="diwali1 img-fluid"/>
          <img src='lottery/diwali-flower2.png' className="diwali2 img-fluid"/> */}
          <img src={lottery1} className="lottery lottery1 img-fluid"/>
          <img src={lottery2} className="lottery lottery2 img-fluid"/>
          <div className="container relative">
          <div className="text-center p-relative">
        		<h1>
          {/* <img src='lottery/diya.png' className="diya img-fluid"/> */}
          Crorepati Lucky Draw 2.0
         {/*  <img src='lottery/diya.png' className="diya img-fluid"/> */} </h1>


            <h4>{ticketPrice} SUNNY Stake - 1 Lucky Draw Ticket. </h4>
            {isLogin ?
            <>
              <button href='#' onClick={openModal} className="buybtnouter">
                <div className="buybtn"><span>Buy Lucky Draw Tickets</span>
                </div>
              </button>
            </>
            :
            <>
            <button href='#' onClick={signin} className="buybtnouter">
              <div className="buybtn"><span>Buy Lucky Draw Tickets</span>
              </div>
            </button>
            </>
            }
            
            <br />
            <br />
           {/* <h6> You will get 9 % extra SUNNY after completion of staking period, which is one year.</h6> */}
            </div>
            <br />
            <h2 className="text-center">Prizes Are As Follows</h2>
            <div className='drawBox'>
              
              <div className='drawHead d-flex align-items-center justify-content-between'>
                <h6 style={{margin: '0px'}}><b>Sold Tickets:</b></h6>
                
                <h5>  {soldTicket.toLocaleString()}/{totalTicket.toLocaleString()}</h5>
              </div>
              <div className='drawBody '>
                <div className={displayclass === 'show' ? ` displayyes mainMidal ` : ` displayno mainMidal`}>
                <div className="row">
                    <div className="col-sm-6">
                      <div className="snvalue text-center">
                        <h4>1st Prize</h4>
                        <h2>1 Crore INR</h2>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="snvalue text-center">
                        <h4>2nd Prize To 126 Members</h4>
                        <h2>1 Lakh INR </h2>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="snvalue text-center">
                        <h4>3rd  Prize To 126 Members</h4>
                        <h2>10,000 INR </h2>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="snvalue text-center">
                        <h4>4th Prize To 21,000 Members </h4>
                        <h2>500 INR</h2>
                      </div>
                    </div>
                    
                    <div className="col-sm-12">
                      <div className="snvalue lightsnvalue text-center">
                        <h2>Get 10% To Direct Sponsor</h2> 
                        <p>You are required to buy a ticket and participate in the referral program. This ticket serves as your entry pass to become an active member of the referral program. </p>
                        <br/>
                        <h2>Get 1 Free Ticket </h2> 
                        <p>On the purchase of every 5 tickets by your direct team, you will get 1 ticket free. </p>
                        {/* <p>Get one free ticket on the purchase of every 5 tickets by your direct team. </p> */}
                        <br/>
                        <h2> Note -: </h2>  
                        <p>a) You will get 9 % extra SUNNY after completion of staking period, which is one year. </p>
                        <p>b) Lucky Draw 2.0 will be opened on 31 October 2024.</p>
                        <p>c) If total tickets will not be sold till 30 October, Draw will be cancelled and you will get 150% extra SUNNY instead of 9% after completion of staking period, which is one year.</p>

                        {/* <p>a) You will get 9 % extra SUNNY after completion of staking period, which is one year. </p>
                        <p>b) Lucky Draw 2.0 will be opened on 27 March 2024.</p> */}
                        {/* <p>c) Get 1 free ticket on buy every 5 tickets or direct team..</p> */}
                       
                        {/* <p>Crorepati Lucky Draw 2.0 will be launched on 27 March 2024. So last date of ticket buying is 26 March 2024.</p> */}
                        {/* <h2> Conditions -: </h2>                                                                                                                                       
                        <p>a) Maa Luxmi Crorepati Lucky Draw will be launched on 12 Nov 2023. So last date of ticket buying is 11 Nov 2023</p>
                        <p>b) While completion of Lucky draw, immediate early withdrawal option will be provided to you.</p> */}
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="snvalue lightsnvalue text-center">
                        <Link to="/crorepati-winner"  className="btn">Maa Luxmi Crorepatii Lucky Draw Winners</Link>
                      </div>
                    </div>
                  </div>
                </div>
               
              </div>

            </div>
          </div>
        </div>
        {/* {winnerStatus===1?<>
          <div className="sn_page_pad ticketSection roundsSection bgchange" key={"win"}>
                <div className="container relative " key={"winner"}>
                  <div className="row" key={"winners"}>
                    <div className="col-sm-12">
                     
                    <DataTable 
                    title={<><h2 className="text-center dt-heading" style={{marginTop: '50px'}}><img src="lottery/diya.png" className="diya img-fluid" />Maa Luxmi Crorepati Lucky Draw Winners<img src="lottery/diya.png" className="diya img-fluid" /></h2></>} 
                    theme={dmode ?"dark":"solarized"} columns={columns} 
                    data={winners && winners.length>0? winners.filter(
                        item => (item.ticket && item.ticket.includes(filterText) ),
                      ):[]}
                      progressPending={pending}  subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      persistTableHead
                      pagination
                     
                      paginationRowsPerPageOptions={[10,20,50,100,500,1000]}
                      paginationPerPage={100}
            
                    
                 
                      /> 
                     { contractLink!==""?<><h4 style={{marginTop:"10px"}}>Lucky Draw Contract: <a href={contractLink} target="_blank">{contractAddress}</a></h4></>:<></>}
                     
                    </div>
                  </div>
                </div>
              </div>
        
        </>:
        <>
        </>} */}
              
        {(isLogin && userTickets.length>0) ?
            <>
              <div className="sn_page_pad ticketSection roundsSection " key={"aa"}>
                <div className="container relative " key={"aaa"}>
                  <h2 className="text-center" key={"aaaa"}>Congratulations 🎉 Your Tickets Total Are  As Follows.</h2>
                  <h2 className="text-center" key={"aaaat"}>You have total {userTickets.length} {userTickets.length>1?"tickets":"ticket"}.</h2>
                  <div className="row justify-content-center" key={"aaaaa"}>
                  { (userTickets.length>0) ?  (userTickets?.map((dbticket, i) => (
                      <>
                          <div className="col-sm-3" key={dbticket.ticket}>
                            <div className="snticket d-flex" key={"a"+dbticket.ticket}>
                              <div className="leftticket " key={"b"+dbticket.ticket}>
                                <h2  key={"c"+dbticket.ticket}>Ticket Number</h2>
                                <h3  key={"d"+dbticket.ticket}>{infos[dbticket.ticket]? <><span  key={"e"+dbticket.ticket}>Ticket No. Copied!</span></>:dbticket.ticket} 
                                <CopyToClipboard  key={"f"+dbticket.ticket}
                                    text={dbticket && dbticket.ticket}
                                    onCopy={() => setInfosHandler(dbticket.ticket)}
                                  >
                                    <BiCopy  key={"g"+dbticket.ticket} />
                                  </CopyToClipboard>
                                  
                                </h3>
                                
                              </div>
                              <div className="rightticket"></div>
                            </div>
                          </div>
                          
                      </>
                  ))):
                      <>
                        
                          <div className="col-sm-3" key="0-0">
                            <div className="snticket d-flex">
                              <div className="leftticket ">
                                <h2>Ticket Number</h2>
                                <h3>Not Buy Yet</h3>
                              </div>
                              <div className="rightticket"></div>
                            </div>
                          </div>
                          
                      
                      </>
                    }
                    </div>
                </div>
              </div>
      </>
      :<></>
            }       
      <Footer />
    </div>

  );
};

export default Lottery;
