import React, { useState, useRef, useEffect } from 'react';
//import { DateRangePicker, DateRange } from 'react-date-range';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { sendRequest } from '../../Helper/helper';
import swal from "sweetalert";
const MyDateRangePicker = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const refOne = useRef(null);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const fetchData = async (startDate, endDate) => {
    setLoading(true);
    const data = {};
    if (startDate && startDate!=="" && endDate && endDate!=="") {
      data.start_date = startDate;
      data.end_date = endDate;
    }
   
    try {
      const res = await sendRequest(
        data,
        'tdsreport'
      );
      if (res.status === 200) {
      }else{
        
      }
      swal(res.message);
      console.log(res); // Handle the response
    } catch (error) {
      console.error('Error fetching data:', error);
    }finally {
      setLoading(false); 
    }
  };



  return (
    <a className="custombtn1 btn" style={{
      backgroundColor:"#4a67f0",
    
      }} onClick={() =>fetchData("", "")} disabled={loading}>{loading ? 'Processing...' : 'Generate TDS Report'} </a>
    
  );
};

export default MyDateRangePicker;
