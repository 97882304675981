import React, { useContext, useState, useEffect } from "react";

import styled from "styled-components";
const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ setFilterSelectText , filterSelectText, filterText, setFilterText}) => (

  <>
    {/* <Input
      id="search"
      type="text"
      placeholder="Filter table data..."
      value={filterText}
      onChange={onFilter}
    /> 
    (e.target.value)
    */}
     <label className="styles.sbox cusSelect">
        Filter by Deposit Status: &nbsp;
        <select  onChange={(e) => {
          setFilterSelectText(e.target.value)
          
          }}>
          <option value="" style={{background:"transparent"}}>All</option>
          <option value="Deposited" style={{background:"transparent"}}>Deposited</option>
          <option value="Not Deposited" style={{background:"transparent"}}>Not Deposited</option>
        </select>
      </label>
    
   
  </>
);

export default FilterComponent;
