import React,{useEffect, useRef} from 'react';
// import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import jwt_decode from "jwt-decode";
import MiniMarket from '../MiniMarket/MiniMarket';
import styles from './MarketChart.module.scss';
//import { widget } from '../../../charting_library/charting_library.esm';
import { widget } from '../../../charting_library/charting_library.esm';
import Datafeed from '../chart-integration/datafeed.js'
import swal from 'sweetalert';
import { apiUrl, authRequest, authRequestNb } from '../../../Helper/helper';



const MarketChart = ({miniData , _isLogin  , dmode , popup , setPopup, extheme}) => {
  const tvChartRef = useRef(null);
const [currency , market ] = [miniData?.currency_symbol , miniData?.market_symbol];
const decoded = _isLogin && jwt_decode(sessionStorage.getItem('user'));
//console.log(decoded)
const userid = decoded?.user;
const BasePathApi =apiUrl;
useEffect(() => {
  const newCur=currency===undefined?"BTRL":currency.toUpperCase();
  const newMar=market===undefined?"INR":market.toUpperCase();
  if(extheme!==undefined){
    newChart(newCur,newMar, Datafeed, _isLogin, extheme)
  }
  else{
    newChart(newCur,newMar, Datafeed, _isLogin, localStorage.getItem('theme'))

  }
}, [currency, market, Datafeed , _isLogin, extheme]);

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
const newChart=(newCur, newMar, Datafeed, _isLogin, extheme)=>{
  //console.log("extheme11",typeof(extheme))
  let widgetOptions = {
  
   symbol: 'BTRLExchange:'+ newCur+'/'+newMar,
   
    // BEWARE: no trailing slash is expected in feed URL
    //datafeed: new window.Datafeeds.UDFCompatibleDatafeed(defaultProps.datafeedUrl),
    datafeed: Datafeed,
   // datafeed: pa,
    interval: 15,
    //debug: true,
    container: tvChartRef.current,
    library_path: '/charting_library/', 
    locale: getLanguageFromURL() || 'en',
    disabled_features: [//'use_localstorage_for_settings', 
    //"header_chart_type",//"header_indicators",, 
    
    "header_symbol_search" ,"go_to_date"], //'use_localstorage_for_settings'
    // "timeframes_toolbar",
    // 'study_templates',
    enabled_features: ["dont_show_boolean_study_arguments", "hide_last_na_study_output"],
    // studies: [
    //   "Volume@tv-basicstudies"
    //   ],
    client_id: 'BTRLExchange',
    user_id: _isLogin?userid:'BTRL',
    fullscreen: false,
    autosize: true,
    studies_overrides: {},
    //theme: extheme==="bodydarkMode"?"dark":"light",
    theme: extheme==="bodydarkMode"?"dark":"light",
    //custom_css_url: '../featuresets.css', add custom css file here
    settings_adapter: {
      initialSettings: {  function(){  }},
      setValue: function(key, value) {  },
      removeValue: function(key) {  },
  },overrides: {
    //"paneProperties.background": "#222222",
   // "theme": extheme==="bodydarkMode"?"#222222":"light",
  // backgroundColour : "transparent",
    //"background": extheme==="bodydarkMode"?"#222222":"#ffffff",
    //"toolProperties.background": extheme==="bodydarkMode"?"#222222":"#ffffff",
    //"paneProperties.background": extheme==="bodydarkMode"?"#222222":"#ffffff",
    //"paneProperties.background": extheme==="bodydarkMode"?"#222222":"#ffffff",
    
    //  "paneProperties.vertGridProperties.color": "#363c4e",
    // "paneProperties.horzGridProperties.color": "#363c4e",
      "paneProperties.background": extheme==="bodydarkMode"?"#222222":"#ffffff",
     "symbolWatermarkProperties.transparency": 90,
     "scalesProperties.textColor" : "#AAA",
      "paneProperties.backgroundType": "solid",
},
// "toolbar_bg":  extheme==="bodydarkMode"?"#222222":"#ffffff",
// "overflowwrap_bg":  extheme==="bodydarkMode"?"#222222":"#ffffff",
custom_css_url:'../../../../chart.css'
  };

  if(_isLogin){
    widgetOptions = {...widgetOptions , charts_storage_url: BasePathApi,
      charts_storage_api_version: '1.1',
      saved_data_meta_info:true,
      auto_save_delay: 5,
     load_last_chart: true,
     save_load_adapter: {
      charts: [],
      studyTemplates: [],
      drawingTemplates: [],
      getAllCharts: async function() {
          if(_isLogin){
            // const pathforallchart = BasePathApi+'/user/1.1/study_templates?client=Btrl&user='+userid
            const pathforallchart = 'user/1.1/study_templates?client=Btrl&user='+userid
          const response =  await authRequestNb(pathforallchart);
         // console.log(response , typeof response )
          const data = response.data;
        
        if(!data.error){
         // console.log("exp data this.charts.name length >", this.charts.length);
          //console.log("exp data.name=>", data.name);
          if(this.charts.length > 0 && this.charts.length < data.length) {
            for(let i=0; i < this.charts.length; i++){
              //console.log("exp data this.charts.name 1=>", this.charts[i].name);
              for (let j=0; j < data.length; j++)
              {
                if(typeof(this.charts[i].name) !== undefined && this.charts[i].name !== data[j].name){

                  this.charts.push(data[j]);

                }
              }
            }
          }
          else if(this.charts.length === 0) {
            for (let j=0; j < data.length; j++)
            {
                this.charts.push(data[j]);
            }
            //this.charts.push(data);
          }

        }
         //console.log('exp data response', this.charts)
        
          return Promise.resolve(this.charts);
          }else{
            swal("Please login first !")
          }
      },
  
      removeChart: async function(id) {
        const pathfordeletechart = 'user/1.1/study_templates/delete';
          for (var i = 0; i < this.charts.length; ++i) {
              if (this.charts[i].id === id) {
                const response = await authRequest({chartId: id},pathfordeletechart);
                // Awaiting response.json()
                // const resData = await response.json();
                  this.charts.splice(i, 1);
                  return Promise.resolve();
              }
          }
  
          return Promise.reject();
      },
  
      saveChart: async function(chartData) {
          
          if (!chartData.id) {
              chartData.id = Math.random().toString();
          } else {
              this.removeChart(chartData.id);
          }
          chartData.timestamp = new Date().valueOf();
          chartData.lastModified = chartData.timestamp
          chartData.chartId = chartData.id
         // console.log('exp data chartData', chartData)
          const pathforsave = 'user/1.1/charts?client=Btrl&user='+userid
          const response = await authRequest(chartData,pathforsave);
            // Awaiting response.json()
            const resData = response.data;
          this.charts.push(chartData);
  
          return Promise.resolve(chartData.id);
      },
  
      getChartContent: function(id) {
          for (var i = 0; i < this.charts.length; ++i) {
              if (this.charts[i].id === id) {
                  return Promise.resolve(this.charts[i].content);
              }
          }
  
          console.error('error');
  
          return Promise.reject();
      },
  
      removeStudyTemplate: function(studyTemplateData) {
          for (var i = 0; i < this.studyTemplates.length; ++i) {
              if (this.studyTemplates[i].name === studyTemplateData.name) {
                  this.studyTemplates.splice(i, 1);
                  return Promise.resolve();
              }
          }
  
          return Promise.reject();
      },
  
      getStudyTemplateContent: function(studyTemplateData) {
          for (var i = 0; i < this.studyTemplates.length; ++i) {
              if (this.studyTemplates[i].name === studyTemplateData.name) {
                  return Promise.resolve(this.studyTemplates[i]);
              }
          }
  
          console.error('st: error');
  
          return Promise.reject();
      },
  
      saveStudyTemplate: function(studyTemplateData) {
       // console.log('exp data studyTemplateData' );
          for (var i = 0; i < this.studyTemplates.length; ++i) {
              if (this.studyTemplates[i].name === studyTemplateData.name) {
                  this.studyTemplates.splice(i, 1);
                  break;
              }
          }
  
          this.studyTemplates.push(studyTemplateData);
          return Promise.resolve();
      },
  
      getAllStudyTemplates: function() {
          return Promise.resolve(this.studyTemplates);
      },
  
      removeDrawingTemplate: function (toolName, templateName) {
          for (var i = 0; i < this.drawingTemplates.length; ++i) {
              if (this.drawingTemplates[i].name === templateName) {
                  this.drawingTemplates.splice(i, 1);
                  return Promise.resolve();
              }
          }
  
          return Promise.reject();
      },
  
      loadDrawingTemplate: function (toolName, templateName) {
          for (var i = 0; i < this.drawingTemplates.length; ++i) {
              if (this.drawingTemplates[i].name === templateName) {
                  return Promise.resolve(this.drawingTemplates[i].content);
              }
          }
  
          console.error('drawing: error');
  
          return Promise.reject();
      },
  
      saveDrawingTemplate: function (toolName, templateName, content) {
       // console.log('exp data saveDrawingTemplate' );
          for (var i = 0; i < this.drawingTemplates.length; ++i) {
              if (this.drawingTemplates[i].name === templateName) {
                  this.drawingTemplates.splice(i, 1);
                  break;
              }
          }
  
          this.drawingTemplates.push({ name: templateName, content: content });
          return Promise.resolve();
      },
  
      getDrawingTemplates: function () {
          return Promise.resolve(this.drawingTemplates.map(function(template) {
              return template.name;
          }));
      },
  }
    }
  }
  
  const tvWidget = new widget(widgetOptions);
  tvWidget.onChartReady(() => {
   // console.log('tvWidget=>',tvWidget)
   //tvWidget.addCustomCSSFile('../../../../../chart.css')
    tvWidget.headerReady().then(() => {
     
     // tvWidget.
      // const button = tvWidget.createButton();
      // button.setAttribute('title', 'Click to show a notification popup');
      // button.classList.add('apply-common-tooltip');
      // button.addEventListener('click', () => tvWidget.showNoticeDialog({
      //   title: 'Notification',
      //   body: 'TradingView Charting Library API works correctly',
      //   callback: () => {
      //     console.log('Noticed!');
      //   },
      // }));

      // button.innerHTML = 'Check API';
    });
  });
}
return (
    <section 
    className={`${styles.marketchart_l} latesthandle`}
    >
    <MiniMarket  miniData={miniData} dmode={dmode} popup={popup} setPopup={setPopup} extheme={extheme}/>
    <div ref={ tvChartRef } id="chartArea" className={styles.chart}>
    </div>
    </section>
  )
}

export default MarketChart