
import { parseFullSymbol } from './helpers.js';
import { webSocketUrl } from "../../../Helper/helper.js"

const { io } = require("socket.io-client");
const ws = new Object();
ws.current  = new WebSocket(webSocketUrl);
//const socket = io('wss://stream.binance.com:9443/ws');
const channelToSubscription = new Map();

const onOpenWebsocket = (evt) => {
	//console.log('ws onopen evt => ', evt);
}
function formatEventData(data){
	var d1=JSON.parse(data);
	if(d1.e && d1.e==='24hrTicker'){
		return {time:d1.E,open:d1.o,high:d1.h,low:d1.l,close:d1.c,volume:d1.v,symbol:d1.s}
	}
	return false
}
const onMessageWebsocket = (evt) => {
	//console.log("ticker chart =>",evt);
    if (evt.data && JSON.parse(evt.data).btrl) {
		//setReconnectConnection(false)
		const data = JSON.parse(evt.data).btrl;
		
		if (data) {
			if (Object.keys(data)[0] === "ticker") {
				const ticker = data.ticker;
				
				const channelString="BTRL_"+ticker.currency_symbol.toUpperCase()+"_"+ticker.market_symbol.toUpperCase();
				//console.log("ticker chart channelString 2=>",channelString);
				const subscriptionItem = channelToSubscription.get(channelString);
				if (subscriptionItem === undefined) {
					return;
				}
				const lastDailyBar = subscriptionItem.lastDailyBar;
				//console.log("lastDailyBar=>",Object.keys(lastDailyBar).length);
				if(typeof lastDailyBar !== 'undefined' && lastDailyBar !== null && Object.keys(lastDailyBar).length > 0 ){
					const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time);
					//if(nextDailyBarTime!==NaN){
					if(!isNaN(nextDailyBarTime)){
						//console.log("nextDailyBarTime=>",nextDailyBarTime);
						const tradePrice = parseFloat(ticker.close);
						let timestamp = new Date(ticker.time);
						const tradeTime = Math.floor(timestamp.getTime()); ;
						//console.log("tradeTime=>",tradeTime);
						let bar;
						if (tradeTime >= nextDailyBarTime) {
							bar = {
								time: nextDailyBarTime,
								open: tradePrice,
								high: tradePrice,
								low: tradePrice,
								close: tradePrice,
								volume: ticker.volume,
								
							};
						} else {
							
							bar = {
								...lastDailyBar,
								high: Math.max(lastDailyBar.high, tradePrice),
								low: Math.min(lastDailyBar.low, tradePrice),
								close: tradePrice,
								volume: ticker.volume,
							};
							//console.log('[socket] Update the latest bar by price', tradePrice);
						}
						subscriptionItem.lastDailyBar = bar;
						// send data to every subscriber of that symbol
						subscriptionItem.handlers.forEach(handler => handler.callback(bar));
	
					}

				}
		  	}
		}
	}
}
const onCloseWebsocket = (evt) => {
	//console.log('ws onclose evt => ', evt)
	//webSocketInit();
}
const onErrorWebsocket = (evt) => {
	//console.log('ws onerror evt => ', evt)
}
const webSocketInit = () => {
		//ws.current = new WebSocket(BINANCE_WEBSOCKET_ADDRESS);
		ws.current.onopen = onOpenWebsocket;
		ws.current.onmessage = onMessageWebsocket;
		ws.current.onclose = onCloseWebsocket;
		ws.current.onerror = onErrorWebsocket;
	
	// eslint-disable-next-line
};

function getNextDailyBarTime(barTime) {
	const date = new Date(barTime * 1000);
	date.setDate(date.getDate() + 1);
	return date.getTime() / 1000;
}
export function subscribeOnStream(
	symbolInfo,
	resolution,
	onRealtimeCallback,
	subscribeUID,
	onResetCacheNeededCallback,
	lastDailyBar,
) {
	const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
	//console.log("ticker chart channelString =>",parsedSymbol);
	const channelString = "BTRL_"+parsedSymbol.fromSymbol+"_"+parsedSymbol.toSymbol;
	//console.log("ticker chart channelString =>",channelString);

	webSocketInit();
	const handler = {
		id: subscribeUID,
		callback: onRealtimeCallback,
	};
	let subscriptionItem = channelToSubscription.get(channelString);
	subscriptionItem = {
		subscribeUID,
		resolution,
		lastDailyBar,
		handlers: [handler],
	};
	channelToSubscription.set(channelString, subscriptionItem);
	//ws.current?.send(channelString);
}

export function unsubscribeFromStream(subscriberUID) {
	// find a subscription with id === subscriberUID
	for (const channelString of channelToSubscription.keys()) {
		const subscriptionItem = channelToSubscription.get(channelString);
		const handlerIndex = subscriptionItem.handlers
			.findIndex(handler => handler.id === subscriberUID);

		if (handlerIndex !== -1) {
			// remove from handlers
			subscriptionItem.handlers.splice(handlerIndex, 1);
			if (subscriptionItem.handlers.length === 0) {
				// unsubscribe from the channel, if it was the last handler
				var mysubscribeUID=subscriberUID;
				channelToSubscription.delete(channelString);
				break;
			}
		}
	}
}
