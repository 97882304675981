import React, { useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
const siteKey = "6Lcfp98UAAAAABziyJiLnVGGv8JVsntezK67XicF";

const ReCaptcha = ({checkCaptcha , reset , setReset}) => {

    const recaptchaRef = React.createRef();
    const onChange = () =>{
        const recaptchaValue = recaptchaRef.current.getValue();
        checkCaptcha(recaptchaValue)
      }
const onExpired = () =>{
  recaptchaRef.current.reset()
}

useEffect(() => {
  if(reset && recaptchaRef){
    recaptchaRef.current.reset()
    setReset(false)
  }
}, [reset])

  return (
    <ReCAPTCHA
    ref={recaptchaRef}
    sitekey={siteKey}
    onExpired={onExpired}
    onChange={onChange}
  />
  )
}

export default ReCaptcha