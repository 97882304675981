// import axios from "axios";
import jwt_decode from "jwt-decode";
import swal from "sweetalert";

// export const apiUrl = "https://testingwaala.com/backend/api/";
// export const webSocketUrl = "wss://echo.testingwaala.com:8089/";
export const apiUrl = "https://btrlexchange.com/backend/api/";
export const webSocketUrl = "wss://echo.btrlexchange.com:8089/";
//export const apiUrl = "http://localhost/btrl-backend/api/";
// export const apiUrl = "http://test-btrlexchange-backend.local/api/";
// export const webSocketUrl = "ws://127.0.0.1:8089/";

export const refreshToken = async (_token) => {
  let token = `"${_token}"`;
  sessionStorage.setItem("user", token);
};
export const decodeToken = async (_token) => {
  const decoded = jwt_decode(_token);
  return decoded.st;
};



setInterval(() => {
  if (JSON.parse(sessionStorage.getItem("isLogin"))) {
    const token = JSON.parse(sessionStorage.getItem("user"));
    if (token === false) {
      swal("session expired  !");
      userFuncion(false);
      window.location.href = "/signin";
    } else {
      // console.log("token=>",token);
      // console.log("token=>",token);
      const decoded = jwt_decode(token);
      if (decoded.exp * 1000 <= Date.now()) {
        swal("session expired  !");
        userFuncion(false);
        window.location.href = "/signin";
        // logOut = 0;
        // window.localStorage.setItem("logOutCount", 0);
      }
    }
  }
}, 1000);



export const sendRequest = async (data, _req) => {
  var formBody = [];
  for (var property in data) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const resp = await fetch(apiUrl + _req, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization:
        sessionStorage.getItem("isLogin") === "true"
          ? `Bearer ${JSON.parse(sessionStorage.getItem("user"))}`
          : "",
      Cookie: "ci_session=9d575402035edfbb8df618ba3f668b47143df28b",
    },
    body: formBody,
  });
  return resp.json();
};


export const authRequestNb = async (_req) => {
  try {
    const resp = await fetch(apiUrl + _req, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("user"))}`,
        Cookie: "ci_session=9d575402035edfbb8df618ba3f668b47143df28b",
      },
    });
    return resp.json();
  } catch (err) {
    if (err) {
      console.log(err.message);
    }
  }
};

export const authRequest = async (data, _req) => {
  var formBody = [];
  for (var property in data) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  //console.log("formBody=>",formBody);
  //console.log("ses =>",JSON.parse(sessionStorage.getItem("user")));
  try {
    const resp = await fetch(apiUrl + _req, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("user"))}`,
        Cookie: "ci_session=9d575402035edfbb8df618ba3f668b47143df28b",
      },
      body: formBody,
    });
    return resp.json();
  } catch (err) {
    if (err) {
      console.log(err.message);
    }
  }
};

export const sendFileRequest = async (data, _req) => {
  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    `Bearer ${JSON.parse(sessionStorage.getItem("user"))}`
  );
  myHeaders.append(
    "Cookie",
    "ci_session=6777255e4b717ae7c32d79d8d3e04146ea6ae7b4"
  );

  var formdata = new FormData();

  for (let i = 0; i < Object.keys(data).length; i++) {
    const singleData = data[Object.keys(data)[i]];
    formdata.append(Object.keys(data)[i], singleData);
  }

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  const res = await fetch(`${apiUrl}${_req}`, requestOptions);
  return res.json();
};
export const sendFileRequestWithProgress = async (data, _req) => {
  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    `Bearer ${JSON.parse(sessionStorage.getItem("user"))}`
  );
  myHeaders.append(
    "Cookie",
    "ci_session=6777255e4b717ae7c32d79d8d3e04146ea6ae7b4"
  );

  var formdata = new FormData();

  for (let i = 0; i < Object.keys(data).length; i++) {
    const singleData = data[Object.keys(data)[i]];
    formdata.append(Object.keys(data)[i], singleData);
  }

  console.log(myHeaders, formdata, data);

  /* var requestOptions = {
    method: "post",
    url: `${apiUrl}${_req}`,
    headers: {
      Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("user"))}`,
      Cookie: "ci_session=6777255e4b717ae7c32d79d8d3e04146ea6ae7b4",
    },
    body: formdata,
    redirect: "follow",
  }; */

  // const res = await axios(requestOptions);
  // console.log(res)
  // return res;
};

export const sendGetRequest = async (_req) => {
  const resp = await fetch(apiUrl + _req, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization:
        sessionStorage.getItem("isLogin") === "true"
          ? `Bearer ${JSON.parse(sessionStorage.getItem("user"))}`
          : "",
      Cookie: "ci_session=9d575402035edfbb8df618ba3f668b47143df28b",
    },
  });
  return resp.json();
};

export const allLetter = (inputtxt) => {
  var letters = /^[a-zA-Z '.-]*$/;
  if (inputtxt.match(letters)) {
    return true;
  } else {
    return false;
  }
};

export const vpassword = (inputtxt) => {
  var letters =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{10,}$/;
  if (inputtxt.match(letters)) {
    return true;
  } else {
    return false;
  }
};
export const alphanumeric = (inputtxt) => {
  var letters = /^[A-Z0-9_]*$/;
  if (inputtxt.match(letters)) {
    return true;
  } else {
    return false;
  }
};
export const numeric = (inputtxt) => {
  var digits = /^[0-9]*$/;
  if (inputtxt.match(digits)) {
    return true;
  } else {
    return false;
  }
};

export const vpancard = (inputtxt) => {
  var letters = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
  if (inputtxt.match(letters)) {
    return true;
  } else {
    return false;
  }
};

export const vadhar = (inputtxt) => {
  if (inputtxt.length === 12) {
    return true;
  } else {
    return false;
  }
};

export const vphone = (inputtxt) => {
  var letters = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  if (inputtxt.match(letters)) {
    return true;
  } else {
    return false;
  }
};

export const vcpassword = (p, inputtxt) => {
  if (p) {
    if (vpassword(p) && p.indexOf(inputtxt) >= 0 && p === inputtxt) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const userLogin = (_value) => {
  _value === false && sessionStorage.setItem("user", "");
  sessionStorage.setItem("isLogin", _value);
};

export const userFuncion = (_value) => {
  _value === false && sessionStorage.setItem("user", "");
  sessionStorage.setItem("isLogin", _value);
  window.dispatchEvent(new Event("storage"));
};



export const shorter = (num) => {
  const firstAddressPart = shortener(num, 0, 6);
  const lastAddressPart = shortener(num, 36, 42);
  return `${firstAddressPart}...${lastAddressPart}`;
};

export const shortener = (_data, _start, _end) => {
  let result = "";
  for (let i = _start; i < _end; i++) result = [...result, _data[i]];
  return result.join("");
};

export const contactPhoneCheck = (_no) => {
  if (_no.length > 6) {
    return true;
  } else {
    return false;
  }
};

export const mandsCheck = (_text) => {
  var letters = /^[a-zA-Z0-9 .,@]+$/;
  if (_text.match(letters)) {
    return true;
  } else {
    return false;
  }
};

export const deposit_history_headers = [
  ["Reference No.", "reference_no"],
  ["UTR No.", "utr_no"],
  ["Currency", "currency_symbol"],
  ["Quantity", "amount"],
  ["Deposited By", "deposited_by"],
  ["Confirmation", "confirmation"],
  ["Status", "status"],
  ["Remark", "remark"],
  ["Time", "created_at"],
];

export const trade_history_headers = [
  ["Reference No.", "reference_no"],
  ["Market", "market_symbol"],
  ["Currency", "currency_symbol"],
  ["Type", "type"],
  ["Quantity/Executed", "qty"],
  ["Price", "price"],
  ["Total", "total_amount"],
  ["Status", "status"],
  ["Time", "created_at"],
];

export const open_order_history_headers = [
  ["Reference No.", "reference_no"],
  ["Market", "market_symbol"],
  ["Currency", "currency_symbol"],
  ["Type.", "type"],
  ["Quantity/Executed", "qty"],
  ["Price", "price"],
  ["Total", "total_amount"],
  ["Status", "status"],
  ["Time", "created_at"],
  //["Action", ""],
  ["Action", "action"],
];

export const withdraw_history_headers = [
  ["Reference No.", "reference_no"],
  ["Transaction Id", "transaction_id"],
  ["Address", "address"],
  ["Currency", "currency_symbol"],
  ["Quantity", "amount"],
  ["Fee", "fee"],
  ["Total", "total_amount"],
  ["Status", "status"],
  ["Type", "type"],
  ["Time", "created_at"],
];

let data = {};
let previousComparison = {};

export const isMax = (name, value) => {
  if (data.hasOwnProperty(name)) {
    if (data[name].length > 4) {
      data[name].shift();
    }
    let previousValue = data[name][data[name].length - 1];
    if (value > previousValue || value < previousValue) {
      let comparison = value > previousValue ? true : false;
      previousComparison[name] = comparison;
      data[name].push(value);
      return comparison;
    } else {
      return previousComparison[name];
    }
  } else {
    data[name] = [value];
    return true;
  }
};
//console.log("ues")
export const fixedNumber = (number, _length) => {
  if (number) {
    let str = number.toString();
    let decimalIndex = str.indexOf(".");
    if (str.split(".")[0].length >= _length) {
      return parseFloat(str).toFixed(2);
    } else {
      if (decimalIndex === -1) {
        str = str + ".00000000000000000000000";
      } else {
        str = str + "00000000000000000000000";
      }

      str = str.slice(0, _length);

      if (str[str.length - 1] === ".") {
        return str.slice(0, _length - 1);
      } else {
        return str;
      }
    }
  }
};

export const trade = async(
  order,
  buyData,
  setBuy,
  sellData,
  setSell,
  type = "ws"
) => {

  let buy = buyData.map((obj) => {
    Object.keys(obj).forEach((key) => {
      obj[key] = parseFloat(obj[key]);
    });
    delete obj["operation"] 
    delete obj["type"] 
    return obj;
  });

  let sell = sellData.map((obj) => {
    Object.keys(obj).forEach((key) => {
      obj[key] = parseFloat(obj[key]);
    });
    delete obj["operation"] 
    delete obj["type"] 
    return obj;
  });

  const edit = async (newObject, old, get, set, buy) => {
    const change = get.map((obj) => {
      if (obj === old) {
        return newObject;
      } else {
        return obj;
      }
    });
   
    await add(false, change, set, buy);
  };

  const remove =async (toRemove, get, set, buy) => {
   
    let index = get.findIndex((obj) => obj === toRemove);
  
    delete get[index];
    await add(false, get, set, buy,true);
  };

  const add = async (addObject, get, set, buy,ch=null) => {
  
    const _newtemp=get.filter(item => {
     return Object.keys(item).length !== 0;
 });
       const _temp = addObject ? [addObject, ..._newtemp] : _newtemp;
      
       let temp = _temp.reduce(function (acc, obj) {
         let existingObj = acc.find(function (existingObj) {
          
           return existingObj.bid_price === obj.bid_price;
         });
         if (existingObj) {
           existingObj.qty += obj.qty;
           existingObj.total += obj.total;
         } else {
           acc.push(obj);
         }
         return acc;
       }, []);
       if (buy) {
         set(temp.sort((a, b) => (a.bid_price < b.bid_price ? 1 : -1)));
       } else {
         set(temp.sort((a, b) => (a.bid_price > b.bid_price ? 1 : -1)));
       }
      
  };

  
  sell = sell.filter((item) => !(item === undefined || Object.keys(item).length === 0));
  buy = buy.filter((item) => !(item === undefined || Object.keys(item).length === 0));
  

  if (order.buyTrade) {
    // when buy click
    let index =
    sell?.length !== 0
      ? sell?.findIndex((obj) => obj.bid_price <= order.price)
      : -1;
      let buyindex=
      buy?.length !== 0
      ? buy?.findIndex((obj) => obj.bid_price >= order.price)
      : -1;
    if(order.operation==="Add"){
      await add({
          bid_price: order.price,
          qty: order.amount,
          total: order.amount * order.price,
        },
        buy,
        setBuy,
        true
      );
  
    }else if(order.operation==="Minus"){
      if (index >= 0 && sell[index]) {
        if (fixedNumber(sell[index].qty,12) <= fixedNumber(order.amount,12)) {
          const newOr = order.amount - sell[index].qty;
          await remove(sell[index], sell, setSell, false);

        
          
        }
        else{
          await edit(
            {
              bid_price: sell[index].bid_price,
              qty: sell[index].qty - order.amount,
              total: (sell[index].qty - order.amount) * order.price,
            },
            sell[index],
            sell,
            setSell,
            false
          );
        }
      }
      if (buyindex >= 0 && buy[buyindex]) {
            if (fixedNumber(buy[buyindex].qty,12) <= fixedNumber(order.amount,12)) {
              await remove(buy[buyindex], buy, setBuy, true);
            }
          }else{

            await edit(
              {
                bid_price: order.price,
                qty: buy[buyindex].qty - order.amount,
                total: (buy[buyindex].qty - order.amount) * order.price,
              },
              buy[buyindex],
              buy,
              setBuy,
              true
            );
          }
    }
 
      
  } else {
    // when sell click
    let index =
      buy?.length !== 0
        ? buy?.findIndex((obj) => obj.bid_price >= order.price)
        : -1;
        let sellindex =
        sell?.length !== 0
        ? sell?.findIndex((obj) => obj.bid_price <= order.price)
        : -1;
        if(order.operation==="Add"){
          await add(
            {
              bid_price: order.price,
              qty: order.amount,
              total: order.amount * order.price,
            },
            sell,
            setSell,
            false
          );
        }else if(order.operation==="Minus"){
          if (index >= 0 && buy[index]) {
            if (fixedNumber(buy[index].qty,12) <= fixedNumber(order.amount,12)) {
              const newOr = order.amount - buy[index].qty;
              await remove(buy[index], buy, setBuy, true);
             
            
              
            } else{
              await edit(
                {
                  bid_price: buy[index].bid_price,
                  qty: buy[index].qty - order.amount,
                  total: (buy[index].qty - order.amount) * order.price,
                },
                buy[index],
                buy,
                setBuy,
                true
              );
            }
         }
          if (sellindex >= 0 && sell[sellindex]) {
                if (fixedNumber(sell[sellindex].qty,12) <= fixedNumber(order.amount,12)) {
                await remove(sell[sellindex], sell, setSell, false);
                }
              }
              else{
                await edit(
                  {
                    bid_price: order.price,
                    qty: sell[sellindex].qty - order.amount,
                    total: (sell[sellindex].qty - order.amount) * order.price,
                  },
                  sell[sellindex],
                  sell,
                  setSell,
                  false
                );
              }

       }
    
  }
  
};
export const trade12 = async(
  order,
  buyData,
  setBuy,
  sellData,
  setSell,
  type = "ws"
) => {
  //console.log("ws order=>",order );
  //console.log("ws order=>",order );
  //console.log("ws buyData=>",buyData );
  //console.log("ws setBuy=>",setBuy );
  //console.log("ws sellData=>",sellData );
  //console.log("ws setSell=>",setSell );
  let buy = buyData.map((obj) => {
    Object.keys(obj).forEach((key) => {
      obj[key] = parseFloat(obj[key]);
    });
    return obj;
  });

  let sell = sellData.map((obj) => {
    Object.keys(obj).forEach((key) => {
      obj[key] = parseFloat(obj[key]);
    });
    return obj;
  });

  const edit = async (newObject, old, get, set, buy) => {
    const change = get.map((obj) => {
      if (obj === old) {
        return newObject;
      } else {
        return obj;
      }
    });
    //console.log("change",change);
    await add(false, change, set, buy);
  };

  const remove =async (toRemove, get, set, buy) => {
    //console.log("toRemove=>",toRemove);
    // console.log("get=>",get);
    //console.log("set=>",set);
    //console.log("buy=>",buy);
    let index = get.findIndex((obj) => obj === toRemove);
    //console.log("index",index);
    // get.splice(index,1);
    delete get[index];
    await add(false, get, set, buy);
  };

  const add = async (addObject, get, set, buy) => {
    //  console.log("get=>",get);
    // console.log("set=>",set);
    // console.log("buy=>",buy);
     console.log("addObject=>",addObject);
    
       const _temp = addObject ? [addObject, ...get] : get;
   
       let temp = _temp.reduce(function (acc, obj) {
         let existingObj = acc.find(function (existingObj) {
           return existingObj.bid_price === obj.bid_price;
         });
         if (existingObj) {
           existingObj.qty += obj.qty;
           existingObj.total += obj.total;
         } else {
           acc.push(obj);
         }
         return acc;
       }, []);
       if (buy) {
         set(temp.sort((a, b) => (a.bid_price < b.bid_price ? 1 : -1)));
       } else {
         set(temp.sort((a, b) => (a.bid_price > b.bid_price ? 1 : -1)));
       }
      
  };

  //sell = sell.filter((item) => Object.keys(item).length > 0);
  //sell = sell.filter((item) => !(item === undefined || Object.keys(item).length===0));
  //buy = buy.filter((item) => Object.keys(item).length > 0);
  //buy = buy.filter((item) => !(item === undefined || Object.keys(item).length===0));
  //sell = sell.filter((item) => !(item === undefined || item === {}));
  //buy = buy.filter((item) => !(item === undefined || item === {}));
  sell = sell.filter((item) => !(item === undefined || Object.keys(item).length === 0));
  buy = buy.filter((item) => !(item === undefined || Object.keys(item).length === 0));
  //console.log("sell",sell);
  //console.log("buy",buy);
  //console.log("Order" , order)

  if (order.buyTrade) {
    // when buy click
    let index =
      sell?.length !== 0
        ? sell?.findIndex((obj) => obj.bid_price <= order.price)
        : -1;
    
      

          if (index >= 0 && sell[index]) {
            if (fixedNumber(sell[index].qty,12) === fixedNumber(order.amount,12)) {
              await remove(sell[index], sell, setSell, false);
            } else if (sell[index].qty > order.amount) {
             // console.log("sell[index].qty > order.amount")
              await edit(
                {
                  qty: sell[index].qty - order.amount,
                  bid_price: sell[index].bid_price,
                  total: (sell[index].qty - order.amount) * order.price,
                  operation:order.operation
                },
                sell[index],
                sell,
                setSell,
                false
              );
            } else if (sell[index].qty < order.amount) {
              //const newOr =  fixedNumber(order.amount - sell[index].qty,12);
              const newOr =  (order.amount - sell[index].qty);
              console.log("buy type true order",order)
              // console.log("buy newOr*order.price",newOr*order.price)
              await remove(sell[index], sell, setSell, false);
              //,operation:"Add",operation:"Add"
              const _order = { ...order, amount: newOr, buyTrade: true};
              console.log("buy type true _order",_order)
              //await trade(_order, buy, setBuy, sell, setSell);
              //await add(_order, buy, setBuy, sell);
            }
            //else if(buy[index].qty < order.amount){
          
          } else {
           //if(order.operation==="Add"){
              await add({
                  qty: order.amount,
                  bid_price: order.price,
                  total: order.amount * order.price,
                  operation:order.operation
                },
                buy,
                setBuy,
                true
              );
          
            //}
          }
      
  } else {
    // when sell click
    let index =
      buy?.length !== 0
        ? buy?.findIndex((obj) => obj.bid_price >= order.price)
        : -1;
   
    if (index >= 0 && buy[index]) {
      if (fixedNumber(buy[index].qty,12) === fixedNumber(order.amount,12)) {
        await remove(buy[index], buy, setBuy, true);
      } else if (buy[index].qty > order.amount) {
        console.log("buy[index].qty > order.amount")
        await edit(
          {
            qty: buy[index].qty - order.amount,
            bid_price: buy[index].bid_price,
            total: (buy[index].qty - order.amount) * order.price,
            operation:order.operation
          },
          buy[index],
          buy,
          setBuy,
          true
        );
      } else if (buy[index].qty < order.amount) {
        //fixedNumber = (number, _length)
        //const newOr = fixedNumber(order.amount - buy[index].qty,12);
        const newOr = (order.amount - buy[index].qty);
        await remove(buy[index], buy, setBuy, true);
       // console.log("sell newOr",newOr)
        //console.log("sell newOr*order.price",newOr*order.price)
        console.log("buy type false order",order)
        //false,operation:"Add",operation:"Add" 
        const _order = { ...order, amount: newOr, buyTrade: false};
        console.log("buy type false _order",_order)
       //  await trade(_order, buy, setBuy, sell, setSell);
        //(addObject, get, set, buy)
        //await add(_order, sell, setSell, false);
      }
      
    } else {
      //if(order.operation!=="Minus"){
       // if(order.operation==="Add"){
          await add(
            {
              qty: order.amount,
              bid_price: order.price,
              total: order.amount * order.price,
              operation:order.operation
            },
            sell,
            setSell,
            false
          );
       // }

      //}
    }
  }
  
};
export const trade1_notworkin = (
  order,
  buyData,
  setBuy,
  sellData,
  setSell,
  type = "ws"
) => {
  //console.log("ws order=>",order );
  let buy = buyData.map((obj) => {
    Object.keys(obj).forEach((key) => {
      obj[key] = parseFloat(obj[key]);
    });
    return obj;
  });

  let sell = sellData.map((obj) => {
    Object.keys(obj).forEach((key) => {
      obj[key] = parseFloat(obj[key]);
    });
    return obj;
  });
  const add = (addObject, get, set, buy) => {
    const _temp = addObject ? [addObject, ...get] : get;

    let temp = _temp.reduce(function (acc, obj) {
      let existingObj = acc.find(function (existingObj) {
        return existingObj.bid_price === obj.bid_price;
      });
      if (existingObj) {
        existingObj.qty += obj.qty;
        existingObj.total += obj.total;
      } else {
        acc.push(obj);
      }
      return acc;
    }, []);
    if (buy) {
      set(temp.sort((a, b) => (a.bid_price < b.bid_price ? 1 : -1)));
    } else {
      set(temp.sort((a, b) => (a.bid_price > b.bid_price ? 1 : -1)));
    }
  };
  const edit = (newObject, old, get, set, buy) => {
    const change = get.map((obj) => {
      if (obj === old) {
        return newObject;
      } else {
        return obj;
      }
    });

    add(false, change, set, buy);
  };

  const remove = (toRemove, get, set, buy) => {
    let index = get.findIndex((obj) => obj === toRemove);
    delete get[index];
    add(false, get, set, buy);
  };
  if (order.buyTrade) {
    // when buy click
    let index =
      sell?.length !== 0
        ? sell?.findIndex((obj) => obj.bid_price <= order.price)
        : -1;
    if (order.operation === "Add") {
      add(
        {
          qty: order.amount,
          bid_price: order.price,
          total: order.amount * order.price,
        },
        buy,
        setBuy,
        true
      );
    } else if (order.operation === "Minus") {
      //console.log("index sell ",index)
      if (sell[index]) {
        // console.log("sell[index].qty ",sell[index].qty)
        // console.log("order.amount ",order.amount)
        if (sell[index].qty === order.amount) {
          remove(sell[index], sell, setSell, false);
        } else if (sell[index].qty > order.amount) {
          edit(
            {
              qty: sell[index].qty - order.amount,
              bid_price: sell[index].bid_price,
              total: (sell[index].qty - order.amount) * order.price,
            },
            sell[index],
            sell,
            setSell,
            false
          );
        } else if (sell[index].qty < order.amount) {
          //const _order = {...order , amount: order.amount - sell[index].qty, buyTrade: true,order.operation:"Add"}
          //remove(sell[index] , sell , setSell , false)
          // trade(_order , buy, setBuy, sell, setSell)
          remove(buy[index], buy, setSell, true);
          add(
            {
              qty: order.amount,
              bid_price: order.price,
              total: order.amount * order.price,
            },
            buy,
            setBuy,
            false
          );
        }
      }
    }
  } else {
    // when Sell click
    let index =
      buy?.length !== 0
        ? buy?.findIndex((obj) => obj.bid_price >= order.price)
        : -1;
    //  console.log("order. sell op ",order.operation)
    //  console.log("order. sell price ",order.price)
    //  console.log("order. sell amount ",order.amount)
    if (order.operation === "Add") {
      add(
        {
          qty: order.amount,
          bid_price: order.price,
          total: order.amount * order.price,
        },
        sell,
        setSell,
        false
      );
    } else if (order.operation === "Minus") {
      if (buy[index]) {
        //console.log("buy[index].qty ",buy[index].qty)
        // console.log("order.amount ",order.amount)
        if (buy[index].qty === order.amount) {
          remove(buy[index], buy, setBuy, true);
        } else if (buy[index].qty > order.amount) {
          edit(
            {
              qty: buy[index].qty - order.amount,
              bid_price: buy[index].bid_price,
              total: (buy[index].qty - order.amount) * order.price,
            },
            buy[index],
            buy,
            setBuy,
            true
          );
        } else if (buy[index].qty < order.amount) {
          //const _order = {...order , amount: order.amount - buy[index].qty, buyTrade: false};
          remove(buy[index], buy, setBuy, true);
          add(
            {
              qty: order.amount,
              bid_price: order.price,
              total: order.amount * order.price,
            },
            buy,
            setBuy,
            false
          );
          //trade(_order , buy, setBuy, sell, setSell)
        }
      }
    }
  }
};
export const trade_old = async (
  order,
  buyData,
  setBuy,
  sellData,
  setSell,
  type = "ws"
) => {
  //console.log("ws order=>",order );
  //console.log("ws buyData=>",buyData );
  //console.log("ws setBuy=>",setBuy );
  //console.log("ws sellData=>",sellData );
  //console.log("ws setSell=>",setSell );
  let buy = buyData.map((obj) => {
    Object.keys(obj).forEach((key) => {
      obj[key] = parseFloat(obj[key]);
    });
    return obj;
  });

  let sell = sellData.map((obj) => {
    Object.keys(obj).forEach((key) => {
      obj[key] = parseFloat(obj[key]);
    });
    return obj;
  });

  const edit = async (newObject, old, get, set, buy) => {
    const change = get.map((obj) => {
      if (obj === old) {
        return newObject;
      } else {
        return obj;
      }
    });

    add(false, change, set, buy);
  };

  const remove = async (toRemove, get, set, buy) => {
    //console.log("toRemove=>",toRemove);
    //console.log("get=>",get);
    //console.log("set=>",set);
    //console.log("buy=>",buy);
    let index = get.findIndex((obj) => obj === toRemove);
    delete get[index];
    add(false, get, set, buy);
  };

  const add = async (addObject, get, set, buy) => {
    const _temp = addObject ? [addObject, ...get] : get;

    let temp = _temp.reduce(function (acc, obj) {
      let existingObj = acc.find(function (existingObj) {
        return existingObj.bid_price === obj.bid_price;
      });
      if (existingObj) {
        existingObj.qty += obj.qty;
        existingObj.total += obj.total;
      } else {
        acc.push(obj);
      }
      return acc;
    }, []);
    if (buy) {
      set(temp.sort((a, b) => (a.bid_price < b.bid_price ? 1 : -1)));
    } else {
      set(temp.sort((a, b) => (a.bid_price > b.bid_price ? 1 : -1)));
    }
  };

  //sell = sell.filter((item) => Object.keys(item).length > 0);
  //buy = buy.filter((item) => Object.keys(item).length > 0);
  sell = sell.filter((item) => !(item === undefined || Object.keys(item).length===0));
  buy = buy.filter((item) => !(item === undefined || Object.keys(item).length===0));
  // sell = sell.filter((item) => !(item === undefined || item === {}));
  // buy = buy.filter((item) => !(item === undefined || item === {}));

  //console.log("Order" , order)

  if (order.buyTrade) {
    // when buy click
    let index =
      sell?.length !== 0
        ? sell?.findIndex((obj) => obj.bid_price <= order.price)
        : -1;
    //console.log("index=>",index);
    //console.log("order.price=>",order.amount);
    //console.log("order.price=>",order.amount);


    if (index >= 0 && sell[index]) {
      if (sell[index].qty === order.amount) {
        await remove(sell[index], sell, setSell, false);
      } else if (sell[index].qty > order.amount) {
        await edit(
          {
            qty: sell[index].qty - order.amount,
            bid_price: sell[index].bid_price,
            total: (sell[index].qty - order.amount) * order.price,
          },
          sell[index],
          sell,
          setSell,
          false
        );
      } else if (sell[index].qty < order.amount) {
        const newOr = order.amount - sell[index].qty;
        await remove(sell[index], sell, setSell, false);
        const _order = { ...order, amount: newOr, buyTrade: true };
        await trade(_order, buy, setBuy, sell, setSell);
      }
    } else {
      if(order.operation==="Add"){
      await add(
        {
          qty: order.amount,
          bid_price: order.price,
          total: order.amount * order.price,
        },
        buy,
        setBuy,
        true
      );
      }
    }
  } else {
    // when sell click
    let index =
      buy?.length !== 0
        ? buy?.findIndex((obj) => obj.bid_price >= order.price)
        : -1;
    if (index >= 0 && buy[index]) {
      if (buy[index].qty === order.amount) {
        await remove(buy[index], buy, setBuy, true);
      } else if (buy[index].qty > order.amount) {
        await edit(
          {
            qty: buy[index].qty - order.amount,
            bid_price: buy[index].bid_price,
            total: (buy[index].qty - order.amount) * order.price,
          },
          buy[index],
          buy,
          setBuy,
          true
        );
      } else if (buy[index].qty < order.amount) {
        const newOr = order.amount - buy[index].qty;
        await remove(buy[index], buy, setBuy, true);
        const _order = { ...order, amount: newOr, buyTrade: false };
        await trade(_order, buy, setBuy, sell, setSell);
       
      }
    } else {
     
      await add(
        {
          qty: order.amount,
          bid_price: order.price,
          total: order.amount * order.price,
        },
        sell,
        setSell,
        false
      );
     
    }
  }
};

export const cancleOrderHandler = (
  order,
  buyData,
  setBuy,
  sellData,
  setSell
) => {
  if (order && buyData && sellData) {
    let buy = buyData.map((obj) => {
      Object.keys(obj).forEach((key) => {
        obj[key] = parseFloat(obj[key]);
      });
      return obj;
    });

    let sell = sellData.map((obj) => {
      Object.keys(obj).forEach((key) => {
        obj[key] = parseFloat(obj[key]);
      });
      return obj;
    });

    const edit = (newObject, old, get, set, buy) => {
      const change = get.map((obj) => {
        if (obj === old) {
          return newObject;
        } else {
          return obj;
        }
      });

      add(false, change, set, buy);
    };

    const remove = (_price, get, set, buy) => {
      let index = get.findIndex((obj) => obj.bid_price === _price);
      delete get[index];
      add(false, get, set, buy);
    };

    const add = (addObject, get, set, buy) => {
      const _temp = addObject ? [addObject, ...get] : get;

      let temp = _temp.reduce(function (acc, obj) {
        let existingObj = acc.find(function (existingObj) {
          return existingObj.bid_price === obj.bid_price;
        });
        if (existingObj) {
          existingObj.qty += obj.qty;
          existingObj.total += obj.total;
        } else {
          acc.push(obj);
        }
        return acc;
      }, []);
      if (buy) {
        set(temp.sort((a, b) => (a.bid_price < b.bid_price ? 1 : -1)));
      } else {
        set(temp.sort((a, b) => (a.bid_price > b.bid_price ? 1 : -1)));
      }
    };

    // sell = sell.filter((item) => !(item === undefined || item === {}));
    // buy = buy.filter((item) => !(item === undefined || item === {}));
    //sell = sell.filter((item) => Object.keys(item).length > 0);
    //buy = buy.filter((item) => Object.keys(item).length > 0);
    
     sell = sell.filter((item) => !(item === undefined || Object.keys(item).length===0));
     buy = buy.filter((item) => !(item === undefined || Object.keys(item).length===0));

    // console.log("Order" , order)
    // {bid_price: '9', qty: '50', total: '450'} sample data
    // {market: 'inr', currency: 'btrl', price: 10, amount: 50, buyTrade: true} sample order

    if (order.buyTrade) {
      // when buy click
      let index =
        buy?.length !== 0
          ? buy?.findIndex((obj) => obj.bid_price === order.price)
          : -1;
      if (buy[index]) {
        const leftValue = buy[index].qty - order.amount;
        if (leftValue <= 0) {
          remove(order.price, buy, setBuy, true);
        } else {
          edit(
            { ...buy[index], qty: leftValue },
            buy[index],
            buy,
            setBuy,
            true
          );
        }
      }
    } else {
      // when sell click
      let index =
        sell?.length !== 0
          ? sell?.findIndex((obj) => obj.bid_price === order.price)
          : -1;
      if (sell[index]) {
        const leftValue = sell[index].qty - order.amount;
        if (leftValue <= 0) {
          remove(order.price, sell, setSell, false);
        } else {
          edit(
            { ...sell[index], qty: leftValue },
            sell[index],
            sell,
            setSell,
            false
          );
        }
      }
    }
  }
};

export const usernoCheck = (no) => {
  const userData=sessionStorage.getItem("user");
  //console.log("userData=>",userData);
  if(userData){
    const decoded = jwt_decode(userData);
    return decoded.user === no;
  }
  else
  {
    return false;
  }
};

export const openOrderDataHandler = (_old, current, set,totalRecord,setTotalRecord) => {
 // console.log("old=>",_old);
  //console.log("current=>",current);
 // console.log("set=>",set);
  if (_old) {
    // const filteredArray = _old.filter((item) => Object.keys(item).length > 0);
    // console.log(filteredArray);

    //const old = _old.filter((item) => !(item === undefined || item === {}));
    const old = _old.filter((item) => !(item === undefined || Object.keys(item).length===0));
   //const old = _old.filter((item) => Object.keys(item).length > 0);
    const index = old?.findIndex(
      (obj) => parseInt(obj.tradeID) === parseInt(current.tradeID)
    );
    // console.log("index=>",index);
    //// console.log("old[index]=>",old[index]);
    if (old[index]) {
     // console.log("old[index]=> here");
      if (current.remaining === 0) {
        set(
          old.filter(
            (obj) => parseInt(obj.tradeID) !== parseInt(current.tradeID)
          )
          );
          setTotalRecord(totalRecord>0?totalRecord-1:totalRecord)
      } else {
        old[index] = current;
        set(old);
        setTotalRecord(totalRecord);
      }
    } else {
      //console.log("old[index] else => here");
      current.remaining !== 0 && set([current, ...old]);
      current.remaining !== 0 && setTotalRecord(totalRecord+1);
      
    }
  } else {
    current.remaining !== 0 && set([current, ..._old]);
    current.remaining !== 0 && setTotalRecord(totalRecord+1);
  }
};
  
export const toFixed = (n, fixed) =>
  `${n}`.match(new RegExp(`^-?\\d+(?:\.\\d{0,${fixed}})?`))[0];

