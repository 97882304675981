import React, { useContext, useState, useEffect } from "react";
import Footer from "../../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../../Components/HomeComponents/Navbar/Navbar";
import Context from "../../../Context/Context";
import wallet_i from "../../../Assets/wallet_login_register.png";
import styles from "./PersonalInfo.module.scss";
import { allLetter, authRequest, authRequestNb,sendFileRequest,refreshToken } from "../../../Helper/helper";
import swal from "sweetalert";
//"/latestimages/rankuser.png";
// import {personalImage} from "../../../../public/latestimages/rankuser.png";

const PersonalInfo = () => {
  const [checkS, setCheckS] = useState();
  const [data, setData] = useState();
  const { states } = useContext(Context);
  const [formData, setFormData] = useState({});
  const [profileImage, setProfileImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState({});
  const [allClear, setAllClear] = useState(false);
  const dmode = states.dmode;
  const formDataSet = (key, val) => {
    let change = formData;
    change[key] = val;
    setFormData({ ...change });
  };
  const errorMessageSet = (key, val) => {
    let change = errorMessage;
    change[key] = val;
    setErrorMessage({ ...change });
  };
  const formFillCheck = () => {
    (formData.name === "" || formData.name === undefined) &&
      errorMessageSet("name", "Please enter your name");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formFillCheck();
   
      if (allClear) {
        const res = await sendFileRequest(
          {
            profile_image: formData.profile_image,
          },
          "submit-personal-info"
        );
        // const res = await authRequest(
        //   {
        //     profile_image: formData.profile_image,
        //   },
        //   "submit-personal-info"
        // );
        const _swal = await swal(res.message);
        _swal && (await autoGet());
      }
    
  };

  const autoGet = async () => {
    const res = await authRequestNb("get-personal-info");
    setData(res.data);
    setCheckS(res.data.status);
    formDataSet("name", res.data.name);
    formDataSet("email", res.data.email);
    formDataSet("phone", res.data.mobile);
    refreshToken(res.refresh_token);
    if(res.data.profile_image){

      setProfileImage(res.data.path+res.data.profile_image)
    }
    //formDataSet("profile_image", res.data.profile_image);
  };

  useEffect(() => {
    (async () => {
      await autoGet();
    })();
  }, []);

  useEffect(() => {
    if (formData.name) {
      allLetter(formData.name) === false &&
        errorMessageSet("name", "Only use alphabet");
      setAllClear(false);
      if (allLetter(formData.name)) {
        setAllClear(true);
        errorMessageSet("name", "");
      }
    } else {
      setAllClear(false);
    }
  }, [formData]);
  const handleImageUpload = async(e)=>{
    //const file = e.files[0];
    const file = e.target.files[0];
    console.log("file",file)
    if (file) {
      formDataSet("profile_image", file);
      const reader = new FileReader();
      reader.onload = () => {
        document.querySelector('.urank').src = reader.result; 
      };
      reader.readAsDataURL(file);
    }
  }
  return (
    <div className={dmode ? `${styles.pers_d} ligtModeExchange signPage`: `${styles.pers_l} ligtModeExchange signPage`}>
      <Navbar />
      <div className={`${styles._pers} container`}>
        <div className="row w-100 justify-content-center">
          <div className="col-sm-8">
            <form onSubmit={handleSubmit} className="flex-start column">
              <div className="text-center w-100">
              <h2 className="text-center">My Account</h2>
              
              <div className={`${styles.profileImageWrapper} text-center position-relative`}>
                <img src={profileImage ? profileImage : "/user.jpg"} className={`${styles.rankuser} urank`} />
                
                {/* <img src="/latestimages/rankuser.png" className={`${styles.rankuser}`} />
                */}
                <label className={`${styles.editIconWrapper}`}>
                  <input 
                    type="file" 
                    className={styles.fileInput} 
                    onChange={(e) => handleImageUpload(e)} 
                  />
                  <i className={`fa-solid fa-arrow-up-from-bracket ${styles.editIcon}`}></i>
                  {/* <img src="/latestimages/upload-icon.png" className={styles.editIcon} alt="Edit" /> */}
                </label>
                {/* <i className={`fa fa-pencil ${styles.editIcon}`} aria-hidden="true"></i> */}
              </div>
            </div>
              {/* <div className={` text-center`}>
              <div className=" text-center">
                  <img src="/latestimages/rankuser.png" className=" rankuser" />
                </div>
              </div> */}
              <div className={`${styles.oninput} flex-between`}>
                <input
                  onChange={(e) => formDataSet("name", e.target.value)}
                  type="text"
                  readOnly={true}
                  defaultValue={data && data.name}
                  placeholder="Your Full Name"
                />
                {errorMessage.name && (
                  <p className={styles.phoneWarning}>{errorMessage.name}</p>
                )}
                </div> 
                <div className={`${styles.oninput} flex-between`}>
                <input
                  type="email"
                  defaultValue={data && data.email}
                  readOnly={true}
                  placeholder="Email"
                />
              </div>
              {errorMessage.email && (
                <p className={styles.warning_note_temp}>{errorMessage.email}</p>
              )}
              <div className={`${styles.oninput} flex-between`}>
                <input
                  type="text"
                  defaultValue={data && data.mobile}
                  readOnly={true}
                  placeholder="Mobile"
                />
              </div>
              <button type="submit" className={`${styles.pers_btn} btn`}>
                Submit
              </button>
              
            </form>
          </div>
        </div>

        {/* <img className={styles.bgi} src={wallet_i} alt="wallet" /> */}
      </div>

      <Footer />
    </div>
  );
};

export default PersonalInfo;
