import React, { useState, useEffect } from "react";
import { fixedNumber, } from "../../../Helper/helper";
import styles from "./SellOrder.module.scss";

const SellOrderChart = ({ sdata, trade , setBuyTrade , dmode, buyTrade, setBid,sellTrade,
  setSellTrade }) => {
  const [ssize, setSsize] = useState(window.innerWidth);


  const smartTrade = (value) => {
    //console.log("value=>",value);
    //value.type="Sell";
    value.type="Buy";
    if(buyTrade){
      setBid(value)
    }else{
      setBuyTrade(true)
      setTimeout(() => {
        setBid(value)
      }, 300);
    }
  }

  useEffect(() => {
    (async () => {
      window.addEventListener("resize", () => setSsize(window.innerWidth));
    })();
  }, [trade]);
  return (
    <section
      style={{
        display: !buyTrade && ssize <= 750 ? "none" : "block",
        gridArea: !buyTrade && ssize <= 1050 && ssize > 750 ? "3/1/4/2" : "",
      }}
      
      className={`${styles.soc_l} latesthandle`}
    >
      <h1 className={styles.title}>Sell Order</h1>
      <div className='custableresponsive table-responsive custableresponsive_height mt-15 buysellOrder'>
      <table className={styles.c_table}>
        <thead className='custhead'>
        <tr>
          <th>
            <div>Amount<span>({trade?.currency_symbol})</span></div>
          </th>
          <th>
            <div>Price<span>({trade?.market_symbol})</span></div>
          </th>
          <th>
            <div>Total<span>({trade?.market_symbol})</span></div>
          </th>
        </tr>
      </thead>
        <tbody className={styles.tbody}>
          {sdata &&
            sdata?.map((selld, i) => (
              <tr
                onClick={() => smartTrade(selld)}
                key={i}
                className={styles.tr}
              >
                <td>{fixedNumber(selld.qty, 12)}</td>
                <td className={`${styles.price} textdanger`}>
                  {fixedNumber(selld.bid_price, 12)}
                </td>
                <td>{fixedNumber(selld.total, 12)}</td>
              </tr>
            ))}
        </tbody>
      </table>
      </div>
    </section>
  );
};

export default SellOrderChart;
