import React, { useContext, useState, useEffect } from "react";
import Context from "../../../Context/Context";
import styles from "./PaginationBox.module.scss";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";

// const Pages = JSON.parse(localStorage.getItem("Pages")) ? JSON.parse(localStorage.getItem("Pages")) : null;
// Pages === null  && localStorage.setItem("Pages" , JSON.stringify({deposit_history: 1, trade_history: 1, withdraw_history: 1, open_order_history: 1}))

const PaginationBox = ({
  totalRecords,
  currentPage,
  setCurrentPage,
}) => {
  // const newAllPagesData = allPagesData;
  // // localStorage.setItem("ExPages", JSON.stringify(allPagesData))
  // setCurrentPage(newAllPagesData);
  const { states } = useContext(Context);
  const dmode = states.dmode;
  const totalPage = Math.ceil(totalRecords / 10);

  return (
    <div
      className={dmode ? styles.page_d : styles.page_l}
      style={{ marginRight: "20px" }}
    >
      <button
        onClick={() => setCurrentPage(1)}
        className={currentPage === 1 ? "" : styles.active}
      >
        First
      </button>
      <button
        onClick={() =>
          currentPage !== 1
            ? setCurrentPage(currentPage - 1)
            : setCurrentPage(1)
        }
        className={`${currentPage === 1 ? "" : styles.active
          } flex-center`}
      >
        <AiOutlineLeft />
      </button>
      <button>
        Page {currentPage} of {totalPage} <br />
        Total Records: {totalRecords}
      </button>
      <button
        onClick={() =>
          currentPage < totalPage
            ? setCurrentPage(currentPage + 1)
            : setCurrentPage(totalPage)
        }
        className={`${currentPage === totalPage ? "" : styles.active
          } flex-center`}
      >
        <AiOutlineRight />
      </button>
      <button
        onClick={() => setCurrentPage(totalPage)}
        className={currentPage === totalPage ? "" : styles.active}
      >
        Last
      </button>
    </div>
  );
};

export default PaginationBox;
