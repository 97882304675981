/* eslint-disable import/no-anonymous-default-export */
//import { AxiosInstance } from '../../axiosClient';
import { makeApiRequest,makeApiRequest1, generateSymbol, parseFullSymbol } from './helpers.js';
import { subscribeOnStream, unsubscribeFromStream } from './streaming.js';
var count=0;
//AxiosInstance.get(`/exchanges/list`)
const lastBarsCache = new Map();
const configurationData = {
	//supported_resolutions: ['1D', '1W', '1M'],
	//supported_resolutions: ['1s', '1m', '3m', '5m', '15m', '30m', '1h', '2h', '4h', '6h', '8h', '12h', '1d', '3d', '1w', '1M'],
	supported_resolutions: [ '1', '3', '5', '15', '30', '60', '120', '240', '360', '480', '720', '1d', '3d', '1w', '1M'],
	exchanges: [{
		value: 'BTRLExchange',
		name: 'BTRL Exchange',
		desc: 'BTRLExchange',
	},
	/* ,
	{
		value: 'TBinance',
		name: 'TBinance',
		desc: 'TBinance',
	},
	{
		value: 'FBinance',
		name: 'FBinance',
		desc: 'FBinance',
	},
	{
		value: 'TFBinance',
		name: 'TFBinance',
		desc: 'TFBinance',
	} */
	
	
	/* ,{
		value: 'Bitfinex',
		name: 'Bitfinex',
		desc: 'Bitfinex',
	},
	{
		// `exchange` argument for the `searchSymbols` method, if a user selects this exchange
		value: 'Kraken',

		// filter name
		name: 'Kraken',

		// full exchange name displayed in the filter popup
		desc: 'Kraken bitcoin exchange',
	}, */
	],
	symbols_types: [{
		name: 'crypto',

		// `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
		value: 'crypto',
	},
		// ...
	],
};

async function getAllSymbols(exchange1="Binance",type="spot") {
	//const data = await makeApiRequest('data/v3/all/exchanges');
	const data = await makeApiRequest1('get-all-pairs',null);
	let allSymbols = [];
	if(data && data.status===200){
		const pairs = data.data;
		//console.log("pairs=>",pairs);
		for (const exchange of configurationData.exchanges) {
			for (let i=0; i < pairs.length; i++ ) {
				const symbol = generateSymbol(exchange.value, pairs[i].currency_symbol, pairs[i].market_symbol);
				const symbols= {
					symbol: symbol.short,
					full_name: symbol.full,
					description: symbol.short,
					exchange: exchange.value,
					type: 'crypto',
				}
				allSymbols = [...allSymbols, symbols];
				
			}
		}
		
	}
	//console.log(allSymbols);
	return allSymbols;
}

export default {
	onReady: (callback) => {
		//console.log('[onReady]: Method call');
		setTimeout(() => callback(configurationData));
		//count=0
		// callback(configurationData);
	},

	searchSymbols: async (
		userInput,
		exchange,
		symbolType,
		onResultReadyCallback,
	) => {
		//console.log('exchangeSym symbolType=>',symbolType);
		//console.log('[searchSymbols]: Method call');
		const symbols = await getAllSymbols(exchange);
		const newSymbols = symbols.filter(symbol => {
			const isExchangeValid = exchange === '' || symbol.exchange === exchange;
			const isFullSymbolContainsInput = symbol.full_name
				.toLowerCase()
				.indexOf(userInput.toLowerCase()) !== -1;
			return isExchangeValid && isFullSymbolContainsInput;
		});
		onResultReadyCallback(newSymbols);
	},

	resolveSymbol: async (
		symbolName,
		onSymbolResolvedCallback,
		onResolveErrorCallback,
	) => {
		const parsedFullSymbol = parseFullSymbol(symbolName)
		//initws:(exchange)=>{},
		
		const symbols = await getAllSymbols(parsedFullSymbol.exchange);
		const symbolItem = symbols.find(({
			full_name,
		}) => full_name === symbolName);
		if (!symbolItem) {
			//console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
			onResolveErrorCallback('cannot resolve symbol');
			return;
		}
		const symbolInfo = {
			ticker: symbolItem.full_name,
			name: symbolItem.symbol,
			description: symbolItem.description,
			type: symbolItem.type,
			session: '24x7',
			timezone: 'Etc/UTC',
			exchange: symbolItem.exchange,
			minmov: 1,
			pricescale: 100,
			//has_intraday: false,
			has_intraday: true,
			has_no_volume: true,
			//has_weekly_and_monthly: false,
			has_weekly_and_monthly: true,
			supported_resolutions: configurationData.supported_resolutions,
			volume_precision: 2,
			//data_status: '',
			data_status: 'streaming',
		};

		//console.log('[resolveSymbol]: Symbol resolved', symbolName);
		onSymbolResolvedCallback(symbolInfo);
	},

	getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
		const { from, to, firstDataRequest } = periodParams;
		//console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
		
		const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
		
		const urlParameters = {
			exchange: parsedSymbol.exchange,
			currency: parsedSymbol.fromSymbol,
			market: parsedSymbol.toSymbol,
			time: to,
			limit: 7000,
			resolution:resolution,
		};
		const query = Object.keys(urlParameters)
			.map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
			.join('&');
		try {
			//count++;
			//console.log("count=>",count);
			//if(count===1){
				const data = await makeApiRequest1(`get-bars`,query);
				let am=0;
				//console.log("bars=>",data.data);
				if ((data.status && data.status !== 200) || data.data.length === 0) {
					// "noData" should be set if there is no data in the requested period.
					onHistoryCallback([], { noData: true, });
					return;
				}
				let bars = [];
				
				const myNewReturnedData=data.data;
				myNewReturnedData.forEach(bar1 => {
					let timestamp = new Date(bar1.time);
					let time = Math.floor(timestamp.getTime()); 
					if (time >= from * 1000 && time < to *1000 ) {
						const l1=typeof bar1.low === 'number'?bar1.low:Number(bar1.low);
						const h1=typeof bar1.high === 'number'?bar1.high:Number(bar1.high);
						const o1=typeof bar1.open === 'number'?bar1.open:Number(bar1.open);
						const c1=typeof bar1.close === 'number'?bar1.close:Number(bar1.close);
						const v1=typeof bar1.volume === 'number'?bar1.volume:Number(bar1.volume);
						const myNewObj={
							time: time,
							low: l1,
							high: h1,
							open: o1,
							close: c1,
							volume:v1
						}
						bars = [...bars, myNewObj];
						
						
					}
				});
				//console.log(bars);
				if (firstDataRequest) {
					lastBarsCache.set(symbolInfo.full_name, {
						...bars[bars.length - 1],
					});
				}
				// console.log(`[getBars]: returned ${bars.length} bar(s) `,bars);
				//onHistoryCallback(bars, { noData: false });
				onHistoryCallback(bars, { noData: true });

			//}

		} catch (error) {
			//console.log('[getBars]: Get error', error);
			onErrorCallback(error);
		}
	},
	subscribeBars: (
		symbolInfo,
		resolution,
		onRealtimeCallback,
		subscriberUID,
		onResetCacheNeededCallback,
	) => {
		//console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
		subscribeOnStream(
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscriberUID,
			onResetCacheNeededCallback,
			lastBarsCache.get(symbolInfo.full_name),
		);
	},

	unsubscribeBars: (subscriberUID) => {
		//console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
		unsubscribeFromStream(subscriberUID);
	},
	//initws:(symbolInfo)=>{},
	/* subscribeBars: (
		symbolInfo,
		resolution,
		onRealtimeCallback,
		subscribeUID,
		onResetCacheNeededCallback,
	) => {
		const newSym=parseFullSymbol(symbolInfo)
		console.log('exchange123=>',newSym)
		console.log('[subscribeBars]: Method call with subscribeUID:', subscribeUID);
		subscribeOnStream(
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscribeUID,
			onResetCacheNeededCallback,
			lastBarsCache.get(symbolInfo.full_name),
		);
	},

	unsubscribeBars: (subscriberUID) => {
		console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
		unsubscribeFromStream(subscriberUID);
	}, */
};

