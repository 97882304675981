/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState, useEffect, useMemo } from "react";
import { NavLink as Link, useNavigate } from "react-router-dom";
import styles from "./module.scss";
import Footer from "../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../Components/HomeComponents/Navbar/Navbar";

import PaginationBox from "./PaginationBox/PaginationBox.js";
import FilterComponent from "./FilterComponent.js"
import Context from "../../Context/Context";
//import { useNavigate } from "react-router-dom";
// eslint-disable-next-line no-unused-vars

import Table from 'react-bootstrap/Table';

import {
  sendRequest,
  refreshToken,
  shortener
} from "../../Helper/helper.js";
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const Rank = () => {
 
  const { states } = useContext(Context);
  const [allData, setAllData] = useState();
  const [activePage, setActivePage] = useState(localStorage.getItem("RankPages") && localStorage.getItem("RankPages")!==""  ? parseInt(localStorage.getItem("RankPages")):1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [activeValue, setActiveValue] = useState("Rank");
  const navigate = useNavigate();
  const signin = () => navigate("/signin");
  const isLogin = sessionStorage.getItem("isLogin") === "true" ? true : false;
 
  const dmode = states.dmode;

  const [pending, setPending] = React.useState(true);
  const [filterText, setFilterText] = React.useState('');
  const [search, setSearch] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const handleClear = () => {
    if (filterText) {
     setResetPaginationToggle(!resetPaginationToggle);
     setSearch("");
      setFilterText('');
    }
  }
  useEffect(()=>{
    console.log("activePage => ",activePage);
  
   const getRankData = async () => {
    const oohres = await sendRequest(
                          {
                            
                            start: activePage > 1 ? activePage * 10 - 10 : 0,
                            //start: 1,
                            limit: "10",
                             filter:search
                          },
                          "get-pension-rank"
                        );
                        console.log("oohres => ",oohres);
    setAllData(oohres.data)
    setTotalRecord(oohres.totalRecords)
  }
  getRankData();
  },[activePage,search, resetPaginationToggle])
  const searchHandler=()=>{
      setSearch(filterText)
  }
  return (
    <div className={`${styles.rank_l} ligtMode`}>
      <Navbar />
      <div className='container relative'>
      <div className={`${styles.rank_wrapper} historyTable  p-relative`} >
        <h1 className={`${styles.note} text-center mt-3 mb-3`}>
         Pension Rank
        </h1>
        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} onSearch={ searchHandler} search={search}/>
          <Table responsive>
            <thead >
              <tr>
                  <th ><div>User</div></th>
                  <th ><div>User Full Name</div></th>
                  <th ><div>Direct Team</div></th>
                  <th ><div>Rank</div></th>
                  <th ><div>Eligiblity</div></th>
              </tr>
            </thead>
            <tbody >
              {allData && allData?.length > 0  && allData?.map((item, bi) => (
                <tr className={styles.tr} key={bi}>
                  <td >
                   
                   
                      
                        <img src={item.profile_image ? item.path+item.profile_image : "/user.jpg"} className="img-fluid rankuser rankuser-sm "/>
                        <br />
                        {item.UID} 
                   
                   
                  </td>
                  <td >{item.name}</td>
                  <td >{item.team_count}</td>
                  <td >{item.rank}</td>
                  <td >{item.pension}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className={`${styles.pages} flex-between`}>
        <div></div>
        <div className={styles.pagesArea}>
          {allData && (
            <PaginationBox
              
              activePage={activePage}
              setActivePage={setActivePage}
              _totalPage={totalRecord}
            />
          )}
        </div>
      </div>
        </div>
      </div>
      <Footer />
    </div>

  );
};

export default Rank;
