import React, { useEffect } from "react";
 import { Timeline } from 'react-twitter-widgets'
/*import { TwitterTimelineEmbed } from "react-twitter-embed"; */
import styles from "./News.module.scss";

const News = ({ dmode }) => {
  /* useEffect(()=>{
    twttr.widgets.createTimeline(
      {
        sourceType: "profile",
        screenName: "TwitterDev"
      },
      document.getElementById("container")
    );

  },[]) */
  return (
    <section className={styles.news_l}>
     <div id="fb-root"></div>
     <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbitcoinregular&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=119894037854893" width="340" height="500"  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
     
    </section>
  );
};

export default News;
