/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState, useEffect, useMemo } from "react";
import Footer from "../../Components/HomeComponents/Footer/Footer.js";
import Navbar from "../../Components/HomeComponents/Navbar/Navbar.js";
import Context from "../../Context/Context.js";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import styles from "./module.css";
import Modal from 'react-modal';
import 'react-tabs/style/react-tabs.css';
import lottery1 from "../../Assets/lottery/lottery1.png";
import lottery2 from "../../Assets/lottery/lottery2.png";
import { BiCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Table from 'react-bootstrap/Table';
import swal from "sweetalert";
import DataTable, { createTheme } from 'react-data-table-component';
import FilterComponent from "./FilterComponent.js";
import {
  authRequest,
  refreshToken,
  shortener
} from "../../Helper/helper.js";
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const _path = window.location.pathname;
const Lottery = ({lotteryStatus, setLotteryStatus}) => {
 
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  
  function closeModal() {
    setIsOpen(false);
  }
  const { states } = useContext(Context);
  const [displayclass, setDisplayClass] = useState('show');
  const navigate = useNavigate();
  const signin = () => navigate("/signin");
  //const isLogin = states.isLogin;
  const isLogin = sessionStorage.getItem("isLogin") === "true" ? true : false;
 
  const dmode = states.dmode;
  const ticketPrice = 50000000;
  const currency_symbol = "SUNNY";
  const totalTicket = 270000;
  const [token, setToken] = useState(1);
  const [infos, setInfos] = useState({});
  const [soldTicket, setSoldTicket] = useState(0);
  const [totalBtrl, setTotalBtrl] = useState(token*ticketPrice);
  const [btrlBalance, setBtrlBalance] = useState(0);
  const [errormessage, setErrormessage] = useState({});
  const [userTickets, setUserTickets] = useState([]);
  const [winners, setWinners] = useState([]);
  const [contractAddress, setContractAddress] = useState("");
  const [contractLink, setContractLink] = useState("");
  const [winnerStatus, setWinnerStatus] = useState(0);
  const [pending, setPending] = React.useState(true);
  const handleChange = (event) => {
      const result=event.target.value.replace(/\D/g,'');
      if(result!==""){
        const RemainingTickets=totalTicket-soldTicket;
        if(RemainingTickets>=result){
          setToken(result);
          setTotalBtrl(result*ticketPrice);
        }
        else{
          setToken(RemainingTickets);
          setTotalBtrl(RemainingTickets*ticketPrice);
        }

      }
      else{
        setToken(result);
      }
  };
  const setInfosHandler = (key) => {
    let change = infos;
    change[key] = true;
    setInfos({ ...change });
    setTimeout(() => {
      setInfos({});
    }, 2000);
  };
  const errorHandler = (key, value) => {
    let change = errormessage;
    change[key] = value;
    setErrormessage({ ...change });
  };
  const getuserticket=async ()=>{
    const resBal1 = await authRequest({ currency: currency_symbol }, "user-cr-lotteries");
        if(resBal1)
        {
          if(resBal1.status===200){
            setUserTickets(resBal1.data)
          }
        }
  }
  const getwinnersticket=async ()=>{
    //console.log("currency_symbol",currency_symbol);
    const resBal1 = await authRequest({ currency: currency_symbol }, "luckydraw-winners");
    refreshToken(resBal1?.refresh_token);
   //console.log("resBal1",resBal1);
        if(resBal1)
        {
          if(resBal1.status===200){
            setWinners(resBal1.data)
          }
          setContractAddress(resBal1.contract_address);
          setContractLink(resBal1.link);
          setWinnerStatus(parseInt(resBal1.lotterystatus));
        }
  }

  useEffect(()=>{
    (async()=>{
      //console.log("isLogin",isLogin);
      if(isLogin){

        getuserticket();
        // const resBal = await authRequest({ currency: currency_symbol }, "balance");
        // refreshToken(resBal.refresh_token);
        // if(resBal.status===200){
        //   setBtrlBalance(resBal.data[0]?.available_balance)
        // }
      }
      else{
        setBtrlBalance(0)
      }
    })()
  },[isLogin])
 /*  useEffect(()=>{
    (async()=>{
      if(soldTicket===0){

        const resBal1 = await authRequest({ currency: currency_symbol }, "total-lottery-sold");
        refreshToken(resBal1?.refresh_token);
        if(resBal1?.status===200){
          //setLotteryStatus(1);
          //setSoldTicket(resBal1?.data)
        }
      }
    })()
  }) */
  useEffect(()=>{
    (async()=>{
        /* 
          contractAddress
          contractLink
          winnerStatus
        */
       // console.log("winners=>",winners);
       // console.log("contractAddress=>",contractAddress);
       // console.log("contractLink=>",contractLink);
       // console.log("winnerStatus=>",winnerStatus);
        if(winners.length===0 && contractAddress==="" && contractLink==="" && winnerStatus===0){

          getwinnersticket();
        }
    })()
  })
  const formDataHandler = async () => {
  
   if(token>0){
    if(btrlBalance >= totalBtrl){
      const RemainingTickets=totalTicket-soldTicket;
      if(token > RemainingTickets){
        swal("Tickets must be less than or equals to "+RemainingTickets+".")
      }
      else{
        const res = await authRequest({
          ticket : token,
          
        } , "buy-lottery");
        refreshToken(res.refresh_token)
    
        if(res.status === 200){
           swal(res.message)
           await getuserticket();
           setBtrlBalance(btrlBalance-totalBtrl)
           setSoldTicket(parseInt(soldTicket)+parseInt(token))
           closeModal()
        }else if(res.status === 401){
          for (const keys of Object.keys(res.data)) {
            errorHandler(keys,res.data[keys])
          }
        }else{
          swal(res.message)
        }

      }
    }
    else{
      swal("Balance must be greater than "+totalBtrl+".")
     }
   }
   else{
    errorHandler("ticket","Ticket is required.")
   }
  };
  const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  useEffect(() => {
		const timeout = setTimeout(() => {
			setRows(winners);
			setPending(false);
		}, 2000);
		return () => clearTimeout(timeout);
	}, [winners]);
  const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);
  const columns =  [
    {
      name: 'Ticket Number',
      selector: 'ticket',
      sortable: true,
    },
   /*  {
      name: 'Winner',
      selector: 'winner',
      sortable: true,
    }, */
    {
      name: 'Winning Prize',
      selector: 'winningAmount',
      sortable: true,
    },
  ];
  const clickHandler = (state) => {
    // console.log("ID ", state);
    // window.open("https://wa.me/"+state.mobile, '_blank', 'noopener,noreferrer');
  };
  createTheme('dark', {
    background: {
      default: 'transparent',
    },
    
  });
  const customStyles = {
    title: {
      style: {
        fontColor: 'red',
        fontWeight: '900',
      }
    },
    
  };
/* (e) =>
          setToken((v) => (e.target.validity.valid ? e.target.value : v)) */
  return (
    <div className={dmode ? `  ligtModeInfoPage ligtModeLottery  ` : `  ligtModeInfoPage ligtModeLottery `}>
      <Navbar />
      
        {winnerStatus===1?<>
          <div className="sn_page_pad ticketSection roundsSection bgchange" key={"win"}>
                <div className="container relative " key={"winner"}>
                  <div className="row" key={"winners"}>
                    <div className="col-sm-12">
                     
                    <DataTable 
                    title={<><h2 className="text-center dt-heading" style={{marginTop: '50px'}}><img src="lottery/diya.png" className="diya img-fluid" />Maa Luxmi Crorepati Lucky Draw Winners<img src="lottery/diya.png" className="diya img-fluid" /></h2></>} 
                    theme={dmode ?"dark":"solarized"} columns={columns} 
                    data={winners && winners.length>0? winners.filter(
                        item => (item.ticket && item.ticket.includes(filterText) ),
                      ):[]}
                      progressPending={pending}  subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      persistTableHead
                      pagination
                     
                      paginationRowsPerPageOptions={[10,20,50,100,500,1000]}
                      paginationPerPage={100}
            
                    
                 
                      /> 
                     { contractLink!==""?<><h4 style={{marginTop:"10px"}}>Lucky Draw Contract: <a href={contractLink} target="_blank">{contractAddress}</a></h4></>:<></>}
                     
                    </div>
                  </div>
                </div>
              </div>
        
        </>:
        <>
        </>}
              
        {(isLogin && userTickets.length>0) ?
            <>
              <div className="sn_page_pad ticketSection roundsSection " key={"aa"}>
                <div className="container relative " key={"aaa"}>
                  <h2 className="text-center" key={"aaaa"}>Congratulations 🎉 Your Tickets Are As Follows.</h2>
                  <div className="row justify-content-center" key={"aaaaa"}>
                  { (userTickets.length>0) ?  (userTickets?.map((dbticket, i) => (
                      <>
                          <div className="col-sm-3" key={dbticket.ticket}>
                            <div className="snticket d-flex" key={"a"+dbticket.ticket}>
                              <div className="leftticket " key={"b"+dbticket.ticket}>
                                <h2  key={"c"+dbticket.ticket}>Ticket Number</h2>
                                <h3  key={"d"+dbticket.ticket}>{infos[dbticket.ticket]? <><span  key={"e"+dbticket.ticket}>Ticket No. Copied!</span></>:dbticket.ticket} 
                                <CopyToClipboard  key={"f"+dbticket.ticket}
                                    text={dbticket && dbticket.ticket}
                                    onCopy={() => setInfosHandler(dbticket.ticket)}
                                  >
                                    <BiCopy  key={"g"+dbticket.ticket} />
                                  </CopyToClipboard>
                                  
                                </h3>
                                
                              </div>
                              <div className="rightticket"></div>
                            </div>
                          </div>
                          
                      </>
                  ))):
                      <>
                        
                          <div className="col-sm-3" key="0-0">
                            <div className="snticket d-flex">
                              <div className="leftticket ">
                                <h2>Ticket Number</h2>
                                <h3>Not Buy Yet</h3>
                              </div>
                              <div className="rightticket"></div>
                            </div>
                          </div>
                          
                      
                      </>
                    }
                    </div>
                </div>
              </div>
      </>
      :<></>
            }       
      <Footer />
    </div>

  );
};

export default Lottery;
