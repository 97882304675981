import React from 'react'
import Home from '../latestcomponents/home/homesections';

const HomePage = () => {
  return (
    <>
        <Home />
    </>
  )
}

export default HomePage;