import React, { useEffect, useRef,useState } from 'react';
import Navbar from '../../Components/HomeComponents/Navbar/Navbar';
import Footer from '../../Components/HomeComponents/Footer/Footer';

import Slider from './slider';
import Newlistingtokens from './newlistingtokens';
import Adds from './adds';
import Features from './features';
import Cryptotabs from './cryptotabs';
import Partners from './partners';


const Homesections = ( ) => {
  return (
    <>
      <div className="latestpage bgColor homePage navabsolute">
        <Navbar />
        <Slider />
        <Newlistingtokens />
        <Adds />
    {    <Cryptotabs />}
        <Features />
        <Partners />
        <Footer />
      </div>
    </>
    );
};

export default Homesections;